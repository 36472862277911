define('channels/Cookie',['require','channels/Store','channels/Util'],function (require) {
    "use strict";

    var Store = require("channels/Store"), // for key prefix only
        util  = require("channels/Util");

    // Parses the cookies into a map...
    var parse = function (cookie) {
        var data = {index: util.options(cookie), keys: []},
            key;

        for (key in data.index) {
            // Filter out all values we know are not channel related...
            if (util.idx(key, Store.KEY_PREFIX) === 0) {
                data.keys.push(key);
            } else {
                delete data.index[key];
            }
        }

        return data;
    };

    /**
     * @class
     * The <i>Cookie</i> type describes the channel interaction with (secure) cookie storage when
     * wrapped by the <tt>Store</tt> type. <p>
     *
     * Cookie storage is not well suited for storing large json date; prefer <tt>Storage</tt> for
     * that. It usage here is mainly to allow our test suites to be configured with different
     * snippet configurations as the test suite only supports cookies, not session/local storage.
     *
     * @param {HTMLDocument} doc    The document defining the backing HTML5 cookie, e.g.
     *                              <tt>window.document.cookie</tt> under the document <tt>window.document</tt>.
     * @param {Boolean}      secure True if secure (https), false otherwise (http, default).
     */
    return function (doc, secure) {
        var scope = this,
            storage = function () {
                if (!_storage) {
                    _storage = parse(doc.cookie);
                }
                return _storage;
            },
            _storage = null;

        this.size = function () {
            return storage().keys.length;
        };

        this.key = function (index) {
            return storage().keys[index];
        };

        this.get = function (key) { // timeout never used on get, set on put as per standard cookie behaviour
            return storage().index[key];
        };

        this.put = function (key, value, timeout) {
            var expires = "", postfix = "; path=/";
            if (timeout) {
                var date = util.now();
                    date.setTime(date.getTime() + timeout);
                expires = "; expires=" + date.toGMTString();
            }
            if (secure) {
                postfix += "; secure";
            }
            doc.cookie = key + "=" + value + expires + postfix;
            _storage = null; // force re-parse when needed
        };

        this.remove = function (key) {
            scope.put(key, "", -1);
            _storage = null; // force re-parse when needed
        };
    };

});

