define('channels/Channel',['require','channels/ChannelUrlFilter','channels/Global','channels/State','channels/ViewerBridge','channels/Provider','channels/Dom','channels/Util','channels/Format','channels/LoadingIndicator'],function (require) {
    "use strict";

    var filter           = require("channels/ChannelUrlFilter"),
        global           = require("channels/Global"),
        state            = require("channels/State"),
        Viewer           = require("channels/ViewerBridge"),
        provider         = require("channels/Provider"),
        dom              = require("channels/Dom"),
        util             = require("channels/Util"),
        format           = require("channels/Format"),
        LoadingIndicator = require("channels/LoadingIndicator");

    // Array separator used for DOM element attributes storing array values (as strings)...
    var SEPARATOR = "_;_";

    /**
     * @class
     * A match describes the dom elements that matches the css selector for a given channel.
     *
     * @author Gunni Rode / <a href="http://www.zmags.com">Zmags</a>
     */
    function Match() {
        var scope = this;

        /** @type {HTMLElement} The matching dom element to inject the experience into; can be null, i.e. no match. */
        this.element = null;
        /** @type {HTMLElement[]} Additional dom elements matching the css selector used; never null, can be empty. */
        this.elements = [];
        /** @type {ClientRect} Calculated viewport for <tt>element</tt> at experience insertion time; may change over time. */
        this.viewport = null;

        /**
         * Returns the string representation of this match.
         *
         * @return {String} The string representation; never null.
         */
        this.toString = function () {
            return format.match(scope);
        };
    }

    /**
     * @class
     * A content instance describes the content associated with a channel, e.g. an Experience or Group, including
     * optional fallback data (for now, Experiences only).
     *
     * @constructor
     * @param {Function} data A function to return the (initial) data stub (json); can be falsey in which case
     *                        <tt>type</tt> is "unknown" and a default no-op data function will be set. If defined,
     *                        will declare an "id" property for the id.
     * @param {String}   type The type of data, e.g. "experience" or "group"; cannot be falsey.
     *
     * @author Gunni Rode / <a href="http://www.zmags.com">Zmags</a>
     */
    function Content(data, type) {
        var scope = this;

        /**
         * Returns the id of of this content.
         *
         * @return {String} The id of this content, if available; can be falsey.
         * @see    #shown
         */
        this.id = function () {
            return scope.data.id; // on the function itself, not in the "data" since it can change!
        };

        /**
         * @type {Function}
         * A function to return the (original) raw json content, e.g. an experience or group stub or an embedded
         * equivalent. <p>
         *
         * Implementation note: the raw data <b>cannot</b> be shared with the actual Viewer, as it will/may create
         * Backbone.Model graphs based on it, in place, changing its internal structure, like a json array becoming
         * a Backbone.Collection. <p>
         *
         * The function is never falsey, but the content it return may be (e.g. undefined). <p>
         *
         * Function signature:
         * <pre>
         *     data(notDirty:Boolean): * // Returns the json data, tagging it as dirty unless "notDirty" is true.
         * </pre>
         */
        this.data = data || function () {};

        /**
         * @type {Function}
         * Optional function to return the raw json fallback content, e.g. for an Experience. Fallback data is
         * loaded when a fallback Experience is shown in legacy browsers or when requested in admin mode; all
         * properties are optional. <p>
         *
         * The function may be null, and fallback will only be available if the function is defined and returns
         * fallback data.
         *
         * Function signature:
         * <pre>
         *     fallback(): * // Returns the json fallback data.
         * </pre>
         *
         * The fallback json has the following format:
         * <pre>
         *     {fallbackImageUrl:String, width:Number, height:Number, fallbackLink:String}
         * </pre>
         *
         * Note that we currently have no fallback behaviour for groups!
         */
        this.fallback = null;

        /**
         * Returns a user-friendly name describing this content. <p>
         *
         * The name does not reflect the actual shown experience, i.e. if this content is a group that eventually
         * will lead to a given experience being shown; it is also the content itself that is reflected here. <p>
         *
         * If no name is available, a name will be auto generated, based on the content type and id, if set.
         *
         * @return {String} The user-friendly content name; never falsey.
         */
        this.name = function () {
            var data = scope.data(true), name = data && data.name, id;
            if (name) {
                return name;
            }
            name = scope.type.charAt(0).toUpperCase() + scope.type.substr(1);
            id = scope.id();
            return id ? name + " " + id : name;
        };

        /**
         * @type {String}
         * The content type, lower-cased: "experience" or "group", but never "fallback". When fallback content
         * is inserted into a channel, the <tt>channel.state</tt> will be set to "fallback", but this type
         * will remain the type of the original non-fallback content. <p>
         *
         * Will never contain a white space character as it will be used to tag dom elements via attributes.
         */
        this.type = type;

        /**
         * @type {String}
         * State of the (pre-)load the raw content json if so configured. If loaded, the json will be stored
         * in <tt>data</tt>. <p>
         *
         * State:
         * <pre>
         *    "notloaded": not loaded (including if it cannot be (pre-)loaded because no jQuery available)
         *    "pending":   a load is in progress, but has not completed yet.
         *    "cache":     from in-memory cache.
         *    "local":     from local storage (and thus from the cache as well).
         *    "loaded":    loaded, and stored in <tt>content</tt>, including if embedded. Note that when groups are
         *                 store, the experience data may also be loaded (by the viewer), but such content is not
         *                 stored here, but may be cached.
         *    "failed":    preload failed.
         * </pre>
         *
         * On "failed", this content instance will have an "error" property with the incoming error (message), if
         * such a message is available.
         */
        this.state = "notloaded";

        /**
         * @private @type {String}
         * The id of the actual shown experience for this content, e.g. when <tt>type</tt> is "group" and the viewer
         * has figured out which Experience to show (set via viewer callback).
         */
        this._shown = scope.id();

        /**
         * Return the id of the actual shown experience for this content, e.g. when <tt>type</tt> is "group" and the
         * viewer has figured out which Experience to show (set via viewer callback). <p>
         *
         * For non-group content, this id will always be equal to <tt>id()</tt>.
         *
         * @return {String} The actual shown experience id by viewer once the viewer has loaded; can be falsey.
         */
        this.shown = function () {
            return scope._shown;
        };

        /**
         * Returns the string representation of this content.
         *
         * @return {String} The string representation; never null.
         */
        this.toString = function () {
            return format.content(scope);
        };
    }

    /**
     * Returns a new content instance wrapping the supplied json data in <tt>options</tt>.
     *
     * @param  {*} options The channel options, where <tt>options.experience</tt> or <tt>options.group</tt> will
     *                     be wrapped in the returned content; cannot be falsey.
     * @return {Content} A new content instance; never null, but its <tt>type</tt> may be "unknown" if invalid.
     */
    Content.of = function (options) {
        var content = function (type) {
            var data = options[type], fn;
            if (data) {
                fn = function () { return data; };
                fn.id = data.id;
                return new Content(fn, type);
            }
        };
        return content("experience") || content("group") || new Content(null, "unknown");
    };

    /**
     * @constructor
     * A channel describes where and when its associated experience should be inserted into or removed from the dom. <p>
     *
     * <tt>options</tt> must have the following format:
     * <pre>
     *   {
     *      name:        String,      // The channel name, if any; can be null, will default to <tt>selector</tt>.
     *      id:          String,      // The channel id when in admin mode; defaults to the (defaulted) name.
     *      origin:      String,      // From where did this channel originate from: "loaded", "embed", "content",
     *                                // "manual", or "dom" - or something user defined.
     *      experience:  *,           // The Experience (reference) for this channel (e.g. json); mandatory if <tt>
     *                                // "group" is not set (unless admin mode).
     *      group:       *,           // The Group (reference) for this channel (e.g. json); mandatory if <tt>
     *                                // "experience" is not set (unless admin mode).
     *      selector:    String,      // The CSS selector for this channel; can be falsey if "element" is supplied
     *      element:     HTMLElement, // The HTML element to use for this channel in case no CSS selector is supplied.
     *                                // Ignored if "selector" is specified.
     *      urlPattern:  String[],    // The url pattern used to match against the url; optional.
     *      restoreType: String,      // The optional type of behaviour when this channel/experience snippet is removed
     *                                // from the dom: "restore" (show original content) or "hide" (use display:none for
     *                                // target element, e.g. when the target element is known to be a "blank space" this
     *                                // can look better). Defaults to <tt>snippet.restoreType</tt>.
     *      loadingIndicator: String, // Optional loading indicator style, e.g. "spinner"; defaults to
     *                                // <tt>snippet.loadingIndicator</tt>.
     *      preload: Number,          // A preload priority to preload the content json data for this channel on behalf
     *                                // of the Viewer. A higher number means higher priority, a zero or negative value
     *                                // means no preload. Will default to 1 if the owning snippet Viewer mode has
     *                                // "data", otherwise to 0 (no preload). Will be coerced into 0 in legacy browsers.
     *      timeout: Number,          // The timeout in ms for the original content to be restored according to
     *                                // <tt>restoreType</tt> if the viewer fails to load within said timeout; defaults
     *                                // to <tt>snippet.timeout</tt> if undefined.
     *      cacheable: Number         // The timeout in ms to cache raw json content, e.g. experiences, by this
     *                                // channel; defaults to <tt>snippet.cacheable</tt> if undefined. Even if
     *                                // cacheable, the client may still have disabled local storage, such as incognito
     *                                // mode, effectively disabling caching as well.
     *   }
     * </pre>
     *
     * @param {Snippet} snippet The snippet owning this channel; cannot be falsey.
     * @param {*}       options The channel options (json); cannot be falsey.
     *
     * @author Gunni Rode / <a href="http://www.zmags.com">Zmags</a>
     */
    function Channel (snippet, options) {

        var scope = this;

        /**
         * @type {Snippet}
         * The snippet this channel belongs to; never null.
         */
        this.snippet = snippet;

        /**
         * @type {String}
         * The origin of this channel as an "enum":
         *
         * <pre>
         *     "loaded",  // loaded from the channel config
         *     "embed",   // embedded via the channel config
         *     "content", // manual via content.js script, where it can also be overridden, e.g. "demandware"
         *     "manual",  // added/queued manually via JS
         *     "dom"      // parsed from the dom
         * </pre>
         *
         * Other values will be respected if added explicitly here, useful for sorting channels for the same
         * snippet to give priorities to channels from different origins. <p>.
         *
         * Can be falsey if currently unknown, which should be treated as "loaded".
         */
        this.origin = options.origin;

        /**
         * @type {Content}
         * The publishable content for this channel, e.g. an experience or group. By default, this is just a
         * shim for the actual content, e.g. {id: .., name: ..}, but the content may be preloaded if so
         * configured. <p>
         *
         * If content preload is enabled, <tt>content.state</tt> will reflect the preloading state. Once the
         * state becomes "loaded", this property will contain the loaded content json under <tt>content.data()</tt>.
         * The <tt>content.state</tt> state will also be set to "loaded" if the content is embedded. <p>
         *
         * Once this channel has the content injected to be shown by the viewer, the <tt>shown()</tt> function
         * will return the id of the content (eventually) shown, e.g. an experience id or the id of the asset
         * shown by the group as determined by the viewer.
         *
         * @see #id()
         * @see #type()
         * @see #valid()
         */
        this.content = Content.of(options); // never falsey, but can be bogus!

        /**
         * Returns the type of data associated with this channel: "experience" (default), "group", or
         * "unknown".
         *
         * @return {String} The type of data associated with this channel; never null.
         *
         * @see    Content#type
         */
        this.type = function () {
            return scope.content.type;
        };

        /**
         * @private @type {String}
         * The CSS selector for this channel, to identify the first matching dom element to insert the experience
         * (viewer) into. <p>
         *
         * If empty, the current element found during page load will be used, expected to be supplied
         * as <tt>options.element</tt>. If not, this channel is not considered valid and will eventually
         * be ignored.
         *
         * @see #valid()
         * @see #_dom.element
         */
        this._selector = options.selector || "";

        /**
         * @private @type {*}
         * The matched dom element <tt>experience</tt> or <tt>group</tt> is injected into and its (relevant) original
         * state before the injection for later restore. Only set when the content has been injected. <p>
         *
         * Note that <tt>id</tt> property reflect the configured content id. The id will also be randomised, in case
         * numerous channels use the same experience. <p>
         *
         * Format:
         * <pre>
         * {
         *    element:    HTMLElement,                                                             // matched dom element where content is injected into
         *    container:  HTMLElement,                                                             // content: iframe (experience/group), or image (fallback)
         *    id:         String                                                                   // the dom id to use for the injected iframe/fallback image ("content")
         *    viewport:   ClientBoundingRect,                                                      // "element" viewport, may change over time
         *    style:      HTMLElement,                                                             // < style> element with "css" to hide original content in "element"
         *    visibility: String,                                                                  // original visibility of "element" before injection
         *    position:   {position:String, top:String, bottom:String, left:String, right:String}, // original position of "element"
         * }
         * </pre>
         */
        this._dom = {
            element:    !scope._selector && options.element, // matched/current dom element, only set when content has been injected
            container:  null, // the iframe inserted into (child of) "element" for non-fallback experiences/groups, image for fallback, or div for publication, either having the id "_dom.id".
            viewport:   null, // viewport for "element" at content insertion time, may change over time
            visibility: null, // original visibility of "element" before injection, e.g. "visible" or "hidden"
            position:   null, // original position of "element"
            id:         null  // unique, set up at the end of this constructor, once and for all
        };

        /**
         * @type {Boolean}
         * True if this channel is an 'in-place' channel, false otherwise. <p>
         *
         * An 'in-place' channel is defined during page load, without the use of a css selector, but instead a
         * specific dom element.
         */
        this.inPlace = !!scope._dom.element;

        /**
         * @type {String}
         * The user-friendly name of this channel, defaulting to <tt>selector</tt> if <tt>name</tt> is falsey.
         *
         * @see #id
         * @see #qualifiedName
         */
        this.name = options.name || scope._selector || (((scope._dom.element && scope._dom.element.tagName) || "current") + util.now());

        /**
         * @type {String}
         * The unique id of this channel, defaulting to the channel name if not specified.
         *
         * @see #name
         * @see #qualifiedName
         */
        this.id = options.id || scope.name; // id available in admin mode

        /**
         * @private @type {Viewer}
         * A reference to the current viewer associated with this channel, if any, via a bridge once the viewer
         * is available. <p>
         *
         * Lazily created via "viewer()", but the actual viewer proxy exposed by the viewer will not be available
         * until the viewer has been loaded in its iframe, exposing said proxy in it. The viewer callbacks will
         * be no-ops, e.g. "close()", if no viewer is available.
         *
         * @see #viewer()
         */
        this._viewer = null;

        /**
         * @type {String}
         * The state of this channel: "idle", "match", "experience", "fallback", "group", or "error". <p>
         *
         * The "idle", "match", or "error" states means this channel is not fully initialised. A state of
         * "experience", "group", or "fallback" means fully initialised with either an experience, group, or
         * fallback experience, respectively.
         */
        this.state = "idle";

        /**
         * @private @type {*}
         * The (proxy) object exposed to the world for this channel.
         */
        this._proxy = null;

        /**
         * @private @type {String}
         * The url for this channel, already known to match if this channel is created. <p>
         *
         * At this point, the url is not really used for anything business wise, only for logging
         * and presentation purposes. <p>
         *
         * Can be empty, but never falsey otherwise.
         */
        this._url = (options.urlPattern && options.urlPattern.join("*")) || ""; // note: "url" property not push, only "urlPattern"

        /**
         * @private @type {String}
         * The type of "restoration" made when this channel/experience is removed from the dom (again), either
         * because the channel is explicitly removed or because the viewer could not be initialised successfully,
         * for example. <p>
         *
         * Valid types: <ul>
         * <li> "restore":  restores original content (default behaviour).
         * <li> "hide":     hides the original content using css 'display:none', which can be useful (look good) when
         *                  the original content is known to be a "blank space".
         * </ul>
         *
         * An unknown value will always be treated as "restore". Defaults to the controlling snippet behaviour, though
         * admin will always use "restore".
         */
        this._restoreType = snippet.admin() ? "restore" : (options.restoreType || snippet.restoreType);

        /**
         * @private @type {{options:String, indicator:LoadingIndicator}}
         * The loading indicator type used by this channel, e.g. "spinner".
         *
         * Valid types for <tt>options</tt>: <ul>
         * <li> "spinner":  Standard spinner.
         * <li> "none":     no spinner (default).
         * </ul>
         *
         * In addition, <tt>options</tt> may contain comma-separated spinner options, as described by the
         * <tt>LoadingIndicator.create(value:String, channel:Channel)</tt> factory method. <p>
         *
         * An unknown type will always be treated as "none" (i.e. "type=none"). Defaults to the controlling snippet behaviour.
         */
        this._loadingIndicator = {
            options:   options.loadingIndicator || snippet.loadingIndicator, // either type or include comma separated options
            indicator: null // set during loading for applicable "type" via start
        };

        /**
         * @private @type {Function}
         * Returns a preload priority to preload the content json data for this channel on behalf of the Viewer. <p>
         *
         * A higher number means higher priority, a zero or negative value means no preload. <p>
         *
         * Will default to 1 if the owning snippet Viewer mode has "data", otherwise to 0 (no preload). Will always,
         * however, be coerced into 0 when running in a legacy browser (since loading cannot be done async
         * there) or if no jQuery is available at "use time". <p>
         *
         * @return {Number} The preload priority for this channel on behalf of the Viewer.
         *
         * @see #loadContent
         * @see Provider#json
         * @see Global#$
         */
        this._preload = function () {
            // The mode can change during the lifetime of the snippet...
            return util.int(options.preload) || (snippet.mode.is({viewer: "data"}) ? 1 : 0);
        };

        /**
         * @private @type {Number}
         * The timeout in ms for this channel to restore the original content as per <tt>_restoreType</tt> if
         * the viewer has not loaded by then. <p>
         *
         * The default value is 30000 ms (from the snippet).
         */
        this._timeout = util.time(options.timeout, snippet.timeout);

        /**
         * @private @type {Number}
         * The timeout in ms for this channel to cache raw json data where available and where possible. A zero or
         * negative value means no cache used. Even when enabled, the client may have disabled local storage
         * altogether, like in incognito mode.
         *
         * The default value is 60000 ms (from the snippet).
         */
        this._cacheable = util.time(options.cacheable, snippet.cacheable);

        /**
         * @private @type {Channel[]}
         * Other channels, for any snippet, that has an url pattern and CSS selector, or dom element for in-place
         * channels, that matches said properties for this channel. The array will thus contain other channels that
         * would have used <tt>this._dom.element</tt>, but where this channel was processed first.
         */
        this._channels = [];

        /**
         * Returns the (snippet) qualified name of this channel.
         *
         * @return {String} The qualified channel name; never null.
         */
        this.qualifiedName = function () {
            return snippet.configId + ":" + scope.id;
        };

        // A bit awkward, but we cannot set this up before iwe have a name that might depend on the "in-place"
        // element, so we have to do this here. The type can be "unknown" and id "null" when no content yet
        // available...
        this._dom.id = "zmags_" + scope.content.type + "_" + scope.content.id() + "_" + scope.qualifiedName();

        /**
         * Returns true if this channel is considered valid so that it can potentially match a dom element to an
         * experience or group; false otherwise. <p>
         *
         * In admin mode, a selector or dom element is all that is required for it to be valid, though.
         *
         * @return {Boolean} True if considered valid (but may still not match any element), false otherwise.
         */
        this.valid = function () {
            return !!((scope.content.id() || snippet.admin()) && (scope._selector || scope._dom.element));
        };

        /**
         * Find all elements that matches the CSS selector for this channel. If this channel does not have a selector,
         * an empty array is returned. <p>
         *
         * If more than one element is found, the first *visible* one will be used to insert the experience (Viewer)
         * into. This is the element returned in the "element" property. If no visible elements at all, the first one
         * is used! Additional matching elements will be stored in the "elements" property, to handle their visibility
         * correctly. Note that even if visible, a matched element may not be shown, for example if height zero. If
         * that is the case, this is only logged; it can for example be because the target dom element is part of
         * some menu only shown on hover. <p>
         *
         * When multiple snippets/channels match the same target element, i.e. an experience has already been inserted
         * into a matching element from some other channel, the element is not considered a valid target element, and
         * will be ignored. The first matching channel then "wins", regardless of snippet. <p>
         *
         * Legacy browsers like IE9 will always return an empty object if the Zmags custom Sizzle has not been loaded
         * yet.
         *
         * @return {Match} The matching element(s); never null, but can be empty. "element" is the first *visible*
         *                 matching element, to have the experience injected into; if no visible elements at all, the
         *                 first one is used!. Note that "elements" in the returned match is not a NodeList, but an
         *                 actual array.
         */
        this.matchElements = function () {
            var match = new Match(), elements, element, channel, processed, name, i;

            // Make sure we have a channel selector - this is a public function, so we guard it here as well. Using
            // empty selectors will cause the native query selector function to raise an error. We will use our
            // custom Sizzle if in a legacy browser; if so, we expect Sizzle to have been loaded at this point (which
            // it will have if the normal flow is followed)...
            if (scope._selector) {
                try {
                    elements = dom.selectElements(scope._selector);

                } catch (e) {
                    snippet.error("Cannot select elements, no elements will match channel", scope, e);
                    // Fall-through...
                }

            // An empty channel selector is allowed when 'in-place' channels are created via script tags adding
            // channels to be inserted into the parent elements of said scripts during load time. If so, each
            // created channel will be primed with such elements, if at all available (will not be if called
            // after load has completed). However, we must ensure the relevant element is still in the dom to be usable...
            } else if (dom.doc.contains(scope._dom.element)) { // accepts null/undefined argument
                elements = [scope._dom.element];
            }

            // Filter our all elements already used by other channels: first come, first served. Note that elements
            // is a NodeList, not an array, so we simply cannot splice it; instead, we build a new array to use...
            if (elements) { // guard against potential Sizzle issues or exceptions...
                for (i = 0; i < elements.length; i++) {
                    element = elements[i];

                    // Check if the element is already processed by another (snippet,channel) pair, i.e. matched by
                    // another selector? This would indicate numerous selectors matching the same element(s), which
                    // is potentially bad. A channel will never use an element successfully matched by another channel,
                    // but we still want to tag it as processed. A special case is channels parsed from the dom: they
                    // can already be processed on alternate urls like Google Cache, but may not be...
                    processed = dom.data(element, "channel-processed");
                    // Qualify name, including with app start time, to ensure we match any (snippet,channel) pair,
                    // not just our snippet. The start time is added to aid us when alternate cache urls are used,
                    // where the dom attributes are cached as well...
                    name = state.start + ":" + scope.qualifiedName();

                    if (processed) {
                        // Do add again; we split to ensure we only match on entire channel name, not on a possible
                        // substring of the name. This will normally also handle "dom" channels on alternate urls
                        // like Google Cache that caches dom attributes as well for processed channels...
                        processed = processed.split(SEPARATOR);

                        if (util.idx(processed, name) === -1) {
                            processed.push(name);
                        }

                        if (processed.length > 1) {
                            snippet.info("Element already processed by other channel(s):", format.element(element), " -> ", name, processed, element);
                        }

                        processed = processed.join(SEPARATOR);

                    } else {
                        processed = name;
                    }

                    dom.data(element, "channel-processed", processed);

                    // See if the element is already used by *another* channel - for "dom" elements, the channel
                    // found may actually be this one...
                    channel = Channel.fromElement(element);
                    if (channel && (channel !== scope)) {
                        snippet.info("Ignoring element already matched by other channel", channel, scope, element);

                        // Notify the *other* channel about us wanting to use the same element...
                        channel._channels.push(scope);
                        // Tag this channel with the channel that uses the dom element at this point in time...
                        scope._dom.used = channel.id;

                    } else {
                        match.elements.push(element); // to handle visibility
                    }
                }
            }

            var length = match.elements.length;
            snippet.verbose("Channel selector matched ", length, " usable element(s)", scope);

            // Find match to use: try find a visible one, otherwise just use the first one (legacy issue)...
            if (length) {
                var visible = false, index = 0; // first one by default...
                for (i = 0; i < length; i++) {
                    element = match.elements[i];
                    // Test *computed* visibility. We test for different from "hidden" as legacy IE often reports
                    // "inherit" for default visible elements...
                    if (dom.style(element, "visibility") !== "hidden") {
                        visible = true; // can still be hidden, e.g. if client rect is empty (e.g. zeroe height)...
                        index = i;
                        break;
                    }
                }

                match.element  = match.elements[index];
                match.elements.splice(index, 1);

                var viewport = dom.bounds(match.element);
                match.viewport = viewport;

                // Log a bit info about viewport. Comparing a number to NaN is always false, which is fine here...
                var w = viewport.width || 0, h = viewport.height || 0, x = viewport.right || NaN, y = viewport.bottom || NaN,
                    empty = !h || !w,
                    outside = x < 0 || y < 0,
                    browser;

                if (!outside) {
                    x = viewport.left || NaN;
                    y = viewport.top  || NaN;
                    browser = dom.bounds();
                    outside = x > browser.right || y > browser.bottom;
                }
                snippet.verbose("Channel uses", visible ? " visible" : " *non-visible*", empty ? " and *zero-bounded*" : "", outside ? " and *out-of-bounds-positioned*" : "", " element", scope, match);
            }

            return match;
        };

        /**
         * Preload content json data, e.g. Experience or Group, if not already and where applicable (we need a
         * global jQuery instance to do actual loading), but not in legacy browsers that will block while doing
         * so (synchronous). <p>
         *
         * Also support entire models being embedded in the channel data (e.g. for embedded configs). <p>
         *
         * If these conditions are met, the loaded content json data will be stored in the <tt>content</tt>
         * property on completion and in the global content cache. This means that if the same content is
         * to be loaded again by another channel and/or at another time (within reason), the content will
         * already be cached and ready to use. This is easy, as object ids are globally unique, so different
         * types of publishable content will never conflict.
         */
        this.loadContent = function () {
            var content = scope.content,
                id = content.id(); // can be null if admin mode...

            if (id && content.state === "notloaded") {
                // Lookup in the (persistent) cache, based on the configurable cache timeout for this channel...
                var cached = state.cache.get(id, content.type, scope._cacheable);
                if (cached.content) { // if not cached.source === "miss"
                    // If "cached.source" is "local", we will eventually try a refresh of the content data once the
                    // viewer has loaded *unless* on mobile (do not want to much network traffic there), as not
                    // to interfere with the standard loading (which would slow viewer initialisation down)...
                    this._contentLoaded(cached.content.data, cached.source); // "local"/"cache" for already embedded/loaded, may be refreshed...

                // Not loaded, but is this channel/snippet configured to preload content data? If so, preload it *unless*
                // running in a legacy blocking browser (in which case jQuery is probably now present anyway)...
                } else if (scope._preload() > 0 && global.capabilities.isQuerySelectorSupported) {
                    scope._fetchContent();
                }
            }
        };

        /**
         * @private
         * Fetches the publishable for this channel (if jQuery is available), e.g. an Experience or Group (but not
         * the Experiences for a Group).
         *
         * @return {Boolean} True if a fetch is sent, false otherwise.
         *
         * @see    Provider#json
         * @see    Global#$
         */
        this._fetchContent = function () {
            var content = scope.content;
            return provider.json({
                viewer:    "/api/" + content.type + "s/" + content.id(), // TODO: fallbacks? Would be nice if "type()" could just be "fallback"
                log:       snippet,
                available: function () { scope.content.state = "pending"; },
                callback:  scope._contentLoaded, // already scoped
                errback:   scope._contentLoaded  // already scoped, undefined argument (e.g. experience) = failed!
            });
        };

        /**
         * @private
         * Callback when publishable json data has been preloaded by this channel, e.g. experience or group, where
         * applicable and configured. <p>
         *
         * Will notify the viewer proxy <tt>contentLoaded</tt> function with the supplied publishable, and update
         * the internal state of this channel with regards to content loading.
         *
         * @param {*}       [data]         The loaded publishable json data, e.g. experience or group; will be falsey
         *                                 on failure. Can be a json function wrapper from the cache.
         * @param {String}  [contentState] The loaded state of <tt>data</tt> which will determine if it will be
         *                                 persisted if local storage (if not already). Will default to "loaded".
         */
        this._contentLoaded = function (data, contentState) {
            // Set internal content state...
            scope.contentLoaded(data, scope.content.type, contentState, false); // false: not dirty

            // Call viewer with the content...
            scope.viewer().contentLoaded(data); // falsey/null data = failure, can be a no-op
        };

        /**
         * Notify this channel that the specified data of the given type has finished loading. <p>
         *
         * This will not notify the viewer (but called by the viewer).
         *
         * @param {*}       [data]         The loaded publishable json data, e.g. experience or group; null means
         *                                 failure to load the data.
         * @param {String}   type          The type of data loaded; will default to the configured type of
         *                                 content for this channel.
         * @param {String}  [contentState] The loaded state of <tt>data</tt> which will determine if it will be
         *                                 persisted if local storage (if not already). Will default to "loaded".
         * @param {Boolean} [dirty]        True if dirty (default), false otherwise. Internal flag!
         */
        this.contentLoaded = function (data, type, contentState, dirty) {
            var content = scope.content;
            if (content.type === type) { // no type on ajax callbacks
                if (data) {
                    // Default to "loaded", i.e. on ajax callbacks...
                    content.state = contentState || "loaded";

                    // Did it already come from our cache?
                    if (content.state === "local" || content.state === "cache") {
                        content.data = data; // function!

                    // Update global cache with the data. We now that json is available if we are receiving this
                    // callback, so it is safe to assume a return value here (but local storage may still be disabled,
                    // though). If the data is not cacheable it is by any means invalid and we will not store any
                    // content data...
                    } else {
                        // May not be dirty if initial content loaded by this channel...
                        content.data = state.cache.put(data.id, data, type, typeof dirty === "undefined" || dirty, scope._cacheable).data; // function
                        // Safe-guard...
                        if (!content.data) {
                            content.state = "failed";
                        }
                    }

                } else {
                    content.state = "failed";
                }

            // E.g. when a group is associated with this channel, but the viewer has finally loaded the chosen
            // experience. That is, the data we are getting is not the data stored in "content", but we still
            // want to cache it...
            } else if (data) {
                state.cache.put(data.id, data, type, true, scope._cacheable); // true: always dirty!
            }
        };

        /**
         * Finds a possible dom element match for this channel and if so inserts the channel content in to the supplied
         * matched element, or inserts the fallback image in legacy browsers. On no match, or if no content id is
         * available (admin mode), this is a no-op, just returning false. <p>
         *
         * In non-admin mode and if data preloading is enabled, this will be triggered here (if not already).
         *
         * @param  {*} [logId] A log name postfix to be supplied to the viewer on match, used to generate unique
         *                     viewer log names if more than one viewer is loaded via channels only, as they
         *                     will all write to the same console.
         *
         * @return {String}  The (potential new) state of this channel, e.g. "experience", "match", or "idle";
         *                   never falsey,
         */
        this.insert = function (logId) {
            // See if we can find a match in the dom, regardless if we have content or not. We will have content
            // unless in admin mode, where the channel can still be empty...
            var match = scope.matchElements();
            // If no matching elements at all, we cannot do a thing...
            if (match.element) {
                scope.insertContent(match, logId);
            }
            // What ever we did in "insertContent" to change the state will be ready here, even if we have to
            // preload fallback image first the state will already be set...
            return scope.state;
        };

        /**
         * Inserts the channel content in to the supplied match element, or inserts the fallback image in
         * legacy browsers. <p>
         *
         * In non-admin mode and if data preloading is enabled, this will be triggered here (if not already).
         *
         * @param  {Match} match   The match for the dom element to use; cannot be falsey and assumed to match a
         *                         valid element, i.e. <tt>match.element</tt> is not falsey.
         * @param  {*}     [logId] A log name postfix to be supplied to the viewer, used to generate unique
         *                         viewer log names if more than one viewer is loaded via channels only, as they
         *                         will all write to the same console.
         *
         * @return {Boolean} If the content/viewer was successfully inserted into the dom, false otherwise. On
         *                   false, <tt>remove</tt> will automatically have been called for this channel.
         *
         */
        this.insertContent = function (match, logId) {
            // If supported, go ahead and insert the content - this will always be the case when in admin mode!
            if (!scope._fallback()) {
                // Try and preload the content json, but *only* if so configured and where applicable; the json
                // can also be directly embedded in the channel config or already cached, and will be used if so...
                scope.loadContent();
                // Insert content/viewer and return true on success, false otherwise...
                return scope._insertContent(match, logId);
            }

            // If the content is an experience, we might have a fallback, but it may not be loaded yet. We return
            // true here in any case if an experience...
            if (scope.type() === "experience") { // note: this test only because we know Groups current do not have a fallback, but channels.js support it already!
                scope._insertFallbackContent(match);
                return true;
            }

            // Not supported by groups...
            return false;
        };

        /**
         * @private
         * Returns true if the fallback content should be displayed for this channel, false otherwise. <p>
         *
         * Note that just because the fallback should be displayed does not mean there is any such content or that
         * the content (e.g. a Group) support it; if that is the case, this channel will be restored to its original
         * state.
         *
         * @return {Boolean} True to (coerce) show the fallback content, if any, for the content associated with this
         *                   channel, false otherwise.
         */
        this._fallback = function () { // implementation note: separate hook, so admin can override this
            return !global.capabilities.isSupported;
        };

        /**
         * Inserts the content associated with this channel into the (first) supplied dom element. <p>
         *
         * If this channel has already injected its associated (fallback) content into a different dom element, the
         * (fallback) content will be removed from there and inserted into the supplied element. If the element is
         * the same element as the (fallback) content is already injected into, this method does nothing.
         *
         * @param  {Match}   match  The dom element(s) that matches the selector of this channel, where
         *                          "match.element" is the one is the one to insert the content associated with
         *                          this channel into; cannot be null. If more elements are stored in
         *                          "match.elements", they will retain their original visibility, i.e. not affected.
         * @param {*}       [logId] A log name postfix to be supplied to the viewer, used to generate unique
         *                          viewer log names if more than one viewer is loaded via channels only, as they
         *                          will all write to the same console.
         *
         * @return {Boolean} If the content/viewer was successfully inserted into the dom, false otherwise. On
         *                   false because the viewer insertion failed, <tt>remove</tt> will automatically have been
         *                   called for this channel, but false will also be returned if no content available yet.
         *
         * @see   #_insertFallbackContent
         * @see   #removeContent
         */
        this._insertContent = function (match, logId) {
            var element = match.element,
                type = scope.type();

            // Remove existing (fallback) experience where applicable...
            if (scope._dom.element) {
                scope._removeContent(type, element);
            }

            // No content means we can only set the state to "match". This also ensures that the original content
            // will not be affected by this (not hidden (yet))...
            scope.state = scope.content.id() ? type : "match";

            // Tag element as in use by this channel...
            scope._tagElement(element, true);

            // Prepare element for experience to be inserted, and append the iframe to it. We save the element
            // reference in "scope._dom.element", so we can restore is altered state if the experience is
            // removed again...
            scope._prepareElements(match);

            // Export sandboxed hook for channel/experience...
            scope._expose(true);

            // Do we have any content yet? Only ever so when in admin mode where empty channels are allowed...
            if (scope.state !== "match") {

                // We have content for the specified type...
                scope.state = type;

                // We inject an iframe to sandbox the Viewer, especially regarding CSS! The "about:blank" src ensures
                // that we do not get cross-domain problems...
                var iframe = dom.doc.createElement("iframe"),
                    style  = iframe.style;

                scope._dom.container = iframe;

                iframe.setAttribute("src", "about:blank");
                iframe.setAttribute("frameborder", "0");
                iframe.setAttribute("allowFullscreen", "true");
                iframe.setAttribute("id", scope._dom.id); // same id as fallback image

                style.width    = "100%";
                style.height   = "100%";
                style.position = "absolute";
                style.left     = "0";
                style.top      = "0";
                style.display  = "block"; // some sites sets display:none for all iframes!

                dom.visibility(iframe, "visible");

                // Element is already prepared and stored in "scope._dom.element" via the "_prepareElements" function
                // called above...
                element.appendChild(iframe);

                // Loads the viewer with the specified content in the supplied iframe and tags the viewer context.
                // The "Viewer.insert(..)" function will return null of problems inserting the viewer into the dom,
                // which have been observed on security errors or on bogus viewer html, e.g. CONFIG injected wrong...
                if (scope.viewer().insert(iframe, logId)) {
                    snippet.info("Channel initialised with " + type, scope, " -> viewer log postfix: " + format.val(logId || ""));
                    return true;
                }

                // Famous last words: this should never happen, only on wrong build/configuration!...
                snippet.info("Channel could not insert viewer with ", type, ", removing channel again", scope);
                scope.removeContent();
                // Fall-through!

            // We have a matched element, but no content for it. This can be the case in admin mode for empty
            // channels...
            } else {
                snippet.info("Channel dom match with no content", scope, match);
            }

            return false;
        };

        /**
         * Returns the id of the experience (fallback) currently shown by the viewer for this channel, if any. <p>
         *
         * If the content associated with this channel is an experience, the id will be that of said experience
         * when the viewer has been fully initialised. If the content is a group, the id will be that of the
         * experience the viewer chose from the group to show when the viewer has been fully initialised. If the
         * content is a fallback image, the id will be that of the experience. <p>
         *
         * Note that a viewer is not used at all for fallback images.
         *
         * @return {String} The id of the content currently shown by this channel; can be falsey.
         *
         * @see    #ready
         */
        this.shown = function () {
            return scope.ready() && scope.content.shown();
        };

        /**
         * Returns true if this channel is ready, false otherwise. <p>
         *
         * Ready means that its internal state is either "experience", "group", or "fallback", i.e. is shown with
         * some form of content.
         *
         * @return {Boolean} True if ready, false otherwise.
         * @see    #shown
         */
        this.ready = function () {
            var s = scope.state;
            return s === "experience" || s === "group" || s === "fallback";
        };

        /**
         * @private
         * Shows a loading indicator identified by the configured type for this channel, where applicable. <p>
         *
         * Based on observations for actual clients targeting dom elements with zero width and/or height,
         * loading indicators will look quite odd if enabled if that is the case. Hence, we check for this
         * before starting it.
         *
         * @see #_stopLoadingIndicator
         */
        this._startLoadingIndicator = function () {
            var indicator = LoadingIndicator.create(scope._loadingIndicator.options, scope), // "type" may also contain spinner options!
                viewport = scope._dom.viewport; // viewport fine at this point since we just determined the dom element

            if (indicator) {
                if (viewport.width && viewport.height) {
                    scope._loadingIndicator.indicator = indicator;
                    indicator.start();
                } else {
                    snippet.info("Loading indicator not started, zero-bounded viewport: ", format.bounds(viewport), scope);
                }
            }
        };

        /**
         * @private
         * Stops the loading indicator currently shown, if any.
         *
         * @see #_startLoadingIndicator
         */
        this._stopLoadingIndicator = function () {
            var indicator = scope._loadingIndicator.indicator;
            if (indicator) {
                scope._loadingIndicator.indicator = null;
                indicator.end();
            }
        };

        /**
         * @private
         * Inserts the fallback content associated with this channel into the matching dom element stored
         * in "match.element", for either an Experience or Group (the latter currently not supported by
         * the API). <p>
         *
         * If no fallback data has been loaded yet, it will be requested, calling this method again on loaded. <p>
         *
         * If this channel has already injected its associated (fallback) content into a different dom element, the
         * (fallback) content will be removed from there and inserted into the supplied element. If the element is
         * the same element as the (fallback) content is already injected into, this method does nothing.
         *
         * @param {Match} match The dom elements matching the css selector of this channel; never null.
         *
         * @see   #insertContent
         * @see   #remove
         */
        this._insertFallbackContent = function (match) {
            var element = match.element;

            // Remove existing (fallback) content where applicable...
            if (scope._dom.element) {
                scope._removeContent("fallback", element); // sets state!
            } else {
                scope.state = "fallback";
            }

            var fallback = scope.content.fallback;

            // We cannot show until we have loaded the fallback data, so we issue a request for the fallback data
            // which will call this again when ready...
            if (!fallback) {
                scope._loadFallbackContent(match);
                return;
            }

            var url = fallback.fallbackImageURL;

            // If there is no fallback image, just restore original content - which is there right now, since we
            // have not modified the prepared the dom at this point for the content/fallback content, though
            // the channel restore type may dictate that the original content should be hidden...
            if (!url) {
                snippet.info("No " + scope.content.type + " fallback available", scope);
                this._removeContent("idle", match.element);
                return;
            }

            // Make sure we have a valid url to use; some legacy issues have prefixed viewer (obsolete) url, most
            // do not...
            url = provider.url({viewer: url});

            snippet.verbose("Fallback image to load: ", format.val(url), scope);

            // Create fallback image...
            var image = new Image(),
                style = image.style,
                start = util.now();

            scope._dom.container = image;

            image.id  = scope._dom.id; // same id as iframe for Viewer
            image.src = url;

            style.width    = fallback.width  + "px";
            style.height   = fallback.height + "px";
            style.position = "absolute";
            style.left     = "0";
            style.top      = "0";
            style.display  = "block";

            dom.visibility(image, "hidden"); // only show on load to avoid curtain effect

            dom.ready({name: "insertFallbackContent", scope: snippet, element: image,
                callback: function () {
                    dom.visibility(image, "visible"); // only show now, to avoid curtain effect
                    snippet.verbose("Fallback image loaded in ", util.now() - start, " ms: ", format.val(url), scope);
                },
                errback: function () {
                    snippet.error("Fallback image error, showing original content: ", format.val(url), scope);
                    scope._removeContent("error"); // actually remove failing fallback...
                }
            });

            // Use name if available (legacy thing, not really relevant since never shown on mobile devices
            // anyway - removed to simplify backend logic significantly)...
            var name = fallback.name || scope.content.name();

            if (name) {
                image.title = name;
                image.alt   = name;
            }

            // Fallback link to activate when clicking on the fallback image?
            if (fallback.fallbackLink) { // TODO: link click could also be tracked via GA!
                image.onclick = function () {
                    snippet.verbose("Link on fallback image clicked: ", format.val(fallback.fallbackLink), scope);
                    global.win.open(fallback.fallbackLink, "_blank");
                };

                style.cursor = "pointer";
            }

            // Prepare target element and finally append fallback image...
            scope._prepareElements(match).appendChild(image);

            // Export sandboxed hook for channel/content...
            scope._expose(true);

            snippet.info("Channel initialised with " + scope.content.type + " fallback", scope);
        };

        /**
         * @private
         * Loads the fallback content data for this channel, only called when not loaded yet and where needed. <p>
         *
         * On actual finished load, <tt>insertFallbackContent(match)</tt> will be called. <p>
         *
         * Implementation: this support Group fallbacks as well, assuming same format and url naming scheme, but
         * we never push any Group fallback yet. Calling it for a Group (which we do not) will thus always cause
         * a 404 for now.
         *
         * @param {Match} match The dom elements matching the css selector of this channel; never null.
         */
        this._loadFallbackContent = function (match) {
            // The channel properties referenced here are known to be set at this point!...
            var id = scope.content.id(),
                type = scope.content.type,
                name = type.charAt(0).toUpperCase() + type.substr(1); // because of legacy awkward naming

            var callback = function (data) {
                // Ignore if already loaded (concurrently), since exposed globally because of jsonp...
                if (scope.content.fallback) {
                    snippet.info(name + " fallback already loaded", scope);
                } else {
                    scope.content.fallback = data || {}; // to avoid infinite loop!
                    scope._insertFallbackContent(match);
                }
            };

            // Cannot delete these references again, might be called explicitly as well in sync mode! The awkward
            // naming is generated as part of the jsonp data stored in the repository...
            global.win["__zmagsFallback" + name + "_" + id] = callback;

            // Legacy naming for experiences only...
            if (type === "experience") {
                global.win["__mosaik_fallbackExperience_" + id] = callback;
            }

            snippet.info("Loading " + type + " fallback", scope);
            provider.script({
                viewer: "/assets/fallback" + name + "s/" + id + ".jsonp",
                log: snippet,
                // We have not inserted "match.element" into the dom at this point, so we simply try and restore it
                // as-is for consistency (including logging)...
                errback: function () {
                    scope._removeContent("error", match.element);
                }
            });
        };

        /**
         * @private
         * Tags the specified dom element as used by this channel or removes the tags again. <p>
         *
         * When tagged, <tt>element</tt> will be tagged with three "data-" attributes: "data-snippet", "data-channel",
         * and "data-" + content.type, e.g. "data-experience", with the snippet id, channel id (name), and
         * content id, respectively. For groups, the element will eventually be tagged with the actual experience
         * shown as well (and cleared again). <p>
         *
         * This is used to look up dom elements associated with (another) channel and facilitates easy debugging. <p>
         *
         * These tags are also cleared from channels that originates from the dom, since the channel is already
         * associated with a snippet; a stop/restart would otherwise add yet another channel for the same element
         * again.
         *
         * @param {HTMLElement} element The element to (un-)tag; never null.
         * @param {Boolean}    [tag]    True to tag, false to remove tag (default).
         *
         * @see   #fromElement
         */
        this._tagElement = function (element, tag) {
            var content = scope.content,
                id = content.id();

            dom.data(element, "snippet", tag ? snippet.configId : null);
            dom.data(element, "channel", tag ? scope.id         : null); // channel name or id (admin)
            if (id) {
                dom.data(element, content.type, tag ? id : null); // e.g. "group"/"experience"
            }
            // Remember to remove the actual experience id in case of a group...
            if (!tag && content.type === "group") {
                dom.data(element, "experience", null); // e.g. "group"/"experience"
            }
        };

        /**
         * @private
         * Prepares the supplied element just before a Creator experience (Viewer) will be injected into it. This
         * will update the internal <tt>_dom</tt> state of this channel, with the element and style, as well as
         * old visibility and old position, for later restore. <p>
         *
         * <tt>match</tt> is the match found by <tt>matchElements</tt>. <p>
         *
         * At call time, the <tt>match.element</tt> is known to be tagged as belong to this channel!
         *
         * @param {Match} match The dom elements matching the css selector of this channel; never null.
         *
         * @see   #_handleVisibility
         * @see   #_restoreElement
         */
        this._prepareElements = function (match) {
            // Hide original content for "match.element"...
            scope._handleVisibility(match);

            // Element to inject experience into!
            var element = match.element;

            // We need to position our experience absolutely inside the element (on top of the current - invisible -
            // content). Positioning absolutely is not possible inside an element that has position:static, so we
            // must fix this. Note: Firefox used to have troubles with for example table cells, but that has been
            // fixed as of fall 2015, so we do not have to handle it here - for further info, see
            // https://bugzilla.mozilla.org/show_bug.cgi?id=63895#c210
            var pos = dom.style(element, "position"),
                style = element.style;

            if (pos === "static" || !pos) {

                // Save old in case the content is to be hidden again...
                scope._dom.position = {
                    position: pos,
                    left:     style.left,
                    top:      style.top,
                    right:    style.right,
                    bottom:   style.bottom
                };
                style.position = "relative";
                style.left     = "";
                style.top      = "";
                style.right    = "";
                style.bottom   = "";

                snippet.verbose("Setting target element position to relative for channel/" + scope.type(), scope, element);
            }
            return element;
        };

        /**
         * Ensures that the original content in "match.element" is hidden so the experience can be injected and shown,
         * while all other dom elements matching the dom selector, supplied as "match.elements", must retain their
         * original visibility.
         *
         * @param {Match} match The dom elements matching the css selector of this channel; never null.
         */
        this._handleVisibility = function (match) { // TODO: what about "display" - what if set to none?
            var element = match.element;

            // For "element", set element, and save old visibility before we hide it, so we can restore it later...
            scope._dom.element    = element;
            scope._dom.viewport   = match.viewport;
            scope._dom.visibility = dom.style(element, "visibility"); // computed style!

            // Hide original content in the target element if we have actual content to insert into it, but not
            // only on a "match" state...
            if (scope.state !== "match") {
                dom.visibility(element, "hidden");
            }
        };

        /**
         * Returns the (augmented) viewer proxy exposed in the content window of the supplied element if and only
         * if an iframe (and not a fallback element).
         *
         * @return {{close:Function, contentLoaded:Function}} The exposed viewer proxy, if any; can be falsey.
         */
        this.viewer = function () {
            if (!scope._viewer) {
                scope._viewer = new Viewer(scope);
            }
            return scope._viewer;
        };

        /**
         * Removes the content shown in this channel, if any, i.e. the (fallback) experience, or group. <p>
         *
         * If fallback data has already been loaded, it will not be cleared by this, making it fast to show a
         * fallback experience again.
         */
        this.removeContent = function () {
            scope._removeContent("idle");
        };

        /**
         * @private
         * Conditionally removes the (fallback) experience or group currently shown for this channel,
         * if any, if a different element and/or (fallback) experience or group is to be used. <p>
         *
         * Also called when a (new) experience or group is to be inserted or in case of replacing
         * (restoring) original content with the fallback image. <p>
         *
         * The state of this channel is updated to <tt>state</tt> after this methods completes.
         *
         * @param {String}      state     The state to ignore; never null.
         * @param {HTMLElement} [element] The (new) element to use; will default to <tt>_dom.element</tt>, which may
         *                                be falsey.
         * @return {Boolean} Returns true if the content was removed, false if ignored.
         */
        this._removeContent = function (state, element) {
            if (scope.state === state || scope._dom.element === element) {
                return false;

            } else if (!element) {
                element = scope._dom.element; // can still be falsey!
            }

            // Remove exported sandboxed hook for channel...
            scope._expose(false);

            // Shutdown the exposed viewer unless a fallback image...
            if (scope._viewer) {
                scope._viewer.close(); // can be a no-op
                scope._viewer = null;
            }

            // Remove iframe or fallback image, and shut down viewer for normal container (e.g. iframe or div).
            if (scope._dom.container) {
                dom.removeElement(scope._dom.container); // iframe or image
            }

            // Restore element properties...
            scope._restoreElement(element);

            // Remove channel references from other channels wanting to use the same dom element as this channel...
            scope._channels = [];
            // And do the reverse...
            snippet.each(function (channel) {
                var i = util.idx(channel._channels, scope);
                if (i !== -1) {
                    channel._channels.splice(i, 1);
                }
            });

            // Clear active dom elements and state, but keep id. In case this channel does not have a selector,
            // we know it used the current element since here, so we must retain that as well...
            scope._dom = {
                id:      scope._dom.id,
                element: scope._selector ? null : element // i.e. "current element"
            };

            // Reset channel state...
            scope.state = state || "idle";

            snippet.info("Channel restored with ", scope._restoreType === "hide" ? "hidden" : "original", " content", scope);

            return true;
        };

        /**
         * @private
         * Restores the supplied element just after an experience (Viewer) or fallback image have been removed from
         * it or hides it, depending on the restore type ("restore" or "hide", respectively). <p>
         *
         * <tt>element</tt> is the matching dom element found by <tt>matchElements</tt> originally.
         *
         * @param {HTMLElement} [element] The element to restore; can be null/undefined, in which case this function
         *                                does nothing.
         * @see    #_prepareElements
         */
        this._restoreElement = function (element) {
            if (!element) {
                return;
            }

            // Restore original visibility (if re-enabled)...
            dom.visibility(element, scope._dom.visibility);

            // Do we need to hide it altogether then...
            if (scope._restoreType === "hide") {
                dom.visibility(element, "none");
            }

            var type = scope.type();

            // Remove data attributes pointing to this channel, no longer relevant...
            scope._tagElement(element);

            // If a the content is a group, the "viewerShown" callback may have tagged the element with
            // "data-experience" as well, besides "data-group" that is...
            if (type === "group") {
                dom.data(element, "experience", null);
            }

            // Array...
            var processed = dom.data(element, "channel-processed").split(SEPARATOR),
                i = util.idx(processed, scope.qualifiedName());

            processed.splice(i, 1);
            dom.data(element, "channel-processed", processed.length ? processed.join(SEPARATOR) : null);

            // Did we have to modify the element position as well, before we inserted the experience?
            var pos = scope._dom.position, name;

            if (pos) {
                for (name in pos) {
                    element.style[name] = pos[name]; // TODO: on failure to load, this will sometimes generate a way too large viewport for table cells (when position is set to "static" again)
                }
                snippet.verbose("Resetting target element position for channel/" + type, scope, element);
            }
        };

        /**
         * Exposes this channel/experience globally in the sandbox or removes it from there.
         *
         * @param {Boolean} expose True to expose in sandbox, false otherwise.
         */
        this._expose = function (expose) {
            var sandbox = global.sandbox(),
                proxy = scope.proxy(),
                i = util.idx(sandbox.active, proxy);

            // Export...
            if (expose) {
                if (!sandbox.active) {
                    sandbox.active = [];
                }
                if (i === -1) {
                    sandbox.active.push(proxy);
                }

            // Remove...
            } else if (i !== -1) {
                sandbox.active.splice(i, 1);

                if (!sandbox.active.length) {
                    delete sandbox.active;
                }
            }
        };

        /**
         * The (proxy) object exposed to the world for this channel, i.e. client site. <p>
         *
         * The viewer in the embedded iframe will have access to this channel itself, not only to the proxy. <p>
         *
         * WHen called numerous times, the same proxy object may be returned, but it will still be backed by
         * the current state of this channel, i.e. correct dom references, updated content, etc.
         *
         * @return {*} The (proxy) object exposed to the world for this channel; never null.
         */
        this.proxy = function () {
            if (!scope._proxy) {
                scope._proxy = scope._createProxy();
            }
            return scope._proxy;
        };

        /**
         * @private
         * Creates a new proxy this channel. <p>
         *
         * Prefer using the public <tt>proxy()</tt> function as it will reuse the same public proxy instance. This
         * is only a separate function so it can be augmented with <tt>Viewer</tt> callbacks for the viewer only!
         *
         * @return {*} A new proxy for this channel; never null.
         */
        this._createProxy = function () {
            return {
                name:      scope.name,
                snippet:   snippet.proxy(),
                stop:      function () { snippet._restoreChannel(scope); },

                id:        scope.shown,
                content:   function () { return scope.content; }, // configured, but can be (pre-)loaded, also a hook for fallback

                container: function () { return scope._dom.container; },
                element:   function () { return scope._dom.element; },
                viewport:  function () { scope._dom.viewport = dom.bounds(scope._dom.element); return scope._dom.viewport; }, // re-calc!
                toString:  function () { return format.channel(scope); }
            };
        };

        /**
         * Returns the string representation of this channel.
         *
         * @return {String} The string representation; never null.
         */
        this.toString = function () {
            return format.channel(scope);
        };
    }

    /**
     * Creates a channel based on the supplied (json) data for the specified snippet, if possible. <p>
     *
     * A null channel will always be returned if its url (pattern) does not match that of the current context. <p>
     *
     * When in admin mode, a channel will be returned even if it is not valid; in non-admin mode, only valid
     * channels will be returned. <p>
     *
     * If the experience state is tagged as "inactive", no channel will be created either. <p>
     *
     * @param  {Snippet}   snippet  The snippet in question; never null.
     * @param  {*}         data     The (json) channel data or an Experience id; can be falsey.
     * @param  {Function} [process] Optional function <tt>Function(channel:Channel, data:*):Channel</tt> to post-process
     *                              the created channel; can be null.
     * @return {Channel} A corresponding channel, if possible; can/will be null, including if the url (pattern)
     *                   does not match the current context.
     */
    Channel.of = function (snippet, data, process) {
        // Client space, so we do a bit of sanity checks. The check on experience is to ensure we never show
        // data from a suspended company, i.e. the viewer will do the same check when loading the experience...
        if (data) {
            // Used to create "in-place channels" via id, e.g. using the current element at load time. If called
            // after document has loaded, the current element cannot be found, and the channel will eventually
            // be ignored since we do not know where to insert the Experience based on it...
            if (typeof data === "string") {
                data = {experience: {id: data}, configId: snippet.configId, element: dom.currentElement()};
            }
            // Make sure the channel matches our snippet if so specified...
            if (!data.configId || data.configId === snippet.configId) {
                var content = data.experience || data.group;
                // Try and cache json if embedded, even if the channel is not active - we may need it later.
                // The cache will ignore the put request if the json data is not embedded (loaded)...
                if (content && content.id) {
                    // Never valid, not even in DnD...
                    if (content.state === "invalid") {
                        return null;
                    }
                    state.cache.put(content.id, content, data.experience ? "experience" : "group", false, content.cacheable || snippet.cacheable);
                }

                // Only ever create channels for matching url, but a channel is allowed to be invalid in admin mode.
                // It must still have a css selector though, or it can never been shown in DnD. Note that we do not
                // test on "global.loc.real", since we all clients to view cached pages as well, e.g. via Google Cache...
                if (filter(data, global.loc.href)) { // TODO: supply global.loc.query as well (or just global.loc) to eventually support query parameters
                    var channel = new Channel(snippet, data);
                    if (channel.valid()) { // takes admin mode into account!
                        return process ? process(channel, data) : channel;
                    }
                    // Fall-though!
                }
            }
        }

        return null;
    };

    /**
     * Returns the channel associated with the supplied dom element, regardless of snippet, if any.
     *
     * @param  {HtmlElement} element The html element to find the channel for; cannot be null.
     * @return {Channel} The corresponding channel, if any; will be null on no match.
     */
    Channel.fromElement = function (element) {
        // Must have "snippet" and "channel" attributes and either an "experience" or "group" attribute...
        var id = dom.data(element, "snippet"), // any snippet!
            snippet,
            channel,
            content,
            i;

        if (id) {
            loop:
            for (i = 0; i < state.snippets.length; i++) {
                snippet = state.snippets[i];

                if (snippet.configId === id) {
                    if (snippet._channels) {
                        id = dom.data(element, "channel"); // name or id

                        for (i = 0; i < snippet._channels.length; i++) {
                            channel = snippet._channels[i];
                            if (channel.id === id) {
                                content = channel.content;
                                // We match on id, not on channel._dom.element, to ensure it will match regardless when called...
                                if (content.id() === dom.data(element, content.type)) { // e.g. experience id
                                    return channel; // TODO: what about null match?
                                }
                                break loop;
                            }
                        }
                    }

                    break;
                }
            }
        }

        return null;
    };

    Channel.Content = Content;
    Channel.Match   = Match;

    return Channel;
});

