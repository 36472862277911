define('channels/Dom',['require','channels/Global','channels/Util','channels/Format','channels/Log'],function (require) {
    "use strict";

    var global = require("channels/Global"),
        util   = require("channels/Util"),
        format = require("channels/Format"),
        Log    = require("channels/Log");

    /**
     * Defines a (singleton) "mini dom" used to manipulate the dom, only exposing the minimum set of channel
     * required dom methods.
     *
     * @author Gunni Rode  / <a href="http://www.zmags.com">Zmags</a>
     * @author Kim Knudsen / <a href="http://www.zmags.com">Zmags</a>
     */
    var dom = {

        /** @type {Document} The document/dom, where the body may/will change. */
        doc: global.win.document,

        /**
         * Returns the 'head' element for the specified document, defaulting to the
         * global document.
         *
         * @param {HTMLDocument} [doc] The document to find the head for, defaulting to <tt>dom.doc</tt>.
         * @return {HTMLElement} The 'head' element; never falsey.
         */
        head: function (doc) {
            if (!doc) { doc = dom.doc; }
            // The head will never change, even for single page apps, but legacy browsers have no "head" property...
            return doc.head || doc.getElementsByTagName("head")[0];
        },

        /**
         * Returns a reference to the body element, or falsey if the body has not been initialized.
         *
         * @return {HTMLElement} A reference to the body element, or falsey if the body
         *                       has not been initialized.
         */
        body: function () {
            return dom.doc && (dom.doc.body || dom.doc.getElementsByTagName("body")[0]);
        },

        /**
         * Returns the current executing script at invocation time. <p>
         *
         * Caveat: this <b>only</b> works when the document is loading in inline script tags, but when called after
         * the document has finished loading!
         *
         * @return {HTMLElement} The current executing script at invocation time while the document is loading,
         *                       otherwise falsey.
         * @see #currentElement
         */
        currentScript: function () {
            // If "currentScript" not supported and document has already loaded, we return falsey instead of some
            // random script...
            return dom.doc.currentScript || (!dom.docLoaded && (function () {
                var scripts = dom.doc.getElementsByTagName("script");
                return scripts[scripts.length - 1];
            })());
        },

        /**
         * Returns the current element deduced from the script calling this method while the document is loading.
         * More precisely, it is the parent element of the current script executing.
         *
         * @return {HTMLElement} The current element as described above, if any; can be null.
         * @see    #currentScript
         */
        currentElement: function () {
            var script = dom.currentScript();
            return script ? script.parentElement : null;
        },

        /**
         * Adds <tt>fn</tt> as a <b>one-time</b> event handler on <tt>element</tt> for the <tt>name</tt>d event.
         *
         * @param  {Log}           log     The log to use; cannot be falsey.
         * @param  {HTMLElement|*} element The html element, document, or window to add the handler to; cannot be null.
         * @param  {String}        name    The event name, minus "on" prefix; cannot be null.
         * @param  {Function}      fn      The function to call at most one time when <tt>element</tt> is ready;
         *                                 cannot be null. The function is assumed to be scoped and will be called
         *                                 without the <tt>Event</tt> argument only, or no args if already ready.
         * @return {Boolean} False if <tt>element</tt> is already ready and <tt>fn</tt> has been called once this
         *                   function returns, true otherwise.
         */
        on: function (log, element, name, fn) {
            // Already ready? Works for "document" in all major browsers, also for elements in IE...
            var state = element.readyState; // ignored for "window" object!
            if (state === "complete") {
                fn();
                return false;
            }

            var callback;
            // Modern browsers, e.g. Chrome...
            if (element.addEventListener) {
                callback = function (event) {
                    element.removeEventListener(name, callback, false);
                    fn(event);
                };
                element.addEventListener(name, callback, false);

            // Legacy browsers, i.e. old Explorer. We add more logging here, since old legacy browsers are
            // way less stable...
            } else if (element.attachEvent) {
                var win = element === global.win; // does not support readyState!
                name = "on" + name;
                callback = function (event) {
                    state = element.readyState; // undefined for "win"
                    if (state === "loaded" || state === "complete" || win) { // "complete" when cached content!
                        element.detachEvent(name, callback);
                        if (win) {
                            log.verbose("Window ready");
                        } else {
                            log.verbose("<" + element.tagName + "> ready: ", format.val(state), element);
                        }
                        fn(event || global.win.event);
                    }
                };
                element.attachEvent(name, callback);

            } else {
                log.error("Cannot add callback: ", format.val(element.tagName + "." + name), element);
            }
            return true;
        },

        /**
         * Adds the specified callback to be invoked once the dom is ready; do NOT use this for non-document elements.
         * The callback will only be invoked one time. <p>
         *
         * Options format:
         * <pre>
         * {
         *     name:     String,    // the function called.
         *     scope:    Log        // calling scope, also potentially doubling as a logger (or defaulting to global log).
         *     callback: Function,  // the no-arg callback to invoked on dom ready
         *     args:     *|[]       // single argument for "callback", if any (can be an array).
         * }
         * </pre>
         *
         * If the dom is ready at invocation time, <tt>options.callback</tt> is invoked immediately.
         *
         * @param {*} options The on-ready options; cannot be null.
         *
         * @see   #ready
         */
        docReady: function (options) {
            var scope = options.scope, log = scope && scope.verbose ? scope : Log.global(),
                callback = function () {
                    log.verbose("Dom ready callback: ", format.val(options.name));
                    options.callback(options.args); // already scoped
                    // Since we know the dom is ready, we can call the callback directly henceforth if such
                    // a callback is defined for the scope, e.g. "populateChannels"...
                    if (scope && scope[options.name]) {
                        scope[options.name] = options.callback;
                    }
                };

            // If we know for sure the document is ready, we do not bother with event handlers...
            if (dom.docLoaded) {
                callback();
                return;
            }

            // Make sure the callback is only invoked once, we assign it to multiple events...
            var invoke = util.one(log, options.name, callback);

            // Use the handy event callback, or fallback to window that will always work (slower). We will assign both
            // unless already ready...
            if (dom.on(log, dom.doc, dom.doc.addEventListener ? "DOMContentLoaded" : "readystatechange", invoke)) {
                dom.on(log, global.win, "load", invoke);
            }
        },

        /**
         * @type {Boolean}
         * Constant set to true when the document has loaded, false until then.
         */
        docLoaded: false,

        /**
         * Adds the specified callback to be invoked once the specified element is ready; do NOT use this for the
         * global dom, only for specific dom elements (after the dom is known to be ready). The callback will only be
         * invoked one time. <p>
         *
         * Options format:
         * <pre>
         * {
         *     element:  HTMLElement, // the dom element; never document, but can be script or image.
         *     name:     String,      // the function called.
         *     scope:    Log          // calling scope, also potentially doubling as a logger (or defaulting to global log).
         *     callback: Function,    // the callback to invoked on ready (see "args" below).
         *     args:     *|[],        // single argument for "callback", if any (can be an array); will default to
         *                            // the <tt>Event</tt> in question.
         *     errback:  Function     // optional handler to be called on error with the <tt>Event</tt> as the single
         *                            // argument, not "args"!
         * }
         * </pre>
         *
         * If the element is ready at invocation time, <tt>options.callback</tt> is invoked immediately.
         *
         * @param {*} options The on-ready options; cannot be null.
         *
         * @see   #docReady
         */
        ready: function (options) {
            // Make sure the callback is only invoked once, we assign it to multiple events...
            var log = options.scope.verbose ? options.scope : Log.global(),
                invoke = util.one(log, options.name, function (event) {
                    log.verbose("<" + options.element.tagName + "> ready callback: ", format.val(options.name));
                    if (options.callback) {
                        options.callback(options.args || event); // already scoped
                    }
                });

            // Add ready handler, and optional errback, but only if the content is not already ready...
            if (dom.on(log, options.element, options.element.addEventListener ? "load" : "readystatechange", invoke) && options.errback) {
                dom.on(log, options.element, "error", function (event) {
                    invoke.clear(); // clear timer, we have an errback to handle this, i.e. we know it is erroneous...
                    options.errback(event);
                });
            }
        },

        /**
         * Takes any native selector and escapes it properly in case it contains numbers in reserved positions,
         * e.g. at the start of any individual selector. <p>
         *
         * Handles:
         * <ul>
         * <li> Single id or class (#foo)
         * <li> Combined (#foo.bar)
         * <li> Descendant (#foo .bar)
         * <li> Immediate child (#foo>.bar)
         * </ul><p>
         *
         * @param {String} selector The selector to be escaped; cannot be null/undefined.
         * @return {String} The escaped selector; never null/undefined.
         * @throws {Error} If <tt>selector</tt> is null/undefined.
         */
        escapeSelector: function (selector) {
            // The function supplied for each match takes 3 arguments: "all" = entire match, e.g. ".12foo" (not used),
            // "escaped" = the selector id or class prefix, e.g. ".", and "digits" = the digits (string!) immediately
            // following the class or id selector, e.g. "12"...
            return selector.replace(/([#\.])(\d+)/g, function (all, escaped, digits) {
                for (var i = 0; i < digits.length; i++) {
                    escaped += "\\3" + digits[i];
                }
                return escaped + " "; // escaping ends with a space!
            });
        },

        /**
         * Returns the elements matched by the passed CSS selector. <p>
         *
         * This is not a jQuery selector but a native one, so we use <tt>querySelectorAll</tt> (for non-legacy
         * browsers); otherwise we default to our custom Sizzle, expected to be ready in the sandbox at the
         * time of call.
         *
         * @param   {String} selector The CSS selector used, never falsey.
         * @returns {NodeList} All matched elements; can be empty.
         * @throws  {Error} If <tt>selector</tt> is invalid, or if legacy browser and Sizzle not loaded yet.
         */
        selectElements: function (selector) {
            selector = dom.escapeSelector(selector);

            // Custom Sizzle is (already) loaded when no native CSS selector support...
            return global.capabilities.isQuerySelectorSupported ?
                dom.doc.querySelectorAll(selector) :
                global.sandbox().sizzle(selector);
        },

        /**
         * Returns a new iframe with src "about:blank" with a display style of "none", attached to the current
         * body if already loaded, otherwise unattached.
         *
         * @return {HTMLIFrameElement} The created iframe, possibly attached; never null. To test if the iframe is
         *                             attached, query for its "contentDocument" property (will be available if
         *                             attached).
         */
        iframe: function () {
            var iframe = dom.doc.createElement("iframe");
                iframe.setAttribute("src", "about:blank");
                iframe.style.display = "none";

            var body = dom.body();
            if (body) {
                body.appendChild(iframe);
            }

            return iframe;
        },

        /**
         * Constructs an <tt>HTMLStyleElement</tt> containing the specified CSS string.
         * The element is appended to the head of the current context.
         *
         * @param  {String} css A valid CSS string. E.g. <tt>.zmags-node {opacity: 0;}</tt>.
         */
        css: function (css) {
            var style = dom.doc.createElement("style");
                style.type = "text/css";

            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(dom.doc.createTextNode(css));
            }

            dom.head().appendChild(style);
        },

        /**
         * Adds the specified class name to the given element.
         *
         * @param {HTMLElement} element   The target element.
         * @param {String}      className The class name to append to the element's class list.
         */
        addClass: function (element, className) {
            if (element.classList) {
                element.classList.add(className);
            } else {
                element.className += " " + className;
            }
        },

        /**
         * Removes the specified element from it's parent, if any.
         *
         * @param  {HTMLElement} element The element to remove; if falsey, this function does nothing.
         */
        removeElement: function (element) {
            if (element && element.parentNode) {
                element.parentNode.removeChild(element);
            }
        },

        /**
         * Returns the computed style property for the specified dom element.
         *
         * @param  {HTMLElement} element  The dom element to find the property for; cannot be null.
         * @param  {String}      property The property name; cannot be null.
         * @return {String} The specified property value as a string, or falsey if no such property.
         */
        style: function (element, property) {
            var value = null;
            // Modern browsers...
            if (global.win.getComputedStyle) {
                // IE will throw an error if the passed element can't have styles (such as a text node)
                try {
                    value = global.win.getComputedStyle(element).getPropertyValue(property);
                } catch (e) {
                    // Fall-through - value will be falsey...
                }

            // Polyfill for old legacy IE browsers; this implementation is suited to our needs, does not cover all cases!
            } else if (element.currentStyle) {
                property = util.camelCase(property);
                value = element.currentStyle[property]; // not really computed style as such...
                while (value === "inherit") {
                    element = element.parentElement;
                    if (element && element.currentStyle) {
                        value = element.currentStyle[property];
                    } else {
                        break; // "inherit"
                    }
                }
            }
            return value;
        },

        /**
         * Sets the visibility for the supplied dom element.
         *
         * @param {HTMLElement} [element]    The element to set the visibility for; this function does nothing if falsey.
         * @param {String}      [visibility] The visibility to set for "element" as "visible", "hidden", "inherit",
         *                                   "none" or "block". The latter two will set the "display" property, the
         *                                   former the "visibility" property. This function does nothing if falsey.
         */
        visibility: function (element, visibility) {
            if (element && visibility) {
                // This also applies to legacy browsers...
                var prop = visibility === "none" || visibility === "block" ? "display" : "visibility";
                element.style.cssText += "; " + prop + ": " + visibility + " !important";
            }
        },

        /**
         * Returns the bounds for the specified element, if supplied, or the bounds for the browsers viewport. <p>
         *
         * Different browsers may give different results! Also note that properties such as "left" can be negative,
         * e.g. if currently positioned outside the actual browser viewport and thus not visible to the end user.
         *
         * @param  {HTMLElement} [element] The element to get the bounds for; can be falsey, in which case the bounds
         *                                 for the browsers viewport is returned.
         * @return {{width:Number, height:Number, left:Number, top:Number, right:Number, bottom:Number}} The bounds;
         *         never null, but individual properties may be undefined, i.e. not available.
         */
        bounds: function (element) {
            var bounds = {};
            // Element...
            if (element) {
                if (element.getBoundingClientRect) {
                    bounds = element.getBoundingClientRect() || {};
                }
                // Legacy IE returns a client rect, but without width and height, and it cannot be updated
                // so we have to make a copy with width and height... :/
                if (typeof bounds.width === "undefined" || typeof bounds.height === "undefined") {
                    bounds = {
                        width:  element.offsetWidth  || (bounds.right - bounds.left), // also works for negative values
                        height: element.offsetHeight || (bounds.bottom - bounds.top),
                        top:    bounds.top,
                        left:   bounds.left,
                        bottom: bounds.bottom,
                        right:  bounds.right
                    };
                }

            // Browser (window) viewport...
            } else {
                bounds = {top: 0, left: 0};
                // Prefer jQuery calculation if available since it should take everything into account...
                var $ = global.$(), win, w, h;
                if ($) {
                    win = $(global.win);
                    w = win.width(); // jQuery defines these!
                    h = win.height();
                }
                bounds.width  = bounds.right  = w || global.win.innerWidth;
                bounds.height = bounds.bottom = h || global.win.innerHeight;
            }
            return bounds;
        },

        /**
         * Gets, sets, or clears the named "data" attribute from the specified dom element.
         *
         * @param {HTMLElement} element  The dom element to get/set/clear the "data" attribute for; cannot be null.
         * @param {String}      name     The attribute name, <b>excluding</b> prefixed "data-"; cannot be null.
         * @param {*}           [value]  The value to set the string value of, if any; null means clear, any other value
         *                               will be set. If no supplied at all, the data will be returned.
         * @return {String} The "data" attribute for the specified dom element (will be <tt>value</tt> if supplied);
         *                  never null.
         */
        data: function (element, name, value) {
            name = "data-" + name;
            if (arguments.length === 2) {
                value = element.getAttribute(name) || "";
            } else if (value === null) {
                element.removeAttribute(name);
            } else {
                element.setAttribute(name, "" + value);
            }
            return value;
        }

    };

    // Update our handy constant when document is ready...
    dom.docReady({name: "docLoaded", callback: function () { dom.docLoaded = true; }});

    return dom;

});

