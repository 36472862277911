define('channels/Snippet',['require','channels/Channel','channels/Dom','channels/Format','channels/Global','channels/analytics/GoogleAnalytics','channels/LoadingIndicator','channels/Log','channels/analytics/PiwikAnalytics','channels/Provider','channels/State','util/TimerLogger','channels/Util','channels/ViewerBridge'],function (require) {
    "use strict";

    var Channel          = require("channels/Channel"),
        dom              = require("channels/Dom"),
        format           = require("channels/Format"),
        global           = require("channels/Global"),
        GoogleAnalytics  = require("channels/analytics/GoogleAnalytics"),
        LoadingIndicator = require("channels/LoadingIndicator"),
        Log              = require("channels/Log"),
        PiwikAnalytics   = require("channels/analytics/PiwikAnalytics"),
        provider         = require("channels/Provider"),
        state            = require("channels/State"),
        TimerLogger      = require("util/TimerLogger"),
        util             = require("channels/Util"),
        Viewer           = require("channels/ViewerBridge");

    var timerLogger = new TimerLogger(Log.timer());

    /**
     * @class
     * A <i>mode</i> describes the (loading) mode of a given snippet with regards to loading channel, and optionally
     * viewer, data. <p>
     *
     * The mode may change over type, e.g. initially "manual", then "auto" once started. All channel modes but "admin"
     * will carry over the viewer mode, e.g. "manual:data" first time, then "auto:data" next time.
     *
     * @param {String} [mode] The channel (and optionally viewer) mode, as a colon separated string. An empty mode
     *                        will default to "auto" only (no viewer mode).
     */
    function Mode (mode) {
        var m = mode || "auto",
            v = "",
            i = util.idx(m, ":"); // TODO: use util.options eventually

        if (i !== -1) {
            v = m.substr(i + 1);
            m = m.substr(0, i);

            if (m === "admin") {
                v = ""; // not applicable here!
            }
        }

        /**
         * @type {String}
         * The channel mode: "admin", "auto", "preload", or "manual". <p>
         *
         * The value may change over time, e.g. from "preload" to "auto" once preloading is done (data ready). <p>
         *
         * Will be forced "manual" if the config id supplied is a wild-card ("*"), i.e. the actual snippet id not
         * yet known.
         */
        this.mode = m;
        /**
         * @type {String}
         * The (additional) viewer mode for "auto", "preload", and "manual" modes: "html", "js", "lib", and/or "data"
         * as a colon separated string. Used for viewer and/or viewer data preload. <p>
         *
         * Can be empty, but never null/undefined.
         */
        this.viewer = v;

        /**
         * Returns true of this snippet mode equals the specified mode, optionally if the viewer mode as well.
         *
         * @param {String|String[]|{mode:String|String[], viewer:String}} options The mode and/or viewer options to test
         *                                                                        against this mode. If a string or array,
         *                                                                        assumed to be tested against
         *                                                                        <tt>mode</tt>.
         * @return {Boolean} True if the modes match, false otherwise.
         */
        this.is = function (options) {
            if (options.length) { // string or array
                options = {mode: options};
            }
            if (options.mode && util.idx(options.mode, this.mode) === -1) { // supplied "mode" can be string or array of strings!
                return false;
            }
            return !options.viewer || util.idx(this.viewer, options.viewer) !== -1; // stored viewer mode colon separated!
        };

        /**
         * Merges the supplied channel (and viewer) mode(s), if any, into this mode. <p>
         *
         * If <tt>mode</tt> is supplied, the channel mode will be set on this mode, while the viewer mode will
         * be appended to the viewer mode already used, if any.
         *
         * @param {String} [mode] The channel (and optionally viewer) mode(s), as a colon separated string. An empty
         *                        mode is ignored.
         * @return {Mode} This mode, now potentially merged; never null.
         */
        this.merge = function (mode) {
            if (mode) {
                var m = new Mode(mode);
                this.mode = m.mode;
                if (m.viewer) {
                    if (this.viewer) {
                        this.viewer += ":" + m.viewer;
                    } else {
                        this.viewer = m.viewer;
                    }
                }
            }
            return this;
        };

        /**
         * Returns the string representation of this mode.
         *
         * @return {String} The string representation; never null.
         */
        this.toString = function () {
            var s = this.mode;
            if (this.viewer) {
                s += ":" + this.viewer;
            }
            return s;
        };
    }

    //

    /**
     * @constructor
     * Describes a snippet script tag and its (deduced) properties based on the supplied (json) data. <p>
     *
     * <tt>options</tt> must have the following format:
     * <pre>
     *   {
     *     configId:         String,        // The channel configuration id; mandatory.
     *     url:              String,        // The channel url for the config; optional, will default to configured value.
     *     mode:             String,        // The channel mode: "admin", "auto", "manual", or "preload"; optional,
     *                                      // will default to "auto". For "auto" and "preload", "html", "js", "lib",
     *                                      // and/or "data" can be added as well, separated by colon, like
     *                                      // "auto:js:data" for preloading viewer JavaScript code and experience
     *                                      // json for experiences to be inserted.
     *     restoreType:      String,        // The optional type of behaviour when a channel/experience associated
     *                                      // with this snippet is removed from the dom: "restore" (default, show
     *                                      // original content) or "hide" (use display:none for target element,
     *                                      // e.g. when the target element is known to be a "blank space" this can
     *                                      // look better).
     *     loadingIndicator: String,        // Optional loading indicator style, e.g. "spinner"; defaults to
     *                                      // no loading indicator ("none").
     *     timeout:          Number,        // Optional timeout in ms used by this snippet, and thus all channels
     *                                      // controlled by it, to remove the content again if the Viewer has
     *                                      // successfully loaded by then. The original content will be restored
     *                                      // as dictated by <tt>restoreType</tt>. Default 30 seconds.
     *     cacheable:        Number,        // Optional timeout in ms used by this snippet, and thus all channels
     *                                      // controlled by it, to cache raw json data such as loaded experiences and
     *                                      // groups, possibly allowing for faster reloads or navigations to other
     *                                      // channel enabled pages on the client site. Default 60 seconds.
     *     preview:          String|Number, // The bookmarklet version when in preview, a NaN otherwise. Will default to
     *                                      // 0, i.e.  not preview.
     *     log:              Number,        // Optional log level to use for this snippet, defaulting to the global
     *                                      // set log level.
     *     sort:             Function,      // Channel sorter to sort all unprocessed channels before they are
     *                                      // processed in order to be populated, if any.
     *     process:          Function,      // Channel post-processor to augments valid created channels for this
     *                                      // snippet, if any.
     *     script:           HTMLElement    // Optional reference to the < script > tag this snippet is created for,
     *                                      // used to update the "data-configId" attribute when promoted from
     *                                      // will-card to actual snippet.
     *  }
     * </pre>
     *
     * @param {*} options The snippet options as described above; never falsey.
     *
     * @author Gunni Rode / <a href="http://www.zmags.com">Zmags</a>
     */
    function Snippet (options) {
        // jscs:disable safeContextKeyword
        var scope = this;
        // jscs:enable safeContextKeyword

        /**
         * @type {String}
         * The configuration id used by this snippet.
         */
        this.configId = options.configId;

        /**
         * @private
         * Returns the embedded config for this snippet from the sandbox, if any. <p>
         *
         * If a non-falsey value is returned, it will have the following format:
         * <pre>
         *    {
         *       channels: [],     // channels as json
         *       mode:     String  // embedded mode, if any; if defined, will be merged with the default snippet mode
         *    }
         * </pre>
         *
         * @return {*} The embedded config for this snippet, if any; otherwise falsey.
         */
        this._embedded = function () {
            // Use the embedded configuration for the channel config id used by *this* snippet, if any...
            var configs = global.sandbox().configs,
                config = configs && configs[scope.configId]; // TODO: what about configs not stored under id as well?

            return scope._config(config);
        };

        /**
         * @private
         * Ensures legacy stored channel configs only storing a channel array will be wrapped in a plain
         * JS object (json) mimicking an empty channel config with said channels as the "channels" array.
         *
         * @param {*} [config] The config or channels to ensure matches the channel config format; can be
         *                     falsey, in which case falsey is returned.
         * @return {{channels: [], ..}} A channel config, either <tt>config</tt> as is or a new wrapper
         *                              like <tt>{channels: config, ..}</tt>; can be falsey if <tt>config</tt>
         *                              is falsey.
         */
        this._config = function (config) {
            // Make sure we can handle legacy format, which is just channels embedded as an array, not
            // the entire config...
            if (config && typeof config.length !== "undefined") {
                config = {channels: config};
            }
            return config;
        };

        /**
         * Returns true if this snippet is the single wild-card snippet registered. If so, it will have its
         * actual id set once in-place channels are eventually added, based on the first supplied config id. <p>
         *
         * If a configuration id is supplied and this snippet is currently in wildcard mode, the id will be set
         * for this snippet; otherwise ignored.
         *
         * @param  {String}  [configId] The config id to set for this snippet if about to be upgraded.
         * @return {Boolean} True if this snippet has a wild-card id, false otherwise.
         */
        this.wildcard = function (configId) {
            var wildcard = scope.configId === "*";
            if (configId && wildcard) {
                // Set id for this snippet...
                scope.configId = configId;
                // Set id for this snippet proxy...
                if (scope._proxy) {
                    scope._proxy.id = configId;
                }
                // And remember to update the backing <script>, if any, or "stop()" and (re-) "start()" will not
                // work as expected if channels are added as "in-place" channels...
                if (scope._script) {
                    dom.data(scope._script, "channel", configId);
                }
                scope.info("Qualified wild-card snippet with id", scope);
            }
            return wildcard;
        };

        /**
         * @private
         * Deduces the snippet channel (and viewer) mode based on the supplied mode, if any. By default, the
         * mode will be a channel mode of "auto" and no viewer mode.
         *
         * @param {String|Mode} [mode] The channel mode: "admin", "auto", "manual", or "preload"; optional, will default to
         *                             "auto". For "auto" and "preload", "html", "js", "lib", and/or "data" can be added as
         *                             well, separated by colon, like "auto:js:data" for preloading viewer JavaScript code
         *                             and experience json for experiences to be inserted.
         * @return {Mode} The mode to use for this snippet henceforth; never null.
         */
        this._deduceMode = function (mode) {
            mode = mode instanceof Mode ? new Mode(mode.toString()) : new Mode(mode);

            // Force this snippet to "manual" if currently in wild-card mode. We have no other choice here, even if
            // we have a mode stored (for debugging) in session storage, as this snippet will blow up if we use
            // anything other that "manual" because of the wildcard...
            if (scope.wildcard()) {
                mode.mode = "manual";

            // We may have a mode bundled with embedded configs in the sandbox, so respect those by merging them with
            // the mode already set. It does not make sense to ever check this in wildcard mode (above) as the
            // embedded bundling requires a known config id...
            } else {
                var channelConfig = scope._embedded();
                if (channelConfig) {
                    mode.merge(channelConfig.mode); // merging to respect client deployment of snippet script
                }
            }
            return mode;
        };

        /**
         * @type {Mode}
         * The (loading) mode for this snippet, where the <tt>mode</tt> property is as an enum: "admin", "auto",
         * "manual", or "preload", and where the <tt>viewer</tt> property is a colon separated string with the
         * enum values "html", "js", "lib", and/or "data". All modes but "admin" can be combined with the viewer modes. <p>
         *
         * Once this snippet has been started one time, the mode will always be set to "auto". On "manual", starting
         * this snippet the first time does nothing. On "preload", the channel data is preloaded and filtered, but
         * channels are not populated. Second time started, the mode is thus always "auto", and the channel data
         * will be loaded (i.e. on original "manual" mode) and the experiences inserted into relevant matching
         * channels. <p>
         *
         * In addition, for the modes "auto", "preload", and "manual", the viewer itself or the experience json data
         * used by it, can be preloaded as well. For example, "auto:data" will preload experience json data for
         * inserted experiences, while "auto:data:html" will preload viewer html and experience data for any inserted
         * experience. For "manual:data", starting the snippet the first time does nothing, while next time it
         * runs as "auto:data". <p>
         *
         * The viewer will use the preloaded data.
         */
        this.mode = scope._deduceMode(options.mode);

        if (options.url) {
            this.baseUrl = provider.url({url: options.url.substring(0, options.url.lastIndexOf("/") + 1)}); // drop "channels.js" from url, and use correct protocol
        } else {
            this.baseUrl = provider.url({viewer: ""}); // viewer url, but adjusted to protocol...
        }

        /**
         * @type {String}
         * The type of "restoration" made when a channel/experience is removed from the dom (again), either
         * because the channel is explicitly removed or because the viewer could not be initialised successfully,
         * for example. <p>
         *
         * Individual channels for this snippet can specify their specific restoration type via json, overriding
         * this value, or will default to this value if not specified otherwise. <p>
         *
         * Valid types: <ul>
         * <li> "restore":  restores original content (default behaviour).
         * <li> "hide":     hides the original content using css 'display:none', which can be useful (look good) when
         *                  the original content is known to be a "blank space".
         * </ul>
         *
         * An unknown value will always be treated as "restore".
         */
        this.restoreType = options.restoreType || "restore"; // TODO: support "fallback", including keeping spinner running while fallback loads.
        /**
         * @type {String}
         * The loading indicator type used by this snippet and thus all channels controlled by it, e.g. "spinner". <p>
         *
         * Individual channels for this snippet can specify their specific spinner type via json, overriding
         * this value, or will default to this value if not specified otherwise. <p>
         *
         * Valid types: <ul>
         * <li> "spinner":  Standard spinner.
         * <li> "none":     no spinner (default).
         * </ul>
         *
         * An unknown value will always be treated as "none".
         */
        this.loadingIndicator = options.loadingIndicator || LoadingIndicator.TYPE.None;
        /**
         * @type {Number}
         * The timeout in ms used by this snippet, and thus all channels controlled by it, to remove the
         * content again if the Viewer has successfully loaded by then. The original content will be restored
         * as dictated by <tt>restoreType</tt>. <p>
         *
         * Individual channels for this snippet can specify their specific timeout via json, overriding
         * this value, or will default to this value if not specified otherwise. <p>
         *
         * The default value is 30000 ms, i.e. 30 seconds.
         */
        this.timeout = util.time(options.timeout, 30000);
        /**
         * @type {Number}
         * The timeout in ms used by this snippet, and thus all channels controlled by it, to cache raw json
         * data such as loaded experiences and groups, possibly allowing for faster reloads or navigations
         * to other channel enabled pages on the client site. <p>
         *
         * Individual channels for this snippet can specify their specific cacheable timeout via json, overriding
         * this value, or will default to this value if not specified otherwise. <p>
         *
         * A zero or negative value means cache is disabled for the snippet and its channels. <p>
         *
         * The default value is 60000 ms, i.e. 60 seconds.
         */
        this.cacheable = util.time(options.cacheable, 60000);
        /**
         * @private @type {HTMLElement}
         * The <script> element that caused this snippet to be created, if any. Can be falsey. <p>
         *
         * If supplied and this snippet has a wildcard id, the "data-config-id" attribute will be
         * updated with the proper id once this snippet is assigned the id. This is required to
         * make "app.stop()" and "app.start()" work correctly!
         */
        this._script = options.script;
        /**
         * @private @type {*}
         * The (proxy) object exposed to the world for this snippet.
         */
        this._proxy = null;
        /**
         * @private @type {Number}
         * The preview version (of the bookmarklet), or zero if not in preview.
         */
        this._preview = util.int(options.preview);
        /**
         * @private @type {Number}
         * Index of the first unprocessed channel in <tt>_channels</tt>. Any channel before this index will have
         * already been processed, allowing additional channels to be added programmatically later on, e.g. as
         * "in-place channels".
         */
        this._processed = 0;
        /**
         /**
         * @private @type {Function}
         * A <tt>Channel</tt> sorter used to sort all unprocessed channels before the channels are processed
         * and populated. <p>
         *
         * Typically used in admin mode to ensure additional channel properties are maintained, such
         * as the channel id while editing channels.
         *
         * @see #sort
         */
        this._sort = options.sort;
        /**
         * @private @type {Function}
         * A <tt>Channel</tt> post-processor used to augment channels for this snippet, if any. <p>
         *
         * Typically used in admin mode to ensure additional channel properties are maintained, such
         * as the channel id while editing channels.
         *
         * @see Channel#of
         */
        this._process = options.process;
        /**
         * @private @type {Channel[]}
         * The filtered channels loaded/added for this snippet, automatically set via jsonp callback either initiated
         * from this script, or by the client including a script to fetch said data directly on their page, or
         * by adding "in-place channels" via the channel api during page load, or set specifically when in admin
         * mode. <p>
         *
         * Note that even for single page apps, the url will not change, except for the hash. However, channel urls
         * does not take hashes into account, so they can safely be filtered once and for all against the url. <p>
         *
         * Each <tt>Channel</tt> will have been post-processed according to <tt>_process</tt>, e.g. to maintain
         * additional attributes.
         */
        this._channels = null;
        /**
         * @private @type {Number}
         * Timestamp when viewer html preload based on <tt>mode</tt> has been requested for preload by this snippet,
         * zero otherwise. <p>
         *
         * The viewer or its libs will only be preloaded when the mode is either "auto" or "preload" (and thus not
         * "admin", nor "manual"). <p>
         *
         * Set by <tt>Viewer</tt>.
         *
         * @see Viewer#preload
         */
        this._preloadViewer = 0;

        /**
         * @type {Cache}
         * The (global) experience cache used by this snippet (and all other snippet for the same app). <p>
         *
         * Automatically setup by the application before this snippet is in use with regards to loading
         * channel and experience data.
         */
        this.cache = null; // TODO: remove this and use flag instead!
        /**
         * @type {Logger}
         * The logger used and named accordingly to this snippet (config id); never null.
         */
        this.logger  = null;
        /**
         * @private @type {Number}
         * The default log level to use for this snippet, defaulting to the global log level.
         */
        this._level = options.log;

        // Short cut log methods...
        this.error   = null;
        this.info    = null;
        this.verbose = null;

        /**
         * Setup the specified log for this snippet.
         *
         * @param {Log}           log     The log to use and setup; never null.
         * @param {Number|String} [level] The log level, from <tt>Log.Levels</tt>, defaulting to the configured
         *                                level for this snippet, or finally to the global configured log level.
         */
        this._setupLog = function (log, level) {
            scope.logger  = Log.setup(log, level || scope._level || global.defaultLogLevel());
            scope.error   = log.error;
            scope.info    = log.info;
            scope.verbose = log.verbose;
        };

        /**
         * Returns the supplied relative url qualified with the base url of this snippet. <p>
         *
         * Does not check if <tt>url</tt> is already prefixed!
         *
         * @param {String} url The url to prefix with the base url of this snippet.
         * @return {String} The qualified url.
         */
        this.url = function (url) {
            return scope.baseUrl + url;
        };

        /**
         * Returns true if this snippet is configured to start admin mode, either specifically or globally configured.
         *
         * @return {Boolean} True if this snippet will start admin mode, false otherwise.
         */
        this.admin = function () {
            return scope.mode.is("admin") || util.boolean(global.config(global.QUERY.adminModeIdentifier));
        };

        /**
         * @private
         * Dequeues all, typically "in-place", channels enqueued before the channel snippet was loaded, or
         * while stopped, for this snippet only. The dequeue time does not have to be after the document
         * is ready. <p>
         *
         * This may cause duplicate channel ids/names, and sorting can be used to determine the order. <p>
         *
         * Note: "in-place" channels are not editable via DnD as they do not store their information in a channel
         * config, but directly in the client page. If <tt>keepQueue</tt> is true, such in-place channels will not
         * be removed from the queue.
         *
         * @param  {Boolean} [keepQueue] True to keep the queue, false to remove it (default). Only kept in admin mode.
         * @return {Number}  The number of channels actually dequeued and added to this snippet. The entries used to
         *                   create channels for this snippet will be removed from the queue, including those that was
         *                   used to create invalid channels, e.g. missing content but associated with this snippet.
         */
        this._dequeueChannels = function (keepQueue) {
            var sandbox = global.sandbox(),
                queue = sandbox.queue,
                count = 0;

            if (queue) {
                scope.info("Dequeueing channels for", scope, queue);
                // Does *not* call "_channelsReady"!
                count = scope._addChannels(queue, "manual", keepQueue);

                // Can still be queue entries for other snippets. If "keepQueue" is true, we will not have removed
                // any entries from the queue and the queue length will not have changed; hence, if zero length,
                // we can remove it just fine...
                if (!queue.length) {
                    delete sandbox.queue;
                }
            }
            return count;
        };

        /**
         * @private
         * Parses the current dom for channels already defined based on relevant "data-*" attributes, like
         * "data-channel" and "data-experience". <p>
         *
         * For each such dom element found, a channel will be created and added to the array of channels
         * maintained by this snippet, which may of course cause duplicate channel ids, but they will be
         * tagged with an origin of "dom". To handle which channel comes first, sorting of the channels
         * array should be used to determine order. <p>
         *
         * If such channels are stopped again and restored to their original content, the "data-*" attributes
         * will be cleared as for any other channels to ensure a restart does not add the same channel
         * again. <p>
         *
         * The population of such channels will adhere to the mode of this snippet, i.e. will only be populated
         * if in "auto" mode, otherwise <tt>populateChannels</tt> must explicitly be called. <p>
         *
         * A channel will be created if the following "data-*" attributes are found on a dom element:
         * <pre>
         *   "data-channel":    String,  // must match the <tt>configId</tt> of this snippet
         *   "data-group":      String,  // a Group id OR (AND)
         *   "data-experience": String,  // an Experience id.
         * </pre>
         */
        this._parseChannels = function () { // TODO: a channel should be fully configurable via a dom element as well, like a snippet. For now, this is just Google cache related
            dom.docReady({name: "_parseChannels", scope: scope, callback: function () {
                scope.verbose("Parsing dom for channels on url: ", format.val(global.loc));
                var elements = dom.selectElements("[data-snippet='" + scope.configId + "']"),
                    channels = [],
                    channelId,
                    channel,
                    element,
                    type = "experience",
                    id,
                    n,
                    i;

                for (i = 0; i < elements.length; i++) {
                    element = elements[i];
                    channelId = dom.data(element, "channel");
                    if (channelId) {
                        // Always test for groups first as it will also generate a "data-experience" attribute when
                        // the channel is populated. And, we cannot simply use the exact Experience as the Viewer group
                        // logic will kick in at runtime to handle the group behaviour, e.g. show the proper Experience
                        // according to a given breakpoint...
                        id = dom.data(element, "group");
                        if (id) {
                            type = "group";
                        } else {
                            id = dom.data(element, "experience");
                        }

                        if (id) {
                            channel = {id: channelId, element: element, origin: "dom"};
                            channel[type] = {id: id};
                            channels.push(channel);
                        }
                    }
                }

                // Will not populate channels, merely add the channels...
                n = scope._addChannels(channels); // already tagged with "dom" origin

                scope.info("Found ", n, " channel(s) in the dom", scope);

                // We want to ensure the channels tagged with a "dom" origin comes before channels embedded/loaded
                // via the config, to best reflect the state of the url in question at the time of caching...
                var sort = function (a, b) {
                    if (a.origin === "dom") {
                        return b.origin === "dom" ? 0 : -1;
                    }
                    return b.origin === "dom" ? 1 : 0;
                };

                // Respect snippet mode, i.e. this could be part of a preload run...
                var preload = scope.mode.is("preload");
                if (preload) {
                    scope.mode.mode = "auto";

                    // Do a manual sort then to "_populateChannels" will use it when actually called manually...
                    if (n) {
                        scope.sort(sort);
                    }
                } else {
                    scope._populateChannels(n ? sort : null);
                }
            }});
        };

        /**
         * Loads all channels for this snippet, if not already. <p>
         *
         * Once the channel data is loaded, channels <b>not</b> matching the current url will be filtered out. If
         * in "auto" mode, the channels will then be tried populated based on the current dom; if "manual" mode,
         * the experiences will not be tried injected until <tt>_populateChannels</tt> is explicitly called.
         */
        this.loadChannels = function () {
            // If we have channels for sure, we have already exported our jsonp callbacks and loaded the data, so no
            // more to do then, or the app (snippet) is being restarted. If we have channels here, the mode will always
            // be "auto"...
            if (scope._channels) {
                scope._channelsReady();
                return;
            }

            // Dequeue all channels queues before this snippet was ready. This will not call "channelsReady" (yet), as
            // we might want to load the config first...
            scope._dequeueChannels();

            // Try and preload the viewer html/js/lib for *this* snippet, but *only* if so configured and where
            // applicable...
            Viewer.preload(scope);

            // Use the embedded configuration for the channel config id used by *this* snippet, if any...
            var channelConfig = scope._embedded();
            if (channelConfig) {
                scope.info("Using embedded configuration", scope);
                // Channels from config ready, so add and process them...
                scope._channelsReady(channelConfig.channels, "embed");
                return;
            }

            // Data is not loaded yet, but may be loaded synchronously by the client if they have inserted a script
            // tag to explicitly load the data after the channel tag (old legacy way, though still supported). We
            // thus set up the jsonp callback(s) and issue an asynchronous request for the data, and use which ever
            // callback completes first: the one possibly inserted by the client in the dom or our asynchronous
            // request below; the last incoming response is just ignored, with no errors so we should be fine...
            global.win["__zmagsChannels_" + scope.configId] = function (channelConfig) {
                if (scope._channelsLoaded) {
                    // Ensure we can handle legacy channel format as well as actual configs pushed...
                    scope._channelsLoaded(scope._config(channelConfig).channels);
                    // As stated, only needed once per snippet...
                    scope._channelsLoaded = null;
                }
            };

            // We cannot log a message saying we are about to preload if the client has loaded the data, since
            // the loading will already be done then at this point...
            var msg = "oading channel data";
            if (scope.mode.is("preload")) {
                msg = "Prel" + msg;
            } else {
                msg = "L" + msg;
            }
            scope.info(msg, scope);

            timerLogger.timer(TimerLogger.CHANNEL_CONFIG_TIMER);

            provider.script({
                viewer: "/assets/channelConfigs/" + scope.configId + ".json",
                log: scope,
                errback: function () {
                    scope.error("Cannot load channel config - exiting!");
                }
            });
        };

        /**
         * Returns the number of channels current associated with this snippet.
         *
         * @return {Number} The number of channels; never falsey, never negative.
         */
        this.size = function () {
            return scope._channels && scope._channels.length;
        };

        /**
         * Returns the channel associated with this snippet that matches the entire specified query, otherwise null. <p>
         *
         * An attribute can be specified in <tt>query</tt> as either:
         * <pre>
         *  1. A function, e.g. {id: function (channel, name) { .. return truthy to accept .. },
         *  2. A regex, e.g. {name: /goo|boo/i},
         *  3. Something else matched using == (not ===), e..g. {name: "foo"}, {_cacheable: 42}, {inPlace: false}, etc.
         * </pre>
         *
         * @param {String|*} [query] The query as a plain js object, where each key defines a channel property and its
         *                           value the value to match, e.g. {id: "some-channel-id"}. All properties must match
         *                           for a channel to be returned. If a value is a function, it will be called like
         *                           <tt>fn(channel, name)</tt> and a truthy return value means match for the named
         *                           property. If a value is a regular expression, the value of the named property will
         *                           be tested  against it and must match to be included. If something else, e.g.
         *                           a number, string, or boolean, the value of the named property will be tested
         *                           using double equals (==). If <tt>query</tt> is a string, this is treated as
         *                           <tt>{id: query}</tt>.
         *
         * @return {Channel} The matching channel, if any; can be null.
         * @see    #each
         */
        this.channel = function (query) {
            var channel,
                name,
                value,
                ok,
                i;

            if (query && scope._channels) {
                if (typeof query === "string") {
                    query = {id: query};
                }
                loop:
                for (i = 0; i < scope._channels.length; i++) {
                    channel = scope._channels[i];
                    for (name in query) {
                        value = query[name];
                        if (typeof value === "function") { // function
                            ok = value(channel, name);
                        } else if (value instanceof RegExp) { // regexp
                            ok = value.test(channel[name]); // handles undefined -> false
                        } else {
                            /*jshint -W116*/
                            ok = (channel[name] == value); // something else like a string, number, or boolean
                            /*jshint +W116*/
                        }
                        if (!ok) {
                            continue loop; // no match for this channel
                        }
                    }
                    // Entire query matched...
                    return channel;
                }
            }
            return null;
        };

        /**
         * Applies the specified callback to each channel currently associated with this channel, regardless
         * if processed or not. If <tt>callback</tt> returns a defined value, the iteration will stop and
         * the value returned by this function.
         *
         * @param  {Function} callback A callback to be called like <tt>callback(channel:Channel, i:Number)</tt> for
         *                             each channel associated with this snippet; if falsey, this is a no-op. Will
         *                             be invoked with <tt>ctx</tt> as scope if supplied, otherwise as is.
         * @param  {Object}   [ctx]    The context scope for <tt>callback</tt>, if any; can be falsey, in which
         *                             case <tt>callback</tt> is called as-is.
         *
         * @return {*} The return value from the first time <tt>callback</tt> returns a defined value; can be null.
         *
         * @see   #channel
         */
        this.each = function (callback, ctx) {
            var result, i;
            if (callback && scope._channels) {
                for (i = 0; i < scope._channels.length; i++) {
                    result = callback.call(ctx || callback, scope._channels[i], i);
                    if (typeof result !== "undefined") {
                        return result;
                    }
                }
            }
            return null;
        };

        /**
         * Manually sets channels to be maintained by this snippet, filtering out those not matching the current url or
         * invalid at the time of add. <p>
         *
         * Any existing channels will be removed, and each populated removed channel will have its original content
         * restored. <p>
         *
         * If more than one channel is successfully set for this snippet, the channels will immediately be tried
         * populated unless in "admin" mode; that is, <tt>channelsReady</tt> will be called.
         *
         * @param {*|[]}    channels  The channel json to set/create Channels for; can be falsey. Note: if an array, the
         *                            entries successfully used to create a channel with are removed! If falsey or empty,
         *                            has the effect of removing all channels from this snippet.
         * @param {Boolean} [dequeue] Include 'in-place' channels currently in the queue, if any, false
         *                            otherwise (default).
         *
         * @return {Number} The number of channels actually set for this snippet, including dequeue count. The entries
         *                  used to create channels for this snippet will be removed, both from <tt>channels</tt> and
         *                  the queue, including those that was used to create invalid channels, e.g. missing a selector
         *                  but associated with this snippet.
         */
        this.setChannels = function (channels, dequeue) {
            scope.restoreChannels();
            scope._channels = null;
            var count = 0;
            // Dequeue? If so, will not call "channelsReady". We will keep the queue in admin mode as a reload
            // of the channels should include them again...
            if (dequeue) {
                count = scope._dequeueChannels(scope.admin());
            }
            if (channels) {
                count += scope.addChannels(channels);
            }
            return count;
        };

        /**
         * Manually adds channels to be maintained by this snippet, filtering out those not matching the current url or
         * invalid at the time of add. <p>
         *
         * If more than one channel is successfully added to this snippet, the channels will immediately be tried
         * populated unless in "admin" mode; that is, <tt>channelsReady</tt> will be called.
         *
         * @param {*|[]} channels The channel json to add/create Channels for; can be falsey. Note: if an array, the
         *                        entries successfully used to create a channel with are removed!
         * @return {Number} The number of channels actually added to this snippet. The entries used to create channels
         *                  for this snippet will be removed, including those that was used to create invalid
         *                  channels, e.g. missing a selector but associated with this snippet.
         */
        this.addChannels = function (channels) {
            var count = scope._addChannels(channels, "manual");
            // In admin mode, we want to control explicitly when we populate the channels to make sure we for example
            // can handle showing experiences for a given schedule date...
            if (count && !scope.admin()) {
                scope._channelsReady();
            }
            return count;
        };

        /**
         * @private
         * Manually adds channels to be maintained by this snippet, filtering out those not matching the current url or
         * invalid at the time of add *unless* in admin mode. <p>
         *
         * This will <b>not</b> try and populate any (existing or) new channels.
         *
         * @param {*|[]}     channels     The channel json to add/create Channels for; can be falsey. Note: if an array,
         *                                the entries successfully used to create a channel with are removed unless
         *                                <tt>keepMatched</tt> are true!
         * @param {String}  [origin]      The origin of the channels to add, e.g. "loaded" or "manual", if any.
         * @param {Boolean} [keepMatched] True to keep matched entries from <tt>channels</tt>, false to remove them
         *                                (default).
         * @return {Number} The number of channels actually added to this snippet. The entries used to create channels
         *                  for this snippet will be removed, including those that was used to create invalid
         *                  channels, e.g. missing a selector but associated with this snippet.
         *
         * @see #addChannels
         */
        this._addChannels = function (channels, origin, keepMatched) {
            if (!channels) {
                return 0;
            }

            // Handle Experience id(s), or non array channels...
            var type = typeof channels;
            if (type === "string" || type === "object" && typeof channels.length !== "number") {
                channels = [channels];
            }

            // Filter channels based on url and non-empty selectors only: even for single page apps, we can filter
            // against the current url (global.href) once and for all as only the hash can change, which is not supported
            // by the channel urls. The non-empty css selectors are not tested until "populateChannel(s)" is called,
            // automatically or manually...
            if (!scope._channels) {
                scope._channels = [];
                scope._processed = 0;
            }

            var channel,
                count = 0,
                i = channels.length;

            while (i--) {
                // Will also ignore any channel defining a specific config id that does not match this snippet and
                // that are invalid, taking snippet admin mode into account...
                channel = Channel.of(scope, channels[i], scope._process);
                if (channel) {
                    // Tag with specified origin if no explicit origin has been specified...
                    if (!channel.origin) {
                        channel.origin = origin;
                    }
                    scope._channels.push(channel);
                    scope.info("Channel matching url", channel);
                    count++;

                    // Remove used channel from incoming array (e.g. dequeue since used)...
                    if (!keepMatched) {
                        channels.splice(i, 1);
                    }
                }
            }

            return count;
        };

        /**
         * Sorts all unprocessed channels for this snippet according to the supplied sort function.
         *
         * @param  {Function} sort The function to sort unprocessed channels by; can be falsey (no sort).
         * @return {Number} The index of the first sorted channel; -1 means no channels sorted.
         */
        this.sort = function (sort) {
            var i = -1;
            if (sort && scope._channels) {
                // Remove all channels already processed...
                i = scope._processed;
                var n = scope._channels.length,
                    processed = i ? scope._channels.splice(0, i) : null;

                // Sort unprocessed...
                scope._channels.sort(sort);

                scope.info("Sorted unprocessed channels: [" + i + "," + n + "]");
                scope.verbose("Sorted channel order", scope._channels);

                // Remember to add processed again so "scope._processed" is still correct...
                if (processed) {
                    scope._channels = processed.concat(scope._channels);
                }
            }
            return i;
        };

        // Loads our custom Sizzle implementation for certain legacy browsers where a usable jQuery is not
        // available, e.g. IE9...
        this._loadSizzle = function () { // TODO: no need to fetch this if experience is embedded, since fallback is part of that
            // Invariant: since native query selector is not available, we know the sandbox is global, which is
            // required by our custom Sizzle...
            var preload = scope.mode.is("preload");

            // Sizzle already loaded/available?
            if (global.sandbox().sizzle && !preload) {
                if (global.loc.type === "webcache") {
                    scope._parseChannels();
                } else {
                    scope._populateChannels();
                }
                return;
            }
            scope.verbose("Native css selector not available,", preload ? " pre" : " ", "loading Sizzle", global.capabilities);

            // Custom shim that exposes Sizzle under "sandbox.sizzle" *only*, as we know we only use it for the
            // channel snippet logic when needed, i.e. no amd support or whatever, since never needed...
            provider.script({url: scope.url("js/vendor/sizzle.custom.js"), log: scope, callback: function () {
                // We have to fetch/evaluate the sandbox here again, or IE8 seems to use an old copy of the data
                // available before the "script" call... :/
                if (!global.sandbox().sizzle) { // must be ready now, or we are in trouble...
                    var msg = "Cannot find Sizzle";
                    scope.error(msg, scope);
                    throw new Error(msg);
                } else if (global.loc.type === "webcache") {
                    if (preload) {
                        scope.verbose("Sizzle preloaded", format.snippet(scope));
                    }
                    scope._parseChannels();

                // Ensure in "auto" mode, since we could have been in "preload", but retain viewer mode part...
                } else if (preload) {
                    scope.mode.mode = "auto";
                    scope.verbose("Sizzle preloaded", format.snippet(scope));

                // Populate channels!
                } else {
                    scope._populateChannels();
                }
            }});
        };

        /**
         * @private
         * Invoked when the channel data is loaded as a jsonp callback, wrapped in a one-time callback. Do not
         * invoke directly, automatically called (once)! <p>
         *
         * Note that this can also be called in a synchronous fashion in the client includes both the snippet
         * code *and* a script to fetch the channel data as jsonp. The channels used are which ever request for
         * data completes first, the latter is then silently ignored.
         *
         * @param {[]} channels The channel data as json; will default to an empty array on falsey.
         */
        this._channelsLoaded = function (channels) {
            timerLogger.timer(TimerLogger.NETWORK_MEASUREMENT); // TODO: what about preload of experiences/viewer?

            if (!channels) {
                channels = [];
            }

            var i = channels.length;
            scope.info(i, " channel(s)", scope.mode.is("preload") ? " pre" : " ", "loaded: ", format.val(global.loc), scope).verbose("Channel data", channels);

            // Add all relevant channels, then process them...
            scope._channelsReady(channels, "loaded");
        };

        /**
         * @private
         * Callback when channels have been loaded and filtered based on url, including if this snippet is restarted
         * to avoid reloading channel data.
         *
         * @param {[]}     [channels] The channel data as json to add before processing; can be falsey, in which case
         *                            the existing channels are used.
         * @param {String} [origin]   The origin of <tt>channels</tt>, e.g. "embed" or "loaded".
         */
        this._channelsReady = function (channels, origin) {
            if (channels) {
                scope._addChannels(channels, origin);
            }

            // If in preload mode, we will change the mode to "auto" henceforth...
            var preload = scope.mode.is("preload"),
                length = scope._channels.length;

            // Bail out now if there are no channels to process for the current url at all...
            if (length) {
                // Any channels matching url left to process?
                if (scope._processed < length) {
                    // If the browser's back-forward cache is implemented poorly, experiences will be unresponsive when pages
                    // are served from the cache on load. This doesn't affect many browsers, so for simplicity, we just reload
                    // the page when we detect a cache hit. We can only do this on auto run, i.e. not for single page web apps,
                    // since we could break all sorts of logic...
                    if (!(global.capabilities.isBackForwardCacheSupported || preload)) {
                        global.win.onpageshow = function (event) { // TODO: where can I test this? Which browser is this, IE9/8?
                            if (event.persisted) {
                                scope.info("Reloading page due to bad cache hit: ", format.val(global.userAgent), global.capabilities);
                                global.win.location.reload(); // since global.loc is our type to support alternative locations
                            }
                        };
                    }

                    // Native css support?
                    if (global.capabilities.isQuerySelectorSupported) {
                        // For now, we only parse the doc on alternate cache urls as it will slow down the processing.
                        // This will eventually populate the channels, respecting the snippet mode...
                        if (global.loc.type === "webcache") {
                            scope._parseChannels();

                        // Set to "auto" henceforth, but keep viewer mode part...
                        } else if (preload) {
                            scope.mode.mode = "auto";

                        // Populate when the dom is ready...
                        } else {
                            scope._populateChannels();
                        }

                    // We have to use Sizzle to find elements by CSS selector on browsers that do not support
                    // document.querySelectorAll. We do not load the script until now, since old legacy browsers tend to load
                    // scripts synchronously or halt will loading, and no need to bother the user with that until we know we
                    // have to use Sizzle. However, we might be able to piggyback on a global jQuery.find (= Sizzle)
                    // implementation already loaded, so lets try that one...
                    } else {
                        var $ = global.$();
                        if ($ && $.find) { // find = Sizzle!
                            scope.info("Native css selector not available, but found usable jQuery Sizzle: ", $);
                            global.sandbox().sizzle = $.find;
                            // Fall-through!
                        }
                        scope._loadSizzle(); // will only load once, including for preload... // TODO: only for async, or will halt?!?!?
                    }
                }
            } else {
                scope.info("No channels matching url: ", format.val(global.loc));

                // When on alternative cache url, we might still have channels in the dom that must be parsed. This
                // will eventually populate the channels parsed from the dom, if any, respecting the snippet mode...
                if (global.loc.type === "webcache") {
                    scope._parseChannels();
                }
            }
        };

        /**
         * Populates all unprocessed channels for this snippet with their associated (fallback) experience if and
         * only if the dom selector for each channel matches at least one dom element (after the dom is ready). <p>
         *
         * If a channel is already populated, this method does nothing for it, regardless if the dom selector no
         * longer match.
         *
         * @param  {Function} [sort] Optional sort function to sort unprocessed channels before populating them;
         *                           can be falsey. Will default to the sort function specified for this
         *                           snippet, if any.
         *
         * @see    #sort
         * @see    #restoreChannels
         */
        this.populateChannels = function (sort) {
            return this._populateChannels(sort);
        };

        /**
         * @private
         * Populates all loaded unprocessed channels for this snippet with their associated (fallback) experience if
         * and only if the dom selector for each channel matches at least one dom element (after the dom is ready).
         * In-place channels are processed as well unless already processed. <p>
         *
         * If a channel is already populated, this method does nothing for it, regardless if the dom selector no
         * longer match.
         *
         * @param  {Function} [sort] Optional sort function to sort unprocessed channels before populating them;
         *                           can be falsey. Will default to the sort function specified for this
         *                           snippet, if any.
         *
         * @see    #sort
         * @see    #_populateChannel
         * @see    #restoreChannels
         */
        this._populateChannels = function (sort) {
            dom.docReady({name: "_populateChannels", scope: scope, args: sort, callback: function (sort) {
                // In manual or admin mode, we might not even have any channels yet...
                if (scope._channels) {
                    var total = 0,
                        count = 0,
                        i;

                    for (i = 0; i < scope._processed; i++) {
                        if (scope._channels[i].ready()) {
                            total++;
                        }
                    }
                    // Do an optional sort of the channels before processed. Any channel already processed is
                    // ignored by this...
                    scope.sort(sort || scope._sort);

                    for (; scope._processed < scope._channels.length; scope._processed++) {
                        if (scope._populateChannel(scope._channels[scope._processed], total)) {
                            count++;
                            total++;
                        }
                    }
                    scope.info("Populated channels: ", count + "/" + total, scope);
                }
            }});
        };

        /**
         * Populates the supplied channel with its associated (fallback) experience if and only if the dom selector
         * for this channel matches at least one dom element. <p>
         *
         * If already populated, this method does nothing, regardless if the dom selector no longer match.
         *
         * @param  {Channel} channel   The channel to populate; never null.
         * @param  {Number}  populated Number of channels already populated for this snippet. This is used to
         *                             generate unique viewer log prefixes (using this number as a postfix for each
         *                             logger name) if more than one viewer is loaded via channels only.
         * @return {Boolean} True if the channel was populated (now or sometime in the future), false otherwise.
         * @see    #_populateChannels
         * @see    #_restoreChannel
         */
        this._populateChannel = function (channel, populated) {
            // Find matching element(s) based on the selector for the channel. The returned match has the
            // the <tt>Channel.Match</tt>, i.e. {element:HTMLElement, elements:HTMLElements[]}, where "element" is the
            // element to have the experience injected into, and "elements" are additional matching elements we must
            // handle the visibility off...
            var newState = channel.insert(populated);

            // Track page view event(s) for fallback images only as the viewer will track normal page views. This will
            // never happen when in admin mode, since DnD is only supported in non-legacy browsers...
            if (newState === "fallback") {
                util.defer(function () {
                    GoogleAnalytics.trackPageView();
                    PiwikAnalytics.trackPageView();
                });
            }

            return channel.ready();
        };

        /**
         * Restores all populated channels for this snippet with their original content, optionally removing all
         * channels associated with this snippet.
         *
         * @param  {Boolean} [clear] True to clear all channels are they have been restored, false otherwise (default).
         * @return {Number} The number of restored channels.
         *
         * @see    #_restoreChannel
         * @see    #_populateChannels
         */
        this.restoreChannels = function (clear) { // public!
            var i, n, count = 0;
            if (scope._channels) { // may not even have been initialised yet
                n = scope._channels.length;
                for (i = 0; i < n; i++) {
                    if (scope._restoreChannel(scope._channels[i])) {
                        count++;
                    }
                }
                this._processed = 0;
                scope.info("Restored channels: ", count, scope);

                if (clear) {
                    scope._channels = null;
                    scope.info("Cleared channels: ", n, scope);
                }
            }
            return count;
        };

        /**
         * Restores the supplied channel with its original content. <p>
         *
         * If already restored, i.e. not currently populated, this method does nothing.
         *
         * @param  {Channel} channel The channel to restore; never null.
         * @return {Boolean} True if the channel was restored, false otherwise (i.e. was not needed).
         * @see    #restoreChannels
         * @see    #_populateChannel
         */
        this._restoreChannel = function (channel) {
            if (channel.state !== "idle") {
                channel.removeContent();
                return true;
            }
            return false;
        };

        /**
         * Returns the (proxy) object exposed to the world for this snippet.
         *
         * @return {*} The (proxy) object exposed to the world for this snippet; never null.
         */
        this.proxy = function () {
            if (!scope._proxy) {
                scope._proxy = {
                    id:    scope.configId, // can initially be a wild-card!
                    add:   scope.addChannels,
                    start: scope.loadChannels,
                    stop:  scope.restoreChannels,
                    options: function (options) {
                        if (global.session.enabled()) { // same flag as global.cookie!
                            global.session.put({key: scope.configId, value: options, json: true});
                            scope.info(options ? "Setting" : "Clearing", " snippet config for use on session reload", options || {}, scope);
                        }
                    },
                    log: function () {
                        var type = typeof arguments[0],
                            value = arguments[0];

                        if (type === "number") {
                            scope._setupLog(scope.logger, value);
                        } else {
                            return scope.logger.queue(type === "undefined" || value); // true (default) = raw entries
                        }
                    },
                    toString:  function () { return format.snippet(scope); }
                };
            }
            return scope._proxy;
        };

        /**
         * Returns the string representation of this snippet.
         *
         * @return {String} The string representation; never null.
         */
        this.toString = function () {
            return format.snippet(scope);
        };
    }

    /**
     * Creates a new snippet based on the supplied <script> tag, a string (config id), or a plain on JS object like
     * <tt>{configId:String, ..}</tt>.
     *
     * @param {HTMLElement|Snippet|String|*} o The <script> tag, config id, or plain on JS snippet; cannot be null.
     *
     * @return {Snippet} A new corresponding snippet; will be falsey if <tt>snippet</tt> is not usable, i.e. missing
     *                   relevant "data-" attributes like config id if a <script> tag, or missing corresponding
     *                   properties in a plain old JS object.
     */
    Snippet.of = function (o) {
        var snippet,
            options;

        // At least a config id is required...
        if (!o) {
            return null;

        // Already a snippet, so we assume already configured based on session values for now...
        } else if (o instanceof Snippet) {
            return o;

        // Create a snippet based on "o"...
        } else {
            // Keeping the implicit string conversion because there are edge case differences when
            // using the explicit String() instead. See
            // http://www.2ality.com/2012/03/converting-to-string.html
            // jscs:disable disallowImplicitTypeConversion
            // Script tag?
            if (/script/i.test("" + o.tagName)) {
                options = {
                    configId:         dom.data(o, "channel"),
                    url:              o.src,
                    mode:             dom.data(o, "mode"),
                    restoreType:      dom.data(o, "restore-type"),
                    preview:          dom.data(o, "preview"),
                    loadingIndicator: dom.data(o, "loading-indicator"),
                    timeout:          dom.data(o, "timeout"),
                    cacheable:        dom.data(o, "cacheable"),
                    script:           o // only used when upgraded from wildcard!
                };
            // jscs:enable disallowImplicitTypeConversion

            // Config id as a string...
            } else if (typeof o === "string") {
                options = {configId: o};

            // Plain old JS object or (or so we assume)...
            } else {
                options = o;
            }

            // Invariant: we must have a config id here, perhaps a wildcard, but all others may default!

            // The config id, however, may be a wild-card, i.e. "*". which means no config id present yet,
            // but expected will be defined as soon as the first the "in-place" channel is added. This is
            // handy for partner suites like Demandware where the same channel snippet is included for
            // many different config ids in test/dev environments, and the individual assets (Experiences)
            // added will then define the (same!) snippet id...
            if (options.configId) {
                snippet = new Snippet(options);
            }
        }

        // Use session configured values, if any, to allow us with easy debugging. So, if we for
        // example override the mode in session storage, we can use said mode on reload, to
        // test the changed behaviour. We do it have we have a snippet instance. This also works
        // for wild card snippets as long as we respect "manual" channel mode for wild card snippets.
        // It is ok to read from session storage even if the snippet has been configured not to as
        // we never write anything to storage here...
        if (snippet) {
            options = {key: snippet.configId, json: true};
            options = global.session.get(options) || global.cookie.get(options); // cookie: to aid test suites that only support cookies!
            if (options) {
                if (options.mode) {
                    var mode = new Mode(options.mode);
                    // Already "manual" for channel mode, so we only need to update the viewer mode...
                    if (snippet.wildcard()) {
                        snippet.mode.viewer = mode.viewer;
                    } else {
                        snippet.mode = mode;
                    }
                }
                snippet.restoreType      = options.restoreType      || snippet.restoreType;
                snippet.loadingIndicator = options.loadingIndicator || snippet.loadingIndicator;
                snippet._preview         = options.preview          || snippet._preview;
                snippet._level           = options.log              || snippet._level;
                snippet.timeout          = util.time(options.timeout,   snippet.timeout);   // to respect 0!
                snippet.cacheable        = util.time(options.cacheable, snippet.cacheable); // ditto

                // Snippet log may not be available yet...
                state.log.info("Updated snippet based on session configuration", options, snippet);
            }
        }

        return snippet; // can be null
    };

    Snippet.Mode = Mode;

    return Snippet;
});

