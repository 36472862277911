define('channels/ChannelUrlFilter',['require','channels/Util'],function (require) {
    "use strict";

    var util = require("channels/Util");

    /**
     * Filters the given URL using the pattern for the given channel, returning true if the URL matches the pattern.
     *
     * @param {Object} channel The channel.
     * @param {String} url The url.
     * @returns {boolean} Whether the URL matches the channel.
     */
     return function (channel, url) {
        var tokens = channel.urlPattern;

        // NOTE: when changing the logic here, be sure to update isHit(Channel) method in ChannelConfig.java accordingly!

        // If no pattern is defined, the URL matches by default
        if (!tokens) {
            // Fallback for old channel data that wasn't pre-parsed on the backend
            if (!channel.url) {
                return true;
            }
            channel.url = util.trim(channel.url);
            if (!channel.url) {
                return true;
            }
            tokens = channel.url.split("*");
        }

        // Since the only special thing we support is wildcards, do the string comparisons directly. This is about 10x
        // faster than the old regex version.

        // URL must start with the first token
        if (util.idx(url, tokens[0]) !== 0) {
            return false;
        }
        var startPos = tokens[0].length;

        // URL must end with the last token
        var lastToken = tokens[tokens.length - 1],
            endPos = url.length - lastToken.length;

        if (endPos < 0 || url.lastIndexOf(lastToken) !== endPos) {
            return false;
        }

        // Middle tokens must come in the correct order and be between the start and end tokens
        for (var i = 1; i < tokens.length - 1; i++) {
            startPos = util.idx(url, tokens[i], startPos);
            if (startPos === -1 || startPos > endPos) {
                return false;
            }
            startPos += tokens[i].length;
        }
        return true;
    };

});

