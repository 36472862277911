define('app/ChannelsApplication',['require','channels/Global','channels/Provider','channels/Dom','channels/Util','channels/Log','channels/Format','channels/State','channels/Cache','channels/Snippet','channels/ViewerBridge'],function (require) {
    "use strict";

    var global   = require("channels/Global"),
        provider = require("channels/Provider"),
        dom      = require("channels/Dom"),
        util     = require("channels/Util"),
        Log      = require("channels/Log"),
        format   = require("channels/Format"),
        state    = require("channels/State"), // global app state!
        Cache    = require("channels/Cache"),
        Snippet  = require("channels/Snippet"),
        Viewer   = require("channels/ViewerBridge");

    /**
     * The channels app, also stored under <tt>__zmags.channels.app</tt> until destroyed. <p>
     *
     * Call <tt>start()</tt> to start it, and <tt>stop()</tt> to stop it again (can be restarted). <p>
     *
     * On start, handles for relevant snippets will be returned that can be started (if manual/preload mode),
     * stopped, and have their log mode changed. A snippet can be stopped/restarted again and again if so
     * desired.
     *
     * @class ChannelsApplication
     *
     * @author Gunni Rode / <a href="http://www.zmags.com">Zmags</a>
     */
    function App() {

        /**
         * @private @type {App}
         * The current (re-used) app. May be updated on <tt>init</tt> to the app already loaded/configured
         * in the sandbox when multiple snippets are included on the same page.
         */
        var scope = this;

        // Applies the supplied function to each Snippet, and returns an array of the snippet proxies...
        var call = function (fn) {
            if (!global) { return null; }

            // Call!
            var i, snippet, result = [];
            for (i = 0; i < state.snippets.length; i++) {
                snippet = state.snippets[i];
                fn.call(snippet, snippet);
                result.push(snippet.proxy());
            }
            return result;
        };

        /**
         * @private
         * Returns a reference to the global app state, to be shared when multiple instances are in play.
         *
         * @return {State} A reference to the state of this app; never null.
         * @see State#copy
         */
        this._state = function () {
            return state;
        };

        /**
         * Returns the global log (<tt>Log.global()</tt>), for easy external access like in "content.js" used to create
         * 'in-place channels' on the fly.
         *
         * @return {Log} A reference to the global log; never null after init.
         */
        this.log = function () {
            return state.log;
        };

        /**
         * Initialises this channels application before use, i.e. before <tt>start</tt> has been called, or the
         * first time <tt>start</tt> is called, returning the app instance to use! <p>
         *
         * Once called, this method will be deleted from this app. <p>
         *
         * Options:
         * <pre>
         * {
         *     storage:    Boolean.        // True if session storage, local storage, and cookies are enabled,
         *                                 // false otherwise. Default true. This can be used for "opt out", e.g.
         *                                 // a user not accepting cookies. If disabled, the raw json cache used
         *                                 // will just be an in-mem cache not writing to local storage.
         *     admin:      String|Boolean, // True or "true" for admin mode (default not admin mode). Can be changed
         *                                 // globally henceforth, i.e. will affect any snippet.
         *     log:        String|Number,  // The initial log level to use (defaults to query parameter, then ERR). Can
         *                                 // be changed per snippet henceforth.
         * }
         * </pre>
         *
         * If this channels application has been destroyed, this method does nothing.
         *
         * @param {{log:Number|String, admin:Boolean}} [options] The channels application options; can be falsey,
         *                                                       will default.
         * @return {Boolean} True if this app is initialised now, false otherwise (on re-use, e.g. multiple snippets,
         *                   or if destroyed).
         *
         * @see    App#start
         */
        this.init = function (options) {
            // If already destroyed...
            if (!global) {
                return false;
            }

            // Re-use sandboxed app if already defined. App is defined in this scope, so it will not be available
            // for another instanceof this script, i.e. different type...
            var sandbox = global.sandbox();

            // Re-use, already initialised...
            if (sandbox.app && sandbox.app.constructor.toString() === App.toString()) {
                // Must update scope reference, since we changed it; will also reuse state!
                scope = sandbox.app;

                // Make sure the already handed out global state to other modules uses the correct shared
                // state, not the original (empty) state already given to them. That is, they will use a
                // different state instance, but using the same references to the actual shared state...
                state = state.copy(sandbox.app._state());

                state.log.error("Channel script included multiple times");
                return false;
            }

            // Tag with start time of this app!...
            state.start = util.now();

            // New, i.e. this one "just created"...
            options = options || {};

            // Set up initial configuration values based on query parameters as those will never change during the lifetime
            // of this script...
            var admin = options.admin;
            if (typeof admin === "undefined") {
                admin = global.config(global.QUERY.adminModeIdentifier);
            }
            admin = util.boolean(admin);
            if (admin && !global.capabilities.isSupported) {
                state.log.error("Admin tried enabled in legacy browser");
                admin = false;
            }
            global.config(global.QUERY.adminModeIdentifier, admin || null); // true for admin, null to remove it

            // Configure logging...
            var log = options.log;
            if (typeof log === "undefined") {
                log = global.config(global.QUERY.logModeIdentifier);
            }
            log = util.int(log);
            global.config(global.QUERY.logModeIdentifier, log || Log.Levels.ERROR);

            // Setup global and timer log, and keep reference on "app" for it for easy external reference...
            state.log = Log.setup(Log.global(), global.defaultLogLevel());
            Log.setup(Log.timer(), global.defaultLogLevel());

            // Configure whether or not storage is available (default available)...
            if (typeof options.storage !== "undefined") {
                global.session.enabled(options.storage);
                global.local.enabled(options.storage);
                global.cookie.enabled(options.storage);
            }

            sandbox.app = scope;

            // Setup content json cache here, since no app to reuse. Individual snippets/channels can configure
            // the desired cache times for local storage, including if no local storage caching should be used at all.
            // We always create the cache, even with no persistent options; in that case, it will just be an in-mem
            // cache. If local storage is not enabled at all, e.g. disabled via "options.storage", the cache will
            // respect this...
            state.cache = new Cache();

            state.log.verbose("Started at ", state.start, " with ", util.deferMultiplier, " x timeout");

            // No more init for this app!
            delete scope.init;

            return true;
        };

        /**
         * Starts the specified or any snippet not already started in the current dom. <p>
         *
         * Each found snippet, if any, will be stored under <tt>__zmags.channels.snippets</tt> and returned,
         * and have the following format:
         * <pre>
         * {
         *     id:       String,   // the snippet (configuration) id
         *     start:    Function, // start the snippet, i.e. insert experiences into each matching channel
         *     stop:     Function, // stop the snippet, i.e. restore each channel
         *     log:      Function  // optionally set the log level for this snippet (0 = OFF, 1 = ERR, 2 = LOG, 3 = VER),
         *                         // always returning all logged statements (though perhaps not printed):
         * }
         * </pre>
         *
         * On start, each channel with a matching experience will inject itself into the current dom. Each
         * such matching channel is exposed under <tt>__zmags.channels.active</tt> as:
         * <pre>
         * {
         *     name:       String,      // the channel name
         *     id:         String,      // the id of the actually shown experience
         *     element:    HTMLElement, // the matching dom element
         *     snippet:    *,           // the snippet owning the channel as described above
         *     viewport:   Function,    // returns the viewport of the experience (element)
         *     experience: Function     // the experience associated with the channel, may be preloaded json
         *     fallback:   Function,    // the fallback data loaded for the experience in legacy browsers, if any
         *     stop:       Function     // stop the channel, i.e. restores the original content
         * }
         * </pre>
         *
         * @param {...*} [varArgs] <script> elements, specific config ids as strings, and/or a plain JS snippets like
         *                         <tt>{configId:String, url:String, mode:String, preview:Number, ..}</tt>, where
         *                         only <tt>configId</tt> is mandatory. If empty, all snippets backed by
         *                         a <script> element in the current dom will be deduced and started, if
         *                         not already.
         *
         * @return {*} An array of the started snippet(s); never null. Each returned snippet will have the
         *             format <tt>{configId:String, start:Function, stop:Function, log:Function}</tt>. When in
         *             admin mode, the array will only contain the snippet in admin mode (the first one found).
         *
         * @see    App#init
         * @see    App#stop
         * @see    App#snippet
         */
        /*jshint unused:false*/
        this.start = function (varArgs) {
            // If already destroyed...
            if (!global) {
                return [];
            }

            // Make sure the app is initialised before the first start, either explicitly or implicitly now since
            // we are about to start. This might actually update "scope" (this app) in case the snippet script
            // is included multiple times...
            if (scope.init) {
                scope.init(); // default args!
            }

            // Find current snippet(s), if any, taking existing into account. Normal case if just one, but more
            // can be present. If this script is included multiple page, e.g. via bookmarklet or because of faulty
            // setup, the same app is used and we thus reuse the snippet instances already initialised...
            state.snippets = scope.find(arguments);

            var snippets, snippet;

            // Must have a snippet, or we cannot continue now...
            if (state.snippets.length) {
                state.log.verbose("Channel snippet(s) for", global.loc.type !== "real" ? " *alternate* " : " ", "url: ", format.val(global.loc), " -> ", state.snippets.length, state.snippets);

                // Already in admin mode? That is, we might already have loaded the channel admin script if this
                // app is reused when the snippet is included multiple times...
                snippet = state.admin;
                if (snippet) {
                    snippet.info("Channel snippet admin mode already initialised", snippet);
                    // Reuse existing snippet (only one since admin mode)...
                    snippets = global.sandbox().snippets;

                // Do we need to go into admin mode now? If we, use the base url from the first (only) channel snippet...
                } else {
                    snippet = scope.admin();
                    if (snippet) {
                        // Always coerce snippet admin mode...
                        snippet.mode = new Snippet.Mode("admin");

                        // Tag this app as in admin mode so we do not load admin snippet multiple times...
                        state.admin = snippet;

                        // Used snippet log henceforth, since different snippets can in theory use different base urls...
                        snippet.info("Channel snippet admin mode detected", snippet);

                        // We have no frontend error logger here, but we log it, which will also report it via the error
                        // logger on error when the channels admin is potentially loaded. For now, we only patch here
                        // in channels if we are about to go to the admin - so admin can load at all - since all the
                        // problematic Demandware sites all worked like this (MOS-1840). We may want to revisit this
                        // later on, i.e. to patch in channels always when problems are detected...
                        var overridden = util.patchOverriddenNativeFunctions();
                        if (overridden.length) {
                            snippet.error("Native function(s) overridden", overridden);
                        }

                        // Hand over control to the channel admin snippet once loaded. The channel admin will use
                        // the snippet proxy already set in the sandbox to get the snippet in admin mode...
                        provider.script({url: snippet.url("js/channels-admin-main.js"), log: snippet});
                        snippets = [snippet.proxy()];

                    // Standard use-case: load channels and populate them on load unless mode is set to "manual" (from the
                    // <script> snippet...
                    } else {
                        snippets = call(function (snippet) { // TODO: test this for start/stop and multiple snippets!
                            // Share cache with snippet for easy access; at the same time, we test to see if this is the
                            // first time here for the snippet in question and if so, load channels/viewer respectively.
                            // If not first time, e.g. because of snippet included multiple times, we do not do anything...
                            if (snippet.mode.is("manual")) {
                                snippet.mode.mode = "auto"; // load next time around. supporting optional viewer modes
                                // Try preload viewer stuff now if so configured...
                                Viewer.preload(snippet);

                            } else {
                                snippet.loadChannels();
                            }

                            // Log when in wild-card mode...
                            if (snippet.wildcard()) {
                                state.log.info("Snippet currently in wild-card mode", snippet);
                            }
                        });
                    }
                }

            } else {
                snippets = [];
                state.log.error("No channel snippet(s) found, cannot continue");
            }

            // Expose *proxy* snippets in sandbox...
            global.sandbox().snippets = snippets;

            return snippets;
        };

        /**
         * Stops all started snippets. <p>
         *
         * To stop an individual snippet or channel instead, call <tt>snippet.stop()</tt> or <tt>channel.stop()
         * instead, respectively.
         *
         * @return {*} An array of the stopped snippet(s); never null. Each returned snippet will have the
         *             format <tt>{configId:String, start:Function, stop:Function, log:Function}</tt>.
         *
         * @see    App#start
         */
        this.stop = function () {
            return call(function (snippet) { snippet.restoreChannels(); });
        };

        /**
         * Returns the existing snippet based on the supplied snippet, either as a <script> element, a config id
         * (String), or as a plain old JS object for the snippet. <p>
         *
         * The returned snippet, if any, will have the following format:
         * <pre>
         * {
         *     id:       String,   // the snippet (configuration) id
         *     start:    Function, // start the snippet, i.e. insert experiences into each matching channel
         *     stop:     Function, // stop the snippet, i.e. restore each channel
         *     log:      Function  // get/set the log level for this snippet: 0 = OFF, 1 = ERROR, 2 = LOG, 3 = VERBOSE
         * }
         * </pre>
         *
         * @return {*}  The existing snippet for <tt>snippet</tt>; can be null. The returned snippet will have the
         *             format <tt>{configId:String, start:Function, stop:Function, log:Function}</tt>.
         */
        this.snippet = function (snippet) {
            var match = scope.match(state.snippets, snippet);
            return match.existing && match.existing.proxy();
        };

        /**
         * Matches the supplied snippet against an existing snippet in the specified array for this app.
         *
         * @param  {Snippet[]}                    snippets The array of snippets to search; can be falsey.
         * @param  {Snippet|String|HTMLElement|*} snippet  The snippet to find the existing one for; can be falsey.
         *
         * @return {{existing:Snippet, snippet:Snippet}} An object where the <tt>existing</tt> property will store the
         *         existing snippet, if any, and <tt>snippet</tt> the actual snippet created based on the supplied
         *         value, if possible. Both properties can thus be falsey.
         *
         * @see    Snippet#of
         */
        this.match = function (snippets, snippet) {
            var match = {existing: null, snippet: Snippet.of(snippet)};
            if (match.snippet && snippets) {
                var i = snippets.length, existing;
                while (i--) {
                    existing = snippets[i];
                    if (existing.configId === match.snippet.configId) {
                        match.existing = existing;
                        break;
                    }
                }
            }
            return match;
        };

        /**
         * Finds the (first) snippet from the current snippets for this channel app that must enable admin mode,
         * or falsey otherwise. <p>
         *
         * Admin mode can either be enabled globally via a query parameter, or individually per snippet. Individual
         * configured snippets always takes precedence, but if specified via a query parameter, the first snippet found
         * is used otherwise.
         *
         * @return {Snippet} The (first) snippet to go into admin mode; can be falsey, i.e. non admin mode
         *                   (or no snippets).
         */
        this.admin = function () {
            var i, snippet;
            for (i = 0; i < state.snippets.length; i++) {
                snippet = state.snippets[i];
                if (snippet.admin()) {
                    return snippet;
                }
            }
            return null;
        };

        /**
         * Finds all snippets backed by <script> elements for the current page/dom, re-using existing created
         * <tt>Snippet</tt> objects already available for this app.
         *
         * @param  {Arguments} snippets The snippets to find, if any; can be empty, in which case the dom is searched
         *                              for <script> elements backing snippets; never null.
         * @return {[]} An array of the found snippets, optionally backed by <script> elements; never null, but can
         *              be empty.
         *
         * @see    Snippet#of
         */
        this.find = function (snippets) {
            return scope._setup(snippets && snippets.length ? snippets : dom.doc.getElementsByTagName("script"));
        };

        /**
         * @private
         * Creates or re-uses <tt>Snippet</tt> objects for all valid snippets in <tt>all</tt> for this app.
         *
         * @param {*} all The snippets to setup, as <script> elements, config ids (Strings), or plain old JS objects
         *                describing a snippet; never falsey. For any such snippet, the existing version of it
         *                already created for this app will be used.
         *
         * @return {Snippet[]} The setup snippets; never null, but can be empty.
         */
        this._setup = function (all) {
            var snippets = [],
                i = all.length,
                snippet,
                match;

            while (i--) {
                snippet = all[i];
                // On App reuse (multiple snippets), the "state.snippets" array will contain the already known
                // snippets, perhaps initialised, perhaps not (depending on snippet mode)...
                match = scope.match(state.snippets, snippet);
                // Do we have a snippet at all, i.e. the supplied snippet may be invalid...
                if (match.snippet) {
                    // Make sure we do not have duplicates in the array we are about to build. Note that we have no
                    // direct coupling between script elements ("snippet") and (re-used) Snippet objects, the first
                    // one used (including its configuration) is always used, i.e. never re-parsed...
                    if (scope.match(snippets, match.snippet).existing) {
                        state.log.error("Ignoring duplicate snippet", match.snippet, snippet);

                    // Always reuse existing snippet, if available...
                    } else {
                        snippets.push(match.existing || match.snippet);
                    }
                }
            }

            // Setup named log(s) if more than one snippet, even if we just want a single one...
            i = snippets.length;
            if (i > 1) {
                while (i--) {
                    snippet = snippets[i];
                    snippet._setupLog(new Log("Channels:" + snippet.configId));
                }

            // Could go from i>1 -> 1 snippet on restart...
            } else if (i === 1) {
                snippets[0]._setupLog(Log.global());
            }

            return snippets;
        };

        /**
         * Registers the supplied channel(s) to the snippet with the specified config id. <p>
         *
         * If no config id is specified, i.e. only channels specified as the single argument, the single registered
         * snippet is used if not a wild-card snippet. If more than one snippet, false is simply returned. <p>
         *
         * If a config id is specified and only a single wild-card snippet is registered at the time this
         * function is called, said snippet will be upgraded to use the supplied config id. This is useful
         * for partner <b>test</b> sites like Demandware, where the same channel snippet can be used for
         * all our clients.
         *
         * @param {*|String|[]} channels   The channels to add, either as channel json, an experience id as a string,
         *                                 or an array of either. For any channel with no selector, the the channel
         *                                 will be registered as an "in-place channel" where the current executing
         *                                 script is adding it from (not possible after document has loaded).
         * @param {String}      [configId] The snippet (config) id to add the channel to, defaulting to single snippet
         *                                 currently available. If not supplied and the number of snippets is not
         *                                 1, false is simply returned and no channels are added.
         *
         * @return {Boolean} True if at least one channel was added, false otherwise.
         */
        this.add = function (channels, configId) {
            // Short cut only available when we have a single client snippet, otherwise the config id must
            // be specified...
            var snippet;
            if (state.snippets.length === 1) {
                snippet = state.snippets[0];

                // We only qualify a wild-card snippet id to an actual one on add!...
                if (snippet.wildcard()) {
                    // Can only qualify if we know have a snippet id...
                    if (configId) {
                        snippet.wildcard(configId);

                    } else {
                        state.log.error("Snippet id required, cannot add channel(s)");
                        return false;
                    }

                // Default to single defined snippet id if not supplied for ease of use...
                } else if (!configId) {
                    configId = snippet.configId;
                }
            }

            // Find the (proxy) snippet to use, if any...
            snippet = scope.snippet(configId);
            if (snippet) {
                return snippet.add(channels);
            }
            state.log.error("No such snippet, cannot add channel(s): '", configId, "'");
            return false;
        };

        /**
         * Destroys this app, including its state, making it useless henceforth. <p>
         *
         * (Most) content under <tt>__zmags.channels</tt> will be destroyed by this as well!
         */
        this.destroy = function () {
            // Nothing to destroy if never initialised...
            if (!scope.init) {
                scope.stop();
                var sandbox = global.sandbox();
                delete sandbox.app;
                delete sandbox.snippets;
            }
            global = null; // no restart
        };
    }

    return new App();

});

