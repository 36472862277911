/*global window*/
define('channels/Log',['require','channels/Format','channels/Util'],function (require) {
    "use strict";

    var format = require("channels/Format"),
        util   = require("channels/Util");

    /** Log levels, mimics verbose Logger levels. */
    var Levels = {
        OFF:     0,
        ERROR:   1,
        LOG:     2,
        VERBOSE: 3
    };

    /**
     * @constructor
     * Instantiable and configurable log, backed by the actual global logger implementation.
     *
     * @param {String} [name]  The log name; will default to "Channels".
     *
     * @author Gunni Rode / <a href="http://www.zmags.com">Zmags</a>
     */
    function Log(name) {
        /**
         * @type {String}
         * The logical id of this log.
         */
        this.id = name || "Channels"; // also used for backing "Timer" log!
        /**
         * @private @type {Number}
         * The log level for *this* snippet.
         */
        this._level = Levels.ERROR;
        /**
         * @type {{info:Function, error:Function}}
         * The console used to write log messages to the log for this snippet, if any. <p>
         *
         * Can be undefined, and will only be called if logging is explicitly enabled (not OFF).
         */
        this._out = window.console;

        var scope = this;

        /**
         * Sets and/or gets the log level for this snippet.
         *
         * @param {Number|String} level The optional level to set; can be undefined.
         * @return {Number} The current log level (just set); defaults to <tt>Levels.ERROR</tt>.
         */
        this.level = function (level) {
            if (arguments.length) {
                if (typeof level !== "number") {
                    level = util.int(level);
                }
                var old = scope._level;
                scope._level = Math.min(Levels.VERBOSE, Math.abs(level) || Levels.OFF); // on NaN
                if (old !== scope._level) {
                    scope.info("Set new log level: ", format.val(scope.id), " -> ", scope._level);
                }
            }
            return scope._level;
        };

        // Signature matches verbose Logger implementation used by viewer and editor
        this.error   = function () { Logger.print(scope, Levels.ERROR,   arguments); return scope; };
        this.info    = function () { Logger.print(scope, Levels.LOG,     arguments); return scope; };
        this.verbose = function () { Logger.print(scope, Levels.VERBOSE, arguments); return scope; };

        /**
         * Returns the queued log messages for this logger, either as raw entries or formatted strings.
         *
         * @param {Boolean} [raw] True for "raw" entries, false for formatted strings; default false.
         * @return {String[]|*} The array of (formatted/raw) entries; never null, but can be empty.
         */
        this.queue = function (raw) { // for Viewer
            var queue = Logger._queue[scope.id] || [];
            if (raw) {
                return queue;
            }
            var formatted = [], entry, i;
            for (i = 0; i < queue.length; i++) {
                entry = queue[i];
                // We do not want to change the "entry.printed" property when the queue is requested, since that will
                // change the behaviour of the channel logging if enabled later on...
                if (!entry.formatted) {
                    Logger._format(scope.id, entry); // will update "entry.formatted", but not "entry.printed"!
                }
                formatted.push(entry.formatted);
            }
            return formatted;
        };
    }

    /**
     * A simple *internal* logger implementation that will queue all logged messages, even if logging is turned off,
     * and can log to the console or to the dom. The public interface is the (small) public <tt>Log</tt> type, thus
     * backed by this implementation. <p>
     *
     * Queued messages can be picked up by the Viewer and sent to the Frontend Error Service on error, for easier
     * debugging.
     */
    var Logger = {

        /**
         * @private @type {*}
         * Queue of all logged messages, regardless if logging is turned off or not, indexed by their snippet
         * config id. <p>
         *
         * Each queue is an array of messages declared as:
         * <pre>
         * {
         *     level:   Number,  // the log level from "Levels"
         *     msg[]:   Object,  // the log message / data
         *     time:    Date,    // the timestamp for the log message in ms since the epoch
         *     printed: Boolean, // true if actually printed (logged), false if merely enqueued
         * }
         * </pre>
         *
         * Queue will be purged on success or on failure by the Viewer, in which case it will be sent to the
         * Frontend Error Service. For this reason, this array is global.
         */
        _queue: {},

        /**
         * @private
         * Global log for log messages not specifically related to a given snippet, e.g. script loading.
         */
        _global: null, // cannot create yet, cyclic dependencies

        /**
         * Setups the supplied log by creating its "out" ("console") to log messages to. <p>
         *
         * @param {Log} log               The log to setup; cannot be null. Setting up the same log twice will
         *                                override its existing "out" ("console").
         * @param {String|Number} [level] The initial log level for <tt>log</tt>, defaults to <tt>Levels.ERROR</tt>.
         * @return {Log} <tt>log</tt>, now setup (if not already).
         */
        setup: function (log, level) {
            var old = log._level;
            // Ensure the log has the initial level, so it can be changed later on...
            level = log.level(level);

            // Lower or same level -> nothing to do. Note that this can "leave holes" in the log if for example
            // original log level was 1, then enabled with 2 - all 3 (VERBOSE) will not be logged then, only next
            // time when, and if ever, the log level is set to 3. This is normal behaviour. But, since all below
            // 3 has already been printed, only the missing messages with 3 will be printed then, thus causing
            // "holes" where all messages with a lower level should be (already haven been) printed.
            if (level > old) {
                Logger._dequeue(log);
            }
            return log;
        },

        /**
         * @private
         * Enqueues the specified log message for the supplied log.
         *
         * @param {Log}       log    The log to enqueue the log message for; never null.
         * @param {Number}    level  The log level from <tt>Levels</tt>.
         * @param {Arguments} msg    The log message/data to log; never falsey.
         */
        _enqueue: function (log, level, msg) {
            var entry = {level: level, msg: msg, formatted: null, time: new Date(), printed: false}, queue = Logger._queue[log.id]; // we need a Date, not ms, for easy formatting
            if (!queue) {
                queue = [];
                Logger._queue[log.id] = queue;
            }
            queue.push(entry);
            return entry;
        },

        /**
         * @private
         * Dequeues all logged messages for the supplied log. <p>
         *
         * A dequeued message will only be logged if its log level confirms to the log level of the supplied log. <p>
         *
         * This will not remove log messages from the queue!
         *
         * @param {Log} log The log to dequeue log messages for; never null.
         */
        _dequeue: function (log) {
            // Will print each queued message so far that has not already been logged. It will not clear the queue,
            // since the viewer will potentially use the queue...
            var queue = Logger._queue[log.id] || [], i;
            for (i = 0; i < queue.length; i++) {
                Logger._out(log, queue[i]);
            }
        },

        /**
         * @private
         * Prints the specified log entry, tagging it as printed if it has a log level that matches the
         * logs configured level. <p>
         *
         * The logged message will be prefixed with the id of the log like "[Channels:" + log.id + "]", if more
         * than one snippet is currently found. If the log does not have an id, specified at construction time,
         * or only a single snippet, the prefix is simply "[Channels]".
         *
         * @param {Log} log   The log to log the entry for; never null.
         * @param {*}   entry The log entry to print; never null.
         */
        _out: function (log, entry) {
            // Only do the actual logging if so configured. The snippet log can be undefined if for example console
            // logging is enabled, but the developer tools are disabled...
            var out = log._out;
            if (typeof out !== "undefined" && entry.level <= log.level() && !entry.printed) {
                // Will update "entry.formatted" with formatted log message and return all data to log as data objects...
                var data = Logger._format(log.id, entry);
                // Log formatted message...
                out[entry.level === Levels.ERROR ? "error" : "info"](entry.formatted);
                // Log data object(s), if any...
                if (data.length === 1) {
                    out.info(data[0]);
                } else if (data.length > 1) {
                    out.info(data);
                }
                entry.printed = true;
            }
        },

        /**
         * Formats the specified entry (again) into a string, storing it under <tt>entry.formatted</tt>, and returns
         * all data objects to log as well. <p>
         *
         * The formatted string will reflect the current value of each data object included in the formatting.
         *
         * @param {String} id    The log id; never null.
         * @param {*}      entry The log entry to format; never null.
         * @return {[]} The data to log as objects (non formatted); never null, but can be empty.
         */
        _format: function (id, entry) {
            var data = [],
                sepA = ":",
                sepB = " ->",
                i;

            var flip = function () {
                var tmp = sepA;
                sepA = sepB; sepB = tmp;
            };

            var formatter = function (value, recursive) {
                var type = typeof value;
                if (type === "undefined") {
                    value = "undefined";
                } else if (type === "string") {
                    if (util.idx(value, sepA) !== -1) {
                        flip();
                    }
                } else if (type === "object") {
                    // Handle null...
                    if (value === null) {
                        value = "null";

                    // Handle arrays recursively...
                    } else if (typeof value.length === "number") {
                        var j, v = sepA + " [";
                        for (j = 0; j < value.length; j++) {
                            if (j) {
                                v += ", ";
                            }
                            v += formatter(value[j], true);
                        }
                        value = v + "]";

                    // Handle object...
                    } else {
                        // Some data value to log as-is, but perhaps also as formatted...
                        data.push(value);
                        // Lookup formatter and format value accordingly, will default to "format.str"...
                        value = format(value);

                        if (!recursive) {
                            value = sepA + " " + value;
                            flip();
                        }
                    }
                }
                return value;
            };

            entry.formatted = "[" + id + "] " + entry.time.getHours() + ":" + entry.time.getMinutes() + ":" + entry.time.getSeconds() + ":" + entry.time.getMilliseconds() + " ";
            for (i = 0; i < entry.msg.length; i++) {
                entry.formatted += formatter(entry.msg[i]);
            }
            return data;
        },
		
        /**
         * Prints the supplied message and optional data object for the specified log and level. <p>
         *
         * If both <tt>msg</tt> and <tt>object</tt> are falsey, nothing will be printed, nor enqueued.
         *
         * @param {Log}          log    The log in question; cannot be null.
         * @param {Number}       level  The log level from <tt>Levels</tt>; defaults to <tt>Levels.OFF</tt>.
         * @param {Arguments|[]} msg    The log message/data to log; can be falsey
         */
        print: function (log, level, msg) {
            if (msg && msg.length) {
                Logger._out(log, Logger._enqueue(log, level, msg));
            }
        }
    };

    // Create default global and timer loggers, not initialised until the channels app is initialised before start...
    Logger._global = new Log();
    Logger._timer  = new Log("Timer");

    // Expose log levels and global log!
    Log.Levels = Levels;
    Log.setup  = Logger.setup;

    Log.global = function () { return Logger._global; };
    Log.timer  = function () { return Logger._timer;  };

    return Log;

});

