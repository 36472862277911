define('util/TimerLogger',[],function () {
    "use strict";

    // The timer measuring how long the channel.js script is downloaded
    var CHANNEL_SCRIPT_TIMER    = "Channel script fetch time";

    // The timer measuring how long the channel config data is fetched
    var CHANNEL_CONFIG_TIMER    = "Channel config fetch time";

    // The timer measuring how long it takes to render the experience, including fetching of CSS,
    // the viewer core (viewer.js) and the experience model.
    var EXPERIENCE_RENDER_TIMER  = "Experience render time";

    // The timer indicate start/end of network tracking
    var NETWORK_MEASUREMENT = "[network]";

    /**
     * A wrapper of logger object used for logging timer specific message to the console,
     * each timer message will start with "[Timer]" and end with a timestamp.
     * For example: [Timer] Channel start loading: 1414406106298
     *
     * @author Kim
     * @author Yijie Li
     *
     * @class TimerLogger
     * @constructor
     *
     * @param {Object} logger  A Logger{@see Logger} object.
     */
    function TimerLogger (logger) {
        this._logger = logger;
        this._time = 0;
    }

    TimerLogger.prototype = {

        /**
         * Console log the current(passed-in) time stamp
         *
         * @param {String} name        The name of loading time.
         * @param {Number} [timeStamp] Optional unix time stamp to log. Leave this out to log the current time stamp.
         */
        timer: function (name, timeStamp) {
            // This needs to work in IE8 as well - the channel app is using it.
            var last = this._time;
            this._time = timeStamp || new Date().getTime();
            var msg = name + ": " + this._time;

            if (last) {
                msg += " " + (this._time - last) + " ms elapsed";
            }

            this._logger.info(msg);
        },

        /**
         * Signals that the measuring has ended.
         */
        end: function () {
            this.timer("[End]");
        },

        reset: function () {
            this._time = 0;
        }
    };

    TimerLogger.CHANNEL_SCRIPT_TIMER = CHANNEL_SCRIPT_TIMER;
    TimerLogger.CHANNEL_CONFIG_TIMER = CHANNEL_CONFIG_TIMER;
    TimerLogger.EXPERIENCE_RENDER_TIMER = EXPERIENCE_RENDER_TIMER;
    TimerLogger.NETWORK_MEASUREMENT = NETWORK_MEASUREMENT;

    return TimerLogger;
});

