/*global window,JSON*/
define('channels/Global',['require','util/BrowserCapabilitiesDetector','channels/Store','channels/Storage','channels/Cookie','channels/State','channels/Util'],function (require) {
    "use strict";

    var BrowserCapabilitiesDetector = require("util/BrowserCapabilitiesDetector"),
        Store                       = require("channels/Store"),
        Storage                     = require("channels/Storage"),
        Cookie                      = require("channels/Cookie"),
        state                       = require("channels/State"),
        util                        = require("channels/Util");

    /** Configuration, setup during build process for each relevant environment. */
    var CONFIG =
        /* @preserve replace:configuration*/
        {
            // DO NOT COMMIT ANY MODIFICATIONS TO THIS
        };

    /** Query/config parameters, always the same values regardless of environment. */
    var QUERY = {
        adminModeIdentifier: "zmagsCreatorAdminMode",
        logModeIdentifier:   "zmagsLog" // 0-3, see Log.Levels
    };

    /**
     * Returns a new object describing the location the channel script (thinks) it is running at. <p>
     *
     * The returned location has the following format:
     * <pre>
     * {
     *    href:      String   // The current url, excluding search terms. This is the url that will be tested against
     *                        // channel urls in a case-sensitive manner. The url will never change for the page loaded,
     *                        // not even for single page apps, i.e. anchors and query parameters are ignored.
     *    real:      String,  // The real url channels is running on; only different from <tt>href</tt> if viewed
     *                        // vie e.g. Google Cache ("type" is then "cache").
     *    type:      String,  // The type of url we are running on "real", "cache", or "translate".
     *    secure:    Boolean, // True if really on https, false otherwise.
     *    query:     *        // Map of specified query parameters and their (empty) values, e.g. {"zmagsLog": "2"}.
     * }
     * </pre>
     *
     * @param  {String}  url   The fully qualified url; never falsey.
     * @param  {String} [real] The real url when <tt>url</tt> is an alternate (fake) url, like from Google Cache,
     *                         falsey otherwise (default).
     * @param  {String} [type] The short-hand type of the real url, e.g. "translate" or "cache", if <tt>real</tt> is
     *                         not falsey.
     *
     * @return {*} The location as documented; never falsey.
     */
    var location = function (url, real, type) {
        var href = url.split("?", 2), // find query params
            query = href[1];

        href = href[0].split("#", 2)[0]; // drop anchor, if any
        real = real || href;

        return {

            /**
             * @type {String}
             * The current url, excluding search terms. <p>
             *
             * This is the url that will be tested against channel urls in a case-sensitive manner, but it might
             * not be the actual url, e.g. if the page is viewed via Google Cache. <p>
             *
             * The url will never change for the page loaded, not even for single page apps, i.e. anchors and
             * query parameters are ignored.
             */
            href: href,
            /**
             * @type {String}
             * The real url, defaulting to <tt>href</tt> if <tt>href</tt> is the real url. Like <tt>href</tt>,
             * it does not include any query parameters or anchor. <p>
             *
             * Only different if the page embedding channels is viewed via e.g. Google Cache.
             */
            real: real,
            /**
             * @type {String}
             * The short-hand type for the alternate url, e.g. "translate", (Google Translate), "webcache" (Google or
             * Bing Cache), or "real" when no alternative url is used (standard use case).
             */
            type: type || "real",
            /**
             * @type {Boolean}
             * True for top-level https, false otherwise. <p>
             *
             * This is relative to the actual location, not to some fake cache url like Google cache.
             */
            secure: /^https:/i.test(real),
            /**
             * @type {*}
             * Map of parsed query string parameters, all strings but can be empty. <p>
             *
             * Channel defined query parameters, i.e. those in <tt>QUERY</tt>, will reflect the top level ones
             * to ensure we for example can turn logging on even when running on an alternative url. <p>
             *
             * Never null.
             */
            query: util.options(query, "&"),

            toString: function () {
                return this.type === "real" ? this.href : this.href + " (" + this.type + ": " + this.real + ")"; // defined here because of cyclic dependencies for Format module
            }
        };
    };

    // Short-cuts...
    var win = window,
        userAgent = win.navigator.userAgent,

        loc = (function () {
            var regex = /(\w+)\.(?:googleusercontent|bingj)\.com|\d+\.\d+\.\d+\.\d+\/proxy.ashx?/, // Microsoft translate uses some ip proxy in an iframe... :/
                paramRegex = /^cache:(?:([^:]+):)?/, // match either ["cache:", undefined] or ["cache:", "something_not_colon:"]
                loc = location(win.location.href),
                match = regex.exec(loc.href);

            if (match) {
                var type = match[1], // "type" here: undefined (MS translate), "translate", "webcache", "cc"
                    param = "q",
                    multiplier = 3;

                switch (type) {
                    case undefined:
                        param = "a";
                        type  = "translate";
                        multiplier = 10; // slooow :/
                        break;

                    case "translate":
                        param = "u";
                        break;

                    case "cc":
                        type = "webcache";
                        multiplier = 5;
                }

                // Invariant: "type" now either "translate" or "webcache", or some unknown thing (e.g. some other
                // not yet handled Google user service)...

                try {
                    var url = win.decodeURIComponent(loc.query[param] || "unknown_url"),
                        prefix,
                        j = url.length - 1,
                        i;

                    // Check for odd parameter prefixes we need to remove to make sure the channels have a better
                    // chance of matching, e.g. remove something like "cache:p550z3TSSDMJ:" for some Goggle cache
                    // urls. I think it is some kind of id or timestamp Google uses to show the cached content from,
                    // but it is not always there (but an invalid cannot be supplied)...
                    if (type === "webcache") {
                        match = paramRegex.exec(url);
                        if (match) {
                            prefix = match[1]; // no prefix also for protocol agnostic urls...
                            if (!prefix || util.idx(prefix, "http") === 0) {
                                i = 6; // only drop "cache:"
                            } else {
                                i = match[0].length; // e.g. drop "cache:p550z3TSSDMJ:"
                            }
                            // For some reason, Google cache often appends a "+" at the very end of the url,
                            // which we have to remove if we ever want to match any of the channel urls. This
                            // assumes normal urls do not end with a "+" in the general case, but that seems
                            // like a fair assumption...
                            if (url.charAt(j) !== "+") {
                                j++; // nothing to drop at the end
                            }
                            url = url.substring(i, j);
                        }
                    }

                    // Remember log level, admin mode does not make sense...
                    var level = loc.query[QUERY.logModeIdentifier];

                    // If we do not have an "url" here, we hit some unexpected Google User Content service not
                    // using "q" as the query parameter and we will simply default to the bogus url "unknown_url"
                    // above - we will never be match in any case, but we can trace it...
                    loc = location(url, loc.href, type);

                    if (level) {
                        loc.query[QUERY.logModeIdentifier] = level;
                    }

                    // Will eventually also reflect whether we are on mobile devices or not...
                    util.deferMultiplier *= multiplier;

                } catch (e) {
                    // Fall-through to silently ignore unexpected errors, but still ensuring we log alternative urls...
                    loc.error = e;
                    loc.type = type;
                }
            }
            return loc;
        }());

    /**
     * Defines a (singleton) global reference for the main window and relevant functionality (but not dom related or
     * utility functions), such as <tt>global.win</tt> for the main window, <tt>global.session</tt> for the
     * session storage, etc.
     *
     * @author Gunni Rode  / <a href="http://www.zmags.com">Zmags</a>
     * @author Kim Knudsen / <a href="http://www.zmags.com">Zmags</a>
     */
    var global = {

        CONFIG: CONFIG,
        QUERY:  QUERY,

        /** @type {Window} The main window context. */
        win: win,
        /**
         * @type {{href:String, real:String, alternate:Boolean, secure:Boolean, query:*}}
         * The location this channel script (thinks it) is running at.
         */
        loc: loc,
        /** @type {*} Detected browser capabilities. */
        capabilities: BrowserCapabilitiesDetector.detect(userAgent),

        /** @type {Store} A store backed by session storage, if supported and enabled on an app level. */
        session: new Store(new Storage(win.sessionStorage)),
        /** @type {Store} A store backed by local storage, if supported and enabled on an app level. */
        local: new Store(new Storage(win.localStorage)),
        /** @type {Store} A store backed by cookies, if supported and enabled on an app level. */
        cookie: new Store(new Cookie(win.document, loc.secure)),

        /**
         * Returns the client site jQuery/Zepto, if available. <p>
         *
         * Will be tried used to (pre)load json and select dom elements to avoid loading custom Sizzle in legacy
         * browsers if at all possible. <p>
         *
         * Note: even though a non falsey value is returned here, it might not actually be jQuery, i.e. clients
         * can have overridden "$" to mean something else.
         *
         * @return {jQuery} The client side included jQuery/Zepto, if available; can be falsey.
         */
        $: function () {
            var jq = win.jQuery || win.$;

            return jq && jq.fn && jq.fn.jquery ? jq : null;
        },

        /** @type {String} The user agent. */
        userAgent: userAgent,

        /**
         * Returns the JSON object if available for the current browser, otherwise falsey.
         *
         * @return {JSON} The JSON object, with <tt>parse</tt> and <tt>stringify</tt> methods, if available; can be falsey.
         */
        json: function () {
            return typeof JSON !== "undefined" && typeof JSON.parse === "function" && typeof JSON.stringify === "function" && JSON;
        },

        /**
         * The global (window) sandbox we operate in, e.g. where channels are located, global hooks,
         * active snippets, etc. Always required to handle multiple snippet inclusions and for legacy browsers
         * requiring our custom Sizzle. <p>
         *
         * Optionally, a <i>path</i> to look up a reference in the sandbox can be specified, e.g.
         * <tt>sandbox("app")</tt> to return the <tt>app</tt> reference from the sandbox, if available. <p>
         *
         * All properties/values under this scope are considered read-only. <p>
         *
         * The path is <tt>window.__zmags.channels</tt> (setup when called), and will (may) store:
         *
         * <pre>
         * {
         *     channels: {
         *         app: {                 // singleton, even across scripts!
         *             start:   Function, // fn(configId:String)
         *             stop:    Function, // fn(configId:String)
         *             log:     Function, // fn(configId:String, level:Number|String) or fn(level:Number|String)
         *             destroy: Function  // stops all, and removes "app"
         *         },
         *         snippets: Snippet.proxy[]  // all current snippets for this app
         *         active:   Channel.proxy[]  // current displayed experiences via channels
         *         sizzle:   Function         // custom Zmags Sizzle implementation for legacy browsers
         *         configs: {
         *             configId -> Channel[], // For customers using the "Channels" bundle strategy, the channel
         *             ..                     // configuration will be exposed in the sandbox under the channel config
         *         }                          // id, defined in the script returned by the backend.
         *     }
         * }
         * <pre>
         *
         * In addition, <tt>__zmags.channels.add(..)</tt> can be used to add channels directly as by calling
         * <tt>app.add(..)</tt>.
         *
         * @param  {String} [path] Optional path to find under the sandbox, e.g. "app" or "app.start"; can be falsey.
         *                         If the path cannot be found, falsey will be returned. Any function found as
         *                         part of the path is executed with no arguments, use the result to continue down
         *                         the path.
         * @return {*} The channel sandbox, optional for the specified path; never falsey if no path, i.e. sandbox will
         *             be created if not already available, but can be falsey if the path does not exist.
         */
        sandbox: function (path) {
            // Always created sandbox itself it does not exist...
            var sandbox = win.__zmags || (win.__zmags = {});
                sandbox.channels = sandbox.channels || {};

            var scope = sandbox.channels,
                parts,
                i;

            // Loop up specific path, e.g. "app"?
            if (path) {
                parts = path.split(".");
                for (i = 0; i < parts.length; i++) {
                    scope = scope[parts[i]];
                    if (typeof scope === "function") {
                        scope = scope();
                    }
                    /*jshint -W116*/
                    if (scope == null) { // == intentional
                        return; // undefined
                    }
                    /*jshint +W116*/
                }
            }

            return scope;
        },

        /**
         * Gets, and optionally sets, the configuration value for the specified key. Configuration values are
         * always strings, the calling context must always ensure to convert the value into the proper type
         * as needed. <p>
         *
         * When getting the key, the value will be looked up first in mem; if that fails, the query parameter
         * is looked up; if that fails, session storage is looked up; and if that fails, the empty string is
         * returned.
         *
         * @param {String} key     The configuration (query or storage) key; never null.
         * @param {*}      [value] The value to set, if any; can be falsey. The string representation will be stored.
         *                         If empty, the existing value will be removed from session storage.
         *
         * @return {String} The configured value (as a string), or the empty string.
         */
        config: function (key, value) {
            var v = (value && value.toString()) || "",
                timeout = 1000 * 60 * 60 * 4; // 4 hours, plenty of time to navigate between pages in admin mode...

            if (arguments.length > 1) {
                state.config[key] = v;
                global.session.put({key: key, value: v === "" ? void 0 : v, timeout: timeout});

            } else {
                v = state.config[key];
                if (typeof v === "undefined") {
                    v = global.loc.query[key] || global.session.get({key: key, timeout: timeout}) || "";
                    state.config[key] = v;
                }
            }
            return v;
        },

        /**
         * Returns the default log level used (as a string), defaulting to <tt>Log.Levels.ERROR</tt>, only after
         * the channels app has been initialised.
         *
         * @return {String} The default log level after initialization as a string; can be falsey (the log setup
         *                  can handle string values).
         */
        defaultLogLevel: function () { return global.config(global.QUERY.logModeIdentifier); } // has to be defined here, and not in Log, because of cyclic dependencies!

    };

    // Multiply by 2 when on mobile devices to account for presumably slower connections...
    if (!global.capabilities.browser.desktop) {
        util.deferMultiplier *= 2;
    }

    return global;

});

