define('channels/Provider',['require','channels/Global','channels/Dom','channels/Log','channels/Util','channels/Format','channels/State'],function (require) {
    "use strict";

    var global = require("channels/Global"),
        dom    = require("channels/Dom"),
        Log    = require("channels/Log"),
        util   = require("channels/Util"),
        format = require("channels/Format"),
        state  = require("channels/State");

    /**
     * Defines a <i>provider</i> to provide required resources, such as scripts and urls.
     *
     * @author Gunni Rode  / <a href="http://www.zmags.com">Zmags</a>
     * @author Kim Knudsen / <a href="http://www.zmags.com">Zmags</a>
     */
    var provider = {

        /**
         * Returns a fully qualified url for use based on the supplied url options.
         *
         * Options format:
         * <pre>
         * {
         *    viewer: String,                            // Creator viewer api url, will be prefixed with viewer api base url if not already. Can be empty.
         *    url:    String|{http:String, https:String} // some url, or urls per protocol
         * }
         * </pre>
         *
         * @param {*} options The url options; never null.
         */
        url: function (options) {
            var url,
                qualified = function (url) {
                    return /^http|^\/\//i.test(url);
                },
                merge = function (prefix, postfix) {
                    // Some legacy urls are already qualified, perhaps with odd domains. We have no choice but to
                    // return them (and perhaps replace with actual protocol below)...
                    if (qualified(postfix)) {
                        return postfix;
                    }
                    var endSlash   = prefix.charAt(prefix.length - 1) === "/",
                        startSlash = postfix.charAt(0) === "/";

                    // For our use-cases, this is a sufficient test...
                    if (util.idx(postfix, prefix) !== -1) {
                        return postfix;
                    } else if (endSlash) {
                        return prefix + (startSlash ? postfix.substring(1) : postfix);
                    }
                    return prefix + (startSlash ? postfix : "/" + postfix);
                };

            // Viewer url...
            if (typeof options.viewer !== "undefined") {
                url = merge(global.CONFIG.viewerApiBaseUrl, options.viewer);

            // Url as-is, or per protocol...
            } else {
                url = typeof options.url === "string" ? options.url : options.url[global.loc.secure ? "https" : "http"];
            }

            // Qualify protocol agnostic urls...
            if (/^\/\//i.test(url)) {
                url = (global.loc.secure ? "https:" : "http:") + url;

            // Ensure all urls are https on secure...
            } else if (global.loc.secure && !/^https:/i.test(url)) {
                url = "https:" + url.substring(5); // skip "http:", use "https:", we know not agnostic (//), since handled above
            }
            return url;
        },

        /**
         * Loads a specified script based on the supplied options, e.g. url, callback. <p>
         *
         * Options format:
         * <pre>
         * {
         *     viewer:    String,     // the mandatory script url, relative to the viewer
         *     url:       String ,    // OR fully qualified url
         *     id:        String,     // Optional id property for the script tag
         *     sandboxed: Boolean     // Whether the script should be loaded in a sandboxed anonymous iframe environment, defaults to <tt>false</tt>.
         *     callback:  Function,   // optional callback to invoke on script ready with <tt>Event</tt> argument,
         *                            // or undefined if already ready.
         *     errback:   Function,   // optional callback to invoke on script load error with single <tt>Event</tt> argument, e.g. could not be found
         *     ready:     Function|*, // shim to test if the script is already loaded, e.g. some global variable or function
         *     log:       Log         // log to use, defaults to global log (typically the scope)
         * }
         * </pre>
         *
         * Note: a script cannot be loaded asynchronously in old legacy browsers (e.g. IE9).
         *
         * @param {*} options The script options; never null.
         */
        script: function (options) {
            var log     = options.log || Log.global(),
                url     = provider.url(options),
                script  = state.scripts[url],
                start   = util.now(),
                pending = true,
                ready   = false,
                type,
                i;

            // Call callback/errback queue when script loaded/failed...
            var call = function (type, event) {
                // Make sure we only call if truly pending, i.e. not when "options.ready" fires first...
                var s = state.scripts[url], options = s && s.options, fn, i;
                if (options && options.length) {
                    // Clear pending options...
                    s.options = [];
                    state.scripts[url].loaded = type;
                    for (i = 0; i < options.length; i++) {
                        fn = options[i][type];
                        if (fn) {
                            fn(event);
                        }
                    }
                }
            };

            // We will only have a script here if "url" is pending for this call...
            if (!script) {
                script = {loaded: null, requested: false, sandboxed: options.sandboxed, options: []};
                state.scripts[url] = script;
                pending = false;
            }
            script.options.push(options);

            // Already loaded, successfully or not?
            if (script.loaded) {
                log.verbose("Script already loaded: ", format.val(url));
                call(script.loaded); // no event available here...
                return;
            }

            // Is the script ready based on an "ready" condition, e.g. "options.ready" may be able to fire
            // before the actual script load event, for example by some other script setting up stuff tested
            // for in "options.ready"...
            for (i = 0; i < script.options.length; i++) {
                options = script.options[i];
                type = typeof options.ready;
                // Since queued on same url, just one need to be ready - seems reasonable...
                ready = type === "function" ? options.ready() : options.ready;
                if (ready) {
                    log.verbose("Script already ready: ", format.val(url));
                    call("callback"); // no event here...
                    return;
                }
            }

            // Not ready yet, but script already pending...
            if (pending) {
                log.verbose("Script already pending: ", format.val(url));
                return;
            }

            // "ready" could have fired already, so we tag the script with this for easier debugging...
            script.requested = true;

            // First time the script is requested, so we insert a "script" element to fetch it...
            log.verbose("Loading", options.sandboxed ? " sandboxed " : " ", "script: ", format.val(url), "...");

            // Appends the specified <script> "element" it to the "head" section for the "doc" in question,
            // either the global one or our sandbox iframe for preload.
            var insertScript = function (doc) {
                var element = doc.createElement("script");
                    element.type = "text/javascript"; // ie8 does not understand "application/javascript"!
                    element.src = url;

                if (options.id) {
                    element.id = options.id;
                }

                dom.ready({
                    name:     url,
                    scope:    log,
                    element:  element,
                    callback: function (event) {
                        log.verbose("Script loaded in", options.sandboxed ? " sandbox in " : " ", util.now() - start, " ms: ", format.val(url));
                        call("callback", event);
                    },
                    errback: function (event) {
                        log.error("Cannot load script", options.sandboxed ? " in sandbox: " : ": ", format.val(url), event);
                        call("errback", event);
                    }
                });

                var attach = function () {
                    var head = dom.head(doc);
                    if (head) {
                        head.appendChild(element);
                    } else {
                        return true;
                    }
                };

                // For some reason, IE 9/10 does not supply the "head" immediately, but attaching a bit deferred
                // seems to do the trick. This should be enough, since we wont even be here until the overall
                // document is ready...
                if (attach()) {
                    util.defer(attach);
                }
            };

            // Global doc as default, unless specified to be sandboxed...
            var doc = dom.doc;

            // Handle sandboxed script load (inside an iframe, typically for preload)...
            if (options.sandboxed) {
                var preloadSandbox = state.preloadSandbox;
                // Sandbox is ready, in the dom...
                if (preloadSandbox) {
                    doc = preloadSandbox.contentDocument;
                    // Fall-through to insert script below!

                // The preload sandbox will only be set after document load, since it requires a content
                // document which is not available until it can be attached to the dom body. The body may
                // in fact be ready, in which case this will execute synchronously...
                } else {
                    dom.docReady({
                        name: "sandboxed",
                        scope: log,
                        callback: function () {
                            // We know we have a body know, so we can create/insert the iframe now...
                            state.preloadSandbox = dom.iframe();
                            insertScript(state.preloadSandbox.contentDocument);
                        }
                    });
                    return;
                }
            }

            // No sandbox needed, so we already have have a useful global context, *or* the sandbox is ready...
            insertScript(doc);
        },

        /**
         * Attempts to preload the json data based on the configured options, but only possible if a global jQuery
         * instance can be found. There is currently no callback functionality available on successful load as
         * the channels app does not utilise the actual data. <p>
         *
         * The JSON stringify/parse is handled by jQuery as well, not by <tt>format.json()</tt>. <p>
         *
         * Options format:
         * <pre>
         * {
         *     viewer:    String,   // the mandatory script url, relative to the viewer
         *     url:       String    // OR fully qualified url
         *     available: Function, // optional callback to invoke if json can be fetched *before* fetching starts
         *     callback:  Function, // optional callback to invoke on json loaded, supplied loaded json as argument
         *     errback:   Function, // optional callback to invoke on failure
         *     noCache:   Boolean,  // true to force no-cache, false (default) to use cache
         *     log:       Log       // log to use, defaults to global log
         * }
         * </pre>
         *
         * @param {*} options The json options; never null.
         * @return {Boolean} True if a json request was issued, false otherwise.
         */
        json: function (options) {
            var log   = options.log || Log.global(),
                url   = provider.url(options),
                $     = global.$(),
                json  = $ && $.ajax,// returns Promise, cannot use $.getJSON because cache cannot be disabled via settings!
                start = util.now();

            if (json) {
                if (options.available) {
                    options.available();
                }
                log.verbose("Loading json: ", format.val(url), "...");
                json(url, {dataType: "json", cache: !options.noCache}).done(function (json) {
                    log.verbose("Json loaded in ", util.now() - start, " ms: ", format.val(url), json);
                    if (options.callback) {
                        options.callback(json);
                    }
                }).fail(function (xhr, msg, error) {
                    log.error("Cannot load json: ", format.val(url), " -> status: ", xhr.readyState, " error: ", error.stack || error);
                    if (options.errback) {
                        options.errback();
                    }
                });
                return true;
            }
            log.verbose("Cannot load json, no jQuery available: ", format.val(url));
            return false;
        }

    };

    return provider;

});

