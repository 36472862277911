define('channels/ViewerBridge',['require','channels/Global','channels/State','channels/Dom','channels/Format','channels/Provider','channels/analytics/PiwikAnalytics','channels/Util'],function (require) {
    "use strict";

    var global         = require("channels/Global"),
        state          = require("channels/State"),
        dom            = require("channels/Dom"),
        format         = require("channels/Format"),
        provider       = require("channels/Provider"),
        PiwikAnalytics = require("channels/analytics/PiwikAnalytics"),
        util           = require("channels/Util");

    /**
     * @class
     * A <i>Viewer Bridge</i> describes the bridge between a channel and a viewer proxy as seen by a channel. The
     * viewer operates with a similar channel bridge for the reverse connection. <p>
     *
     * The actual viewer proxy exposed by the viewer will not be available until <tt>insert(..)</tt> has been
     * called from this viewer, inserting the viewer into the dom via an iframe.
     *
     * @param {Channel} channel The channel using this viewer; never null.
     */
    function Viewer(channel) {
        // TODO: with MOS-2164, embedding publishables in an experience via a blank iframe, this viewer bridge should
        //       be used for that. It will require some refactoring to be channel agnostic then, i.e. use a common
        //       interface for the viewer <-> callback interaction!

        var scope = this,
            log = channel.snippet; // doubles as a Log

        /**
         * Returns the channel used by this viewer.
         *
         * @return {Channel} The channel used by this viewer, not its proxy; never falsey.
         */
        this.channel = function () {
            return channel;
        };

        /**
         * Notifies the viewer that the configured content is now loaded, e.g. the initial group or experience to
         * show. <p>
         *
         * This is only invoked if channel preloading is enabled for data!
         *
         * @param {*|Function} data The raw jso data loaded, optionally wrapped in a function to return if from
         *                          the cache, e.g. an experience; will be falsey on error.
         * @return {Boolean} True if the viewer proxy was available and called successfully, false otherwise.
         */
        this.contentLoaded = function (data) {
            var viewer = scope._viewerProxy();
            if (viewer) {
                // Calling "data()" will tag the (cached) data as dirty since we are handing it to the viewer
                // for mutilation...
                viewer.contentLoaded(typeof data === "function" ? data() : data, channel.content.type); // falsey/null data = failure
                return true;
            }
            return false;
        };

        /**
         * Closes the viewer, destroying it altogether (removing it from the dom). <p>
         *
         * Any errors are silently caught, logged, and suppressed.
         *
         * @return {Boolean} True if the viewer proxy was available and called successfully, false otherwise.
         */
        this.close = function () {
            var viewer = scope._viewerProxy();
            if (viewer) {
                try {
                    viewer.close();
                    log.info("Closed viewer", scope);
                    return true;

                } catch (e) {
                    log.error("Cannot close viewer", scope, e); // viewer error, security error
                    // Fall-through...
                }
            }
            return false;
        };

        /**
         * @private @type {close:Function, contentLoaded:Function}
         * Returns the actual viewer proxy instance exposed in the iframe window once the viewer has successfully
         * been loaded.
         *
         * @return {*} The viewer proxy, if available; can be falsey.
         */
        this._viewerProxy = function () {
            try {
                var container = channel._dom.container;
                return container && container.contentWindow && container.contentWindow.viewer; // must have access to content window to access viewer proxy!

            } catch (e) {
                return null;
            }
        };

        /**
         * @private
         * Creates a new channel proxy augmented with a set of viewer callbacks to be called by the viewer proxy to
         * invoke channel behaviour for the channel used by this viewer. <p>
         *
         * For example: a viewer proxy calling <tt>channelProxy.callbacks.shown(..)</tt> will notify the channel in
         * question that the viewer is not fully shown with all relevant data. <p>
         *
         * The <tt>await</tt> function should be called explicitly to start a timer to call <tt>error(..)</tt> in
         * case the viewer has not responded by then.
         *
         * @return {*} A new channel proxy augmented with viewer callbacks; never null.
         */
        this._channelProxy = function () {

            // Create a *new* instance, not just "channel.proxy()", since we augment it with specific viewer behaviour
            // we do not want to expose to the word in the client site sandbox...
            var proxy = channel._createProxy();

            // Viewer callbacks...
            proxy.callbacks = {

                /**
                 * Callback to be invoked by the viewer for the channel in question, when the initial content has been
                 * loaded, or by the viewer to request possibly cached data.
                 *
                 * @param {*|String} data The data to store or the id of the data to request; cannot be falsey.
                 * @param {String}   type The type of data; cannot be falsey.
                 *
                 * @return {*} The (cached) content if <tt>data</tt> is an id and such content is cached; will
                 *             be falsey on miss or if <tt>data</tt> is not a string.
                 */
                content: function (data, type) {
                    if (typeof data === "string") {
                        // Cache never returns a falsey content entry, but "content" will be null on cache miss for
                        // for whatever reason...
                        var entry = state.cache.get(data, type, channel._cacheable).content;
                        return entry ? entry.data() : null;
                    }
                    channel.contentLoaded(data, type); // "loaded" and "dirty"!
                },

                /**
                 * Callback to be invoked by the viewer for the channel in question, when the experience has been
                 * successfully shown (experience and all assets loaded and shown). <p>
                 *
                 * Even if the viewer is configured to use a group, the callback here will be with the experience
                 * selected by the viewer to show (and load), but it can be called numerous times in case the group
                 * chooses a new experience, for example if a breakpoint threshold is crossed. <p>
                 *
                 * If the viewer does not call this callback with the channel configured timeout, the
                 * <tt>error(..)</tt> callback will be automatically invoked.
                 *
                 * @param {String} experienceId The id of the experience now shown (and loaded) by the viewer; never falsey.
                 * @param {*}      [experience] The experience json for the experience actually loaded by the viewer, if the
                 *                              viewer is kind enough to pass it along; if so, it will be cached if caching
                 *                              is enabled for the snippet controlling this channel.
                 */
                shown: function (experienceId, experience) {
                    util.clearDefer(proxy._timer);

                    // Stop loading indicator, if any was so configured and started...
                    channel._stopLoadingIndicator();

                    // We always get an experience callback here, but the original content can be either an experience
                    // or group...
                    var content = channel.content;
                        content._shown = experienceId;

                    log.info("Experience shown: ", format.val(experienceId), scope);

                    // Refresh *original* content sometime in the future if it came from the cache (persistent or not),
                    // but only if our reload timeout is less than the normal cacheable timeout...
                    var timeout = 30000, // wait 1/2 minute...
                        refresh = (channel._cacheable > timeout) && global.capabilities.browser.desktop && (content.state === "local" || content.state === "cache");

                    // Group...
                    if (content.type === "group") {
                        // Even though our content is a group here, we will cache the experience, without updating
                        // the group content associated with this channel. The actual group data already loaded
                        // at this point will have been reported by the viewer via "proxy.content(..)" at some
                        // earlier point in time...
                        if (experience) {
                            channel.contentLoaded(experience, "experience");
                        }

                    // Experience: add to experience cache for global reuse unless it already came from there. The
                    // experience may be a new experience if the group at some point in the future selects another
                    // one, for example because a breakpoint threshold has been crossed...
                    } else if (experience && content.state !== "loaded") {
                        channel.contentLoaded(experience, "experience");
                        // We know just loaded by the viewer, so do not bother refreshing it...
                        refresh = false;
                    }

                    // Tag dom element with "data-experience" storing the experience id as well. This is useful for
                    // groups to show the experience id as well, but also if the experience changes because the group
                    // selects a new one...
                    dom.data(channel._dom.element, "experience", experienceId);

                    // If the *original* channel content was loaded from local storage, we issue a refresh for it in a
                    // bit since the viewer has now loaded *unless* on mobile - we do not want to much network traffic
                    // on such platforms, and it may also slow the viewer down. However, there may other viewers active
                    // on the page, so we wait a bit before loading...
                    if (refresh) {
                        timeout = util.defer(channel._fetchContent, timeout);
                        log.verbose("Will refresh cached ", content.type, " data in ", timeout, " ms: ", format.val(content.id()), scope);
                    }
                },

                /**
                 * Callback to be invoked by the viewer for the channel in question if some <b>fatal</b>
                 * error occurred. <p>
                 *
                 * Only call for fatal errors as it will remove the channel from the dom, including the viewer!
                 * The viewer proxy <tt>close()</tt> function will be invoked if the viewer proxy is available.
                 *
                 * @param {String|*} error The error message, if available; can be falsey.
                 */
                error: function (error) {
                    util.clearDefer(proxy._timer);

                    // Stop loading indicator, if any was so configured and started...
                    channel._stopLoadingIndicator();

                    var content = channel.content;
                        content._shown = null;

                    if (content.state !== "loaded") {
                        content.state = "failed";
                        content.error = error;
                    }

                    log.error("Viewer error: ", error || "unknown");
                    channel._removeContent("error");
                }
            };

            /**
             * Starts a timer to await the viewer calling either the <tt>shown(..)</tt> or <tt>error(..)</tt>
             * callback, calling <tt>error(..)</tt> itself if neither is called within the channel configured
             * timeout. <p>
             *
             * If an existing timer has been started, it will be reset by this, and a new starting in its place,
             * with the same full channel timeout again.
             *
             * @return {*} This channel proxy; never falsey.
             */
            proxy.await = function () {
                util.clearDefer(proxy._timer);
                var time = util.now();

                // Start an initial timer to make sure we restore the original content after the specified channel timeout...
                proxy._timer = util.defer(function () {
                    proxy.callbacks.error("not shown within " + proxy._timer + " ms (since " + time + ")");
                }, channel._timeout);

                return proxy;
            };

            return proxy;
        };

        /**
         * Inserts the Creator viewer html into the specified iframe primed with the content from the channel in
         * question, e.g. experience or group. <p>
         *
         * This function does not inject <tt>iframe</tt> into the dom!
         *
         * @param {HTMLElement} iframe The iframe to insert the viewer html into, expected to have a
         *                             src = "about:blank"; cannot be falsey.
         * @param {*}          [logId] A log name postfix to be supplied to the viewer, used to generate unique
         *                             viewer log names if more than one viewer is loaded via channels only, as they
         *                             will all write to the same console.
         *
         * @return {HTMLElement} <tt>iframe</tt>, now with the viewer html written into it on success, or null if
         *                       the viewer could not be inserted for whatever reason, e.g. security error or bogus
         *                       viewer html.
         */
        this.insert = function (iframe, logId) {
            // Add a "base" tag so that file paths without a domain will use the domain the channel snippet has been
            // loaded from, e.g. "https://creator.zmags.com", and insert the content id as it *cannot* be added as
            // a hash fragment parameter to an "about:blank" src value. The base url takes the client protocol into
            // account...
            var content = channel.content,
                html = Viewer.viewerHtml(channel.snippet.baseUrl, content.id(), content.type, logId);

            try {
                // Now write the viewer html into the iframe with a reference to our channel via a freshly created
                // proxy to be augmented with the needed viewer callbacks. We use a new proxy for this as we do not
                // want the normal (singleton) channel proxy to expose viewer functionality like that...
                var doc = iframe.contentDocument;
                    doc.open();

                // Expose this channel for the viewer proxy (channel bridge); it has to be done at this precise time,
                // or IE will wipe the reference, i.e. after "doc.open(), before "doc.write()". Note that we do not
                // expose the proxy for the viewer, since the viewer has access to privileged callbacks we do not
                // want to expose publicly for now...
                iframe.contentWindow.channel = scope._channelProxy().await();

                doc.write(html);
                doc.close();

                // Start loading indicator, if so configured, since it seems we could insert the viewer html...
                channel._startLoadingIndicator();

                return iframe;

            // Errors have been seen here if the viewer html is bogus, e.g. config messed up, or on security errors...
            } catch (e) {
                log.error("Cannot insert viewer into dom", e);
                return null;
            }
        };

        this.toString = function () {
            return format.viewerBridge(scope);
        };
    }

    /**
     * Returns the primed viewer html to be injected into viewer/content iframes, where the base url, actual
     * content id, content type, and optional log id, will be injected into it.
     *
     * @param {String}   baseUrl The base url for the viewer code/css; cannot be falsey.
     * @param {String}  [id]     The content id, e.g. experience or group id; can be falsey (preload).
     * @param {String}  [type]   The content type, e.g. "experience" or "group"; will default to "experience".
     * @param {*}       [logId]  A log name postfix to be supplied to the viewer, used to generate unique
     *                           viewer log names if more than one viewer is loaded via channels only, as they
     *                           will all write to the same console.
     *
     * @return {String} The primed viewer html; never falsey.
     */
    Viewer.viewerHtml = function (baseUrl, id, type, logId) {
        // Implementation note: do *not* change these awkward regexes below since they match how the configs
        //                      are set up during build...

        id   = id   || "";
        type = type || "experience";

        // Inject base url *after* <head> tag to ensure css behaves correctly in all browsers, then inject
        // content (experience/group) id, if any (can be falsey of viewer preload). The id cannot be added as a
        // hash fragment parameter to about:blank, so we have to inject it like this...
        var html = "replace:viewerhtml" // Grunt will insert the body of "viewer.html" here during build...
            .replace(/<head>/i, "<head><base href='" + baseUrl + "'>")
            .replace("'" + type + "Id':null", "'" + type + "Id':'" + id + "'");

        // If we have a log id to prefix viewer log statements with, inject it; if not, no log prefix will
        // be used (i.e. when only a single snippet)...
        return logId ? html.replace("'logId':null", "'logId':'" + logId + "'") : html;
    };

    /**
     * Issues a preload script request for the viewer js/html, or its external library dependencies, relative to the
     * base url of the supplied snippet, if not already and only if so configured, in order to attempt a preload of the
     * viewer code (and css). <p>
     *
     * Only applicable when in "auto", "preload", or "manual" mode and in non legacy browsers that will not
     * block during preload and where the viewer mode is set to either "html", "js", or "lib". <p>
     *
     * The preload will respect the current mode of the specified snippet, i.e. it can be changed at runtime, but
     * will only ever issue at most one request for a specific preload resource.
     *
     * @param {Snippet} snippet The snippet to do the preload for; cannot be falsey.
     */
    Viewer.preload = function (snippet) {
        if (global.capabilities.isQuerySelectorSupported) { // modern browsers only and once time only!
            var url, mode = snippet.mode;

            // Preload viewer html? This will trigger a full viewer load: html, js, and the viewer will then issue
            // requests for its external libs...
            if (mode.is({viewer: "html"})) {
                snippet.info("Preloading viewer html");

                // Not guarded by multiple loads like "provider.script(..)", so we have to check this explicitly here to
                // avoid preloading the viewer multiple times...
                if (!snippet._preloadViewer) {
                    snippet._preloadViewer = util.now();

                    // We need the document to be 'ready' in order to append anything to the body...
                    dom.docReady({name: "viewerPreload", scope: snippet, callback: function () {
                        try {
                            // Now write the viewer html into the iframe created on the fly...
                            var doc = dom.iframe().contentDocument;
                                doc.open();
                                doc.write(Viewer.viewerHtml(snippet.baseUrl));
                                doc.close();

                        // Errors have been seen here if the viewer html is bogus, e.g. config messed up, or on security errors...
                        } catch (e) {
                            snippet.error("Cannot insert preloaded viewer into dom", e);
                        }
                    }});
                }
            }

            // Preload viewer js only?
            if (mode.is({viewer: "js"})) {
                url = snippet.url("js/viewer-main" + (global.CONFIG.version ? "." + global.CONFIG.version : "") + ".js");
                snippet.info("Preloading viewer js: ", format.val(url));
                // Make sure viewer.js gets loaded in a sandboxed environment (iframed), avoiding any conflicts
                // with the current window scope...
                provider.script({url: url, log: snippet, sandboxed: true});
            }

            // Preload external viewer libraries, like Piwik?
            if (mode.is({viewer: "lib"})) {
                // Url depends on config value, so we must test for its existence! In addition, already a fully
                // qualified url...
                url = PiwikAnalytics.TRACKING_SCRIPT;

                if (url) {
                    snippet.info("Preloading viewer lib: ", format.val(url));
                    provider.script({url: url, ready: function () {
                        return !!global.win.Piwik;
                    }, log: snippet});
                }
            }
        }
    };

    return Viewer;

});

