define('channels/Storage',['require','channels/Util'],function (require) {
    "use strict";

    var util = require("channels/Util");

    var MARKER = "::::"; // for timestamp

    /**
     * @class
     * The <i>Storage</i> type describes the channel interaction with session and/or local storage when
     * wrapped by the <tt>Store</tt> type, ensuring a separate name space and option for timeout for values
     * in local storage (e.g. cached raw experience json).
     *
     * @param {*} storage The backing HTML5 storage, e.g. <tt>window.localStorage</tt> or <tt>window.sessionStorage</tt>.
     */
    return function (storage) {

        this.size = function () {
            return storage ? storage.length : 0;
        };

        this.key = function (index) {
            return storage ? storage.key(index) : null;
        };

        this.get = function (key, timeout) {
            var value,
                timestamp,
                i;

            if (storage) {
                value = storage.getItem(key);

                // Remove timestamp, if we we do not use it here, or the returned value is bogus...
                if (value && util.idx(value, MARKER) === 0) {
                    i = util.idx(value, MARKER, MARKER.length);
                    timestamp = util.int(value.substring(MARKER.length, i));
                    value = value.substring(i + MARKER.length);
                }

                // Remove on timeout...
                if (timestamp && timeout && ((util.now() - timestamp) >= timeout)) {
                    storage.removeItem(key);
                    value = void 0;
                }
            }
            return value;
        };

        this.put = function (key, value, timeout) {
            if (storage) {
                // Storage does not support timeout, so we have to enforce this ourselves...
                if (timeout) {
                    // Checked on "get"...
                    value = MARKER + util.now() + MARKER + value;
                }
                storage.setItem(key, value);
            }
        };

        this.remove = function (key) {
            return storage && storage.removeItem(key);
        };
    };

});

