define('channels/Cache',['require','channels/Global','channels/Format'],function (require) {
    "use strict";

    var store = require("channels/Global").local,
        json  = require("channels/Format").json();

    /**
     * @class
     * A <i>cache</i> indexes raw model json via ids, and it may or may not try to persist json to local storage. <p>
     *
     * Since the ids of any Creator is globally unique, it suffices to index based on id alone even if more than
     * one type of publishable content can be stored, e.g. experience, group, or even fallback data. <p>
     *
     * The <tt>get</tt> and <tt>put</tt> methods accept an optional <tt>timeout</tt> in ms to store the data in
     * local storage as well; if not supplied, or a zero or negative value, the data will only be cached internally
     * until page reload/navigation. <p>
     *
     * A cache can only be enabled if json is supported by the browser. This is required as the raw json data
     * stored in it cannot be shared with the actual Viewer to ensure it is immutable. For example, the viewer
     * will create Backbone.Model and Backbone.Collection instances directly on json objects, changing them
     * in unpredictable ways. So, if json is not supported, this cache will always report at miss when getting
     * content from it and it will never store data either. In other words the cache size will always be zero,
     * and it will never persist data to local storage either (which also requires json).
     *
     * @author Gunni Rode / <a href="http://www.zmags.com">Zmags</a>
     */
    function Cache() { // TODO: share with viewer, including storage, so viewer can cache stuff as well (also when no channels)

        var scope = this;

        /**
         * @private @type {*}
         * The runtime cache of raw json model data, either (pre-)loaded or via embedded content in a
         * channel config. <p>
         *
         * Each content entry is indexed by the data id as a string, e.g. an experience id. Each entry has the format
         * <tt>{type:String, data:Function}</tt>. The <tt>type</tt> property describes the cached content: "experience",
         * "group", or "fallback", while <tt>content.data()</tt> will return the actual json data wrapped in a function
         * to return said data (it can internally be stored as a string or as json, lazily created on first request). <p>
         *
         * When getting content from this cache, the entry will be wrapped in an object like
         * <tt>{content: {type:String, data:Function}, source:String} to express where the cache hit originated from,
         * local storage or in mem.
         */
        this._content = {};
        /**
         * @private @type {Number}
         * The number of raw json models in this cache.
         */
        this._size = 0;

        /**
         * Returns the number of raw json models in this cache.
         *
         * @return {Number} The number of raw json models in this cache.
         */
        this.size = function () {
            return scope._size;
        };

        /**
         * Returns the cached raw json model for the supplied model stub (e.g. from a channel config) or id, if any.
         *
         * @param  {String|*}  id       The id or the content stub (from a channel config); cannot be undefined.
         * @param  {String}    type     The type of content, e.g. "experience"; cannot be falsey.
         * @param  {Number}   [timeout] The timeout of the content to return in ms; if older than this, a miss
         *                              will occur. If falsey, zero, or negative, the data will not be written
         *                              to local storage.
         *
         * @return {{content: {type:String, data:Function}, source:String}} The cache result, where the
         *         <tt>content</tt> property will store the (persistent) cached raw model json under "data", if any,
         *         and "type" is the content type, e.g. "experience" or "group", and <tt>source</tt> describes from
         *         where: "cache", "local", or "miss"; never null, but <tt>content</tt> will be falsey on cache miss.
         *         To get the actual json content, call <tt>content.data()</tt>.
         */
        this.get = function (id, type, timeout) {
            id = id.id || id; // id.id to allow stubs or already loaded data

            // {data:Function, type:String} content entries. If we do not support json, this cache will always be
            // empty...
            var entry = scope._content[id];

            // Cached?
            if (entry) {
                // Sanity check...
                if (entry.type === type) {
                    return {content: entry, source: "cache"};
                }
                // Fall-through

            // Cache miss, so check local storage unless we know it is disabled (which it will if json is not supported)...
            } else if (timeout > 0 && store.enabled()) {
                // For now, we use a simple key consisting on the id only since the channel snippet does not
                // store anything else in *local* storage. We get the data from local storage in its raw
                // stringified form, allowing the content to lazily create the json where requested as "data()"...
                var data = store.get({key: id, timeout: timeout});
                if (data) {
                    return {content: scope.put(id, data, type, false, 0), source: "local"}; // 0 = do not persist here, we got it from the persistent store already (which is why dirty is false)
                }
                // Fall-through
            }

            // No hit, neither in mem or in local storage...
            return {content: null, source: "miss"};
        };

        /**
         * Adds the supplied raw json data to this cache if and only if it is cacheable (has an id and actual data
         * as required for "type"). If the data is already cached, the existing data will be overridden. <p>
         *
         * If this cache is configured to be persistent for the specified data type, any content added to it will be
         * saved to local storage. We do currently not enforce any size on local storage as it is expected that the
         * average number of loaded content data per end-user is small. Some clients have been known to use up to 7
         * experiences on a given page, but that is still a small number.
         *
         * @param  {String}   id       The id to cache data under; cannot be falsey, and must match the id in "data".
         * @param  {String|*} data     The raw json data to add, either already stringified or as a plain old JS object;
         *                             can be falsey.
         * @param  {String}   type     The type of json data to add; cannot be falsey.
         * @param  {Boolean} [dirty]   True if <tt>data</tt> is dirty, false otherwise (default).
         * @param  {Number}  [timeout] The timeout of the content to write in ms; a falsey, zero, or negative value
         *                             means the data will not be written to local storage.
         *
         * @return {data:Function, type:String} The cache entry for <tt>data</tt>; will be falsey if "data" is falsey.
         *         Call <tt>data()</tt> to get the cached json, where supplying a false argument will not tag
         *         the data as dirty (can be reused).
         *
         * @see    #cacheable
         */
        this.put = function (id, data, type, dirty, timeout) {
            // string content is for internal put, so we assume cacheable in said case!...
            var raw = typeof data !== "string", // raw means json
                storedData,
                entry;

            if (raw) {
                id = Cache.cacheable(id, data, type);
            }

            if (id) {
                entry = scope._content[id];
                if (!entry) {
                    entry = {type: type}; // whole new fresh entry!
                    scope._content[id] = entry;
                    scope._size++;
                }

                // We only store the json content, not our internal data structure with the type as that is not needed...
                if (timeout > 0 && store.enabled()) {
                    storedData = store.put({key: id, value: data, json: raw, timeout: timeout});
                }

                // Stringify is equivalent to making a deep copy since "data" may originate from the viewer and
                // Backbone can thus change it in all kinds of nasty ways. The Store used may already have stringified
                // for us, which is why we do this only after potentially accessing the store...
                entry._json = null;
                if (typeof storedData === "string") {
                    entry._data = storedData;
                } else if (typeof data === "string") {
                    entry._data = data;
                } else {
                    // Dirty if loaded by the viewer, not dirty if loaded by channels (and not handed out to the viewer)...
                    entry._data = json.stringify(data);
                    if (!dirty) {
                        entry._json = data;
                    }
                }

                /**
                 * Returns the data for the cache entry, lazily creating the json as required if not already. <p>
                 *
                 * If <tt>notDirty</tt> is not supplied or false, the (lazily created) json will be null, so the
                 * <b>next</b> request will lazily create a new cached json instance.
                 *
                 * @param {Boolean} [notDirty] True if the data is not dirty, false if dirty otherwise
                 *                             (default). If dirty, the internal json will be cleared, lazily
                 *                             created again next time requested from this entry.
                 * @return {*} The json for this entry; never falsey.
                 *
                 * @see    Content#data
                 */
                entry.data = function (notDirty) { // default dirty!
                    if (!entry._json) {
                        entry._json = json.parse(entry._data);
                    }
                    var data = entry._json;
                    if (!notDirty) {
                        entry._json = null;
                    }
                    return data;
                };
                entry.data.id = id; // to be treated as data, e.g. "var id = data.id"
            }
            return entry;
        };

        /**
         * Returns the string representation if this cache.
         *
         * @return {String} The string representation; never null.
         */
        this.toString = function () {
            return "Cache[size: " + scope._size + "]";
        };
    }

    // Map of data types to the json properties required for said type to be cacheable if and only if the
    // data in question also defines an "id" property...
    var cacheable = {
        experience: "scenes",
        group:      "entries",
        fallback:   "fallbackImageURL"
    };

    /**
     * Returns true of the supplied raw json (stub) is cacheable, i.e. loaded, false otherwise.
     *
     * @param  {String} id   The id of the data; must be equal to <tt>data.id</tt>.
     * @param  {*}      data The raw json data to test; can be falsey, in which case falsey is returned.
     * @param  {String} type The content type, e.g. "experience" or "group"; cannot be falsey.
     *
     * @return {String} The id to index <tt>data</tt> by if cacheable (i.e. <tt>id</tt>), i.e. loaded, a falsey
     *                  value otherwise.
     */
    Cache.cacheable = function (id, data, type) {
        var name = cacheable[type];
        // "json" check to ensure we support json at all!
        return json && name && data && typeof data[name] !== "undefined" && id === data.id && id; // id last to be returned!
    };

    return Cache;

});

