/*global require*/
(function () {
    "use strict";

    require(["app/ChannelsApplication"], function (app) {
        app.start();
    }, undefined, true); // Ensures this is loaded synchronously when packaged with Almond...

}());

define("channels-main", function(){});

