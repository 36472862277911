define('channels/State',[],function () {
    "use strict";

    /**


     * @class {State}
     * The <i>State</i> type stores global application state, to potentially be shared among different
     * channel app instances in case a channel snippet is included multiple times on the client page. <p>
     *
     * Any channel related state that must be truly unique for the application must be declared here, as
     * a single state instance will be shared among all applications. This is required to ensure that
     * global scripts are not reloaded, that data is not reloaded, admin mode, etc. <p>
     *
     * The rationale for this awkward design is because multiple snippets will cause multiple scripts to be
     * loaded, initialised during load beyond our control. We can only detect this at app start time, at which
     * point require will already have initialised the duplicate modules. So, we actually do not care about
     * duplicate app instances as such, as long as they share the same state. At app init time when a duplicate
     * snippet is determined, the app state will thus be re-used by the duplicate app.
     *
     * @author Gunni Rode / <a href="http://www.zmags.com">Zmags</a>
     */
    var state = {

        /**
         * Copies all shared state from the source into this state, called when duplicate channel apps are
         * detected. This is a shallow copy, only copying the references to the shared state, so multiple
         * state instances will use the same data.
         *
         * @param  {State} s The state to supply the state references to copy into this state; never null.
         * @return {State} This state to use henceforth; never null.
         */
        copy: function (s) {
            var name;
            for (name in s) {
                if (name !== "copy") { // ignore this function
                    state[name] = s[name]; // by reference!
                }
            }
            return s;
        },

        /**
         * @type {Log}
         * Short-cut to <tt>Log.global()</tt>, for easy external access like in "content.js" to create
         * 'in-place channels' on the fly. It is exposed as "app.log()".
         */
        log: null,

        /**
         * @type {*}
         * Map of configured values, imported from query parameters and/or session storage where available. <p>
         *
         * The map is used to ensure the config will work on devices with no session storage, e.g. old tablets.
         *
         * @see Global
         */
        config: {},

        /**
         * @type {Cache}
         * The global json content cache used by this app, setup in <tt>init</tt>. <p>
         *
         * The cache will store in-memory json data, e.g. for experiences and groups, but may also be backend
         * by local storage. All content is indexed by id only. <p>
         *
         * If this app is loaded multiple times on a given page, the same cache will be used (since
         * the same app is used).
         *
         * @see  ChannelsApplication
         */
        cache: null,

        /**
         * @type {Snippet[]}
         * The current snippet(s) found in the dom for this app (channel script). Note the bookmarklet, or by fault,
         * can insert the script multiple times. <p>
         *
         * This array will not be cleared between multiple start/stop, but duplicates will be ignored.
         *
         * @see  ChannelsApplication
         */
        snippets: [],

        /**
         * @type {Number}
         * The start time in ms since the epoch for the channels app.
         */
        start: null,

        /**
         * @type {Snippet}
         * The snippet currently forcing this app in admin mode, if any, false otherwise. <p>
         *
         * The snippet will be set once a given snippet is determined to be in admin mode <b>and</b> the
         * channel admin snippet has been loaded, to ensure we do not go into admin mode twice. <p>
         *
         * The snippet mode is coerced to "admin" for the snippet stored here, and all viewer mode configurations
         * cleared (i.e. no preload).
         *
         * @see Snippet#admin
         */
        admin: null,

        /**
         * @type {HTMLIFrameElement}
         * The iframe used to preload scripts in an isolated sandbox/context, avoiding any conflicts with the
         * current window scope. <p>
         *
         * Lazily initialized when a script is loaded with the {.., sandboxed:true, ..} option.
         *
         * @see  Provider#script
         */
        preloadSandbox: null,

        /**
         * @type {*}
         * Map of scripts loaded via the <tt>script</tt> function that are either pending, loaded, or which have
         * failed. <p>
         *
         * Each entry is indexed via the full script url. <p>
         *
         * Each format is like:
         * <pre>
         *   {
         *      requested: Boolean, // True if a load has been performed at all for the script, false otherwise, i.e. if
         *                          // the script was already ready the first time requested.
         *      loaded:    String,  // undefined when not loaded, "callback" when loaded successfully, "errback" when
         *                          // loading has already failed.
         *      sandboxed: Boolean, // True if loaded in an iframe sandbox, false otherwise.
         *      options:   []       // array of queued options as supplied to <tt>script(..)</tt>. Each time <tt>script(..)</tt>
         *                          // is called, its options is enqueued here for the url, the dequeued when ready,
         *                          // successfully or not.
         *   }
         * </pre>
         *
         * @see Provider#script
         */
        scripts: {}

    };

    return state;
});

