define('channels/Format',[],function () {
    "use strict";

    /**
     * Formats the supplied value based on the type/name of "v", defaulting to "str" if no specific formatter
     * is found.
     *
     * @param {*} v The value to find a formatter for; will default to "str" formatter if undefined.
     * @return {String} The formatted value.
     *
     * @author Gunni Rode / <a href="http://www.zmags.com">Zmags</a>
     */
    var	format = function (v) { // NOTE: will *not* work on minified code, but channel types, e.g. Snippet, also defines toString functions calling this formatter!
        var name;
        if (typeof v !== "undefined") {
            if (v.constructor) { // type with constructor function
                name = v.constructor.name;
                if (!name) { // IE trouble again
                    var match = /function ([^\(]+)/.exec(v.constructor.toString());
                    name = (match && match[1]);
                }
            }
            if (!name) {
                name = v.name; // may not be present still...
            }
        }
        var fn = format[(name || "").toLowerCase()] || format.str;
        return fn(v);
    };

    /**
     * Returns the JSON object if available for the current browser, otherwise falsey.
     *
     * @return {JSON} The JSON object, with <tt>parse</tt> and <tt>stringify</tt> methods, if available; can be falsey.
     */
    format.json = function () {
        // Implementation note: defined here because of circular dependencies!
        return typeof JSON !== "undefined" && typeof JSON.parse === "function" && typeof JSON.stringify === "function" && JSON;
    };

    // Formats the supplied snippet...
    format.snippet = function (snippet) {
        var s = "Snippet[id: " + format.val(snippet.configId) + "; mode: " + format.val(snippet.mode);
        if (snippet._preview) {
            s += "; preview: " + format.val(snippet._preview);
        }
        return s + "]";
    };

    // Formats the supplied snippet...
    format.channel = function (channel) {
        var s = "Channel[", e;
        if (channel.name) {
            s += "name: " + format.val(channel.name) + "; ";
        }
        s += "state: " + channel.state;
        if (channel.origin) {
            s += "/" + channel.origin;
        }
        s += "; restore-type: " + format.val(channel._restoreType) + "; loading-indicator: " + format.val(channel._loadingIndicator.options);

        if (channel._selector) {
            s += "; selector: " + format.val(channel._selector);
        } else {
            e = channel._dom.element;
            if (e) {
                s += "; in-place-element: " + format.element(e);
            }
        }
        if (channel._url) {
            s += "; url: " + format.val(channel._url);
        }
        return s + "; content: " + format.content(channel.content) + "]";
    };

    // Formats the supplied content...
    format.content = function (content) {
        var s = "Content[type: " + content.type, c;
        if (content.type === "experience") {
            c = format.experience(content.data(true)); // true: not dirty
        } else if (content.type === "group") {
            c = format.group(content.data(true));
        }
        if (content.fallback && content.fallback.fallbackImageURL) {
            c += "; fallback: " + format.val(content.fallback.fallbackImageURL);
        }
        if (c) {
            s += "; data: " + c + "; state: " + content.state;
        }
        return s + "]";
    };

    // Formats the supplied viewer bridge (for the viewer proxy)...
    format.viewerBridge = function (viewerBridge) {
        var s = "ViewerBridge[channel: " + format.val(viewerBridge.channel().name), v = viewerBridge._viewerProxy();
        if (v) {
            s += "; proxy: " + v;
        }
        return s + "]";
    };

    // Formats the supplied experience...
    format.experience = function (experience) {
        var s = "Experience[id: " + format.val(experience.id);
        if (experience.name) {
            s += "; name: " + format.val(experience.name);
        }
        return s + "]";
    };

    // Formats the supplied group...
    format.group = function (group) {
        var s = group.type ? group.type : "Group";
        s += "[id: " + format.val(group.id);
        if (group.name) {
            s += "; name: " + format.val(group.name);
        }
        return s + "]";
    };

    // Formats a html element
    format.element = function (element) {
        return element.tagName + "[" + (element.id || element.name || element.className) + "]";
    };

    // Founds a ClientRect/bounds object
    format.bounds = function (bounds) {
        if (bounds) {
            var s = "(w:" + (bounds.width || 0) + ", h:" + (bounds.height || 0);
            if (typeof bounds.left !== "undefined") {
                s += ", x:" + bounds.left;
            }
            if (typeof bounds.top !== "undefined") {
                s += ", y:" + bounds.top;
            }
            return s + ")";
        }
        return "none";
    };

    // Formats the supplied dom match...
    format.match = function (match) {
        var s = "Match[element: <" + (match.element ? match.element.tagName : "") + ">; viewport: " + format.bounds(match.viewport);
        if (match.elements.length) {
            s += "; elements: " + match.elements.length;
        }
        return s + "]";
    };

    // Formats the supplied value as json if possible...
    format.stringify = function (v) {
        if (format.json) {
            try {
                // Will return undefined - go figure - if it cannot be stringified in legacy IE...
                var value = format.json.stringify(v);
                if (typeof value !== "undefined") {
                    return value;
                }
                // Fall-through...

            } catch (e) {
                // Fall-through...
            }
        }
        // This also handles null, undefined, and non-jsonable types, though somewhat poorly as not proper json then...
        return "" + v;
    };

    // Formats the supplied value as a (json) string...
    format.str = function (v) {
        if (typeof v === "string") {
            return v;
        } else if (typeof v === "undefined" || v === null) { // null is an Object
            return "";
        }
        var s = v.toString();
        return s === "[object Object]" ? format.stringify(v) : s;
    };

    // Formats the supplied value by enclosing it in single quotes...
    format.val = function (v) { return "'" + v + "'"; };

    return format;

});

