define('channels/analytics/GoogleAnalytics',['require','channels/Log','channels/Global','channels/Provider','channels/Util'],function (require) {
    "use strict";

    var Log      = require("channels/Log"),
        global   = require("channels/Global"),
        provider = require("channels/Provider"),
        util     = require("channels/Util");

    var GA_ACCOUNT_TRACKING_ID = global.CONFIG.viewerGoogleAnalyticsTrackingID,
        GA_CHANNEL_NAME = "zmags_channel",

        initialized = false;

    /**
     * Returns the GA tracker to use if and only if a GA account has been specified in the configuration. <p>
     *
     * If the tracker has not been initialized yet, it will be so before returned, optionally loading the GA
     * script if not loaded yet.
     *
     * @return {Function} The actual GA tracker function to use, if any; can be null/falsey.
     */
    function tracker () {
        if (GA_ACCOUNT_TRACKING_ID) {
            if (!initialized) {
                initialized = true;
                provider.script({
                    url: "//www.google-analytics.com/analytics.js",
                    ready: function () {
                        var w = global.win,
                            ready = !!w.ga;

                        if (!ready) {
                            w.GoogleAnalyticsObject = "ga";
                            w.ga = function () {
                                (w.ga.q = w.ga.q || []).push(arguments);
                            };
                        }

                        w.ga.l = util.now(); // 1 * new Date();
                        w.ga("create", GA_ACCOUNT_TRACKING_ID, "auto", {name: GA_CHANNEL_NAME});

                        // No need to load the script if we already have the "ga" object to use...
                        return ready;
                    },

                    errback: function (event) {
                        Log.global().error("Cannot load GA", event);
                    }
                });
            }

            return global.win.ga;
        }
        // Return null if no account id is available, as GA can still be loaded and we do not want
        // to interface with other accounts...
        Log.global().verbose("GA not enabled");

        return null;
    }

    /**
     * The GA tracking interface. <p>
     *
     * For now, only a single function is exposed:
     * <pre>
     *   {
     *       trackPageView: Function // tracks a GA page view for the current url, including search part.
     *   }
     * </pre>
     */
    return {

        /**
         * Tracks a GA page view using the current location, including any search part, if and only if
         * GA account id has been specified in the configuration.
         */
        trackPageView: function () {
            var gaTracker = tracker();

            if (gaTracker) {
                // Use real url here to comply with Piwik that does the same (since off limit to us)...
                gaTracker(GA_CHANNEL_NAME + ".send", "pageview", global.loc.real); // TODO: query parameters
            }
        }
    };
});


