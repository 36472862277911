define('channels/analytics/PiwikAnalytics',['require','channels/Log','channels/Global','channels/Provider'],function (require) {
    "use strict";

    var Log      = require("channels/Log"),
        global   = require("channels/Global"),
        provider = require("channels/Provider");

    var config              = global.CONFIG.piwikConfig,
        TRACKING_SCRIPT_URL = config && config.trackingScriptUrl,
        TRACKER_URL         = config && config.trackerUrl,
        SITE_ID             = config && config.siteID,

        initialized  = false,
        queue        = [["appendToTrackingUrl", "bots=1"]], // always do this first *one* time, see track(args) function below!
        piwikTracker = null;

    /**
     * Actual tracking function for Piwik, which will enqueue until Piwik has loaded successfully. <p>
     *
     * Once loaded, all queued Piwik commands will be executed first, then the actual tracker is then called
     * henceforth (no more queuing). <p>
     *
     * Because of size constraints, this function simply expects the all arguments for Piwik in a single array,
     * where the first argument is always the Piwik command/action to call, and the latter the arguments for
     * said function/command, in order and with expected type.
     *
     * @param {[]} [args] The arguments for Piwik, where the first argument is always the Piwik command name,
     *                    e.g. "trackPageView", and the latter are the arguments, in order, for said function/command.
     *
     * @see  http://developer.piwik.org/api-reference/tracking-javascript#requesting-the-tracker-instance-from-the-piwik-class
     */
    function track (args) {
        if (piwikTracker) {
            piwikTracker[args.shift()].apply(piwikTracker, args);
        } else {
            queue.push(args);
        }
    }

    /**
     * Returns the Piwik tracker to use if and only if a Piwik tracking script url, tracker url and site id are specified
     * in the configuration. <p>
     *
     * If the tracker has not been initialized yet, it will be so before returned, optionally loading the Piwik
     * script if not loaded yet.
     *
     * @return {Function} The actual Piwik tracker function to use, if any; can be null/falsey.
     */
    function tracker () {
        if (TRACKING_SCRIPT_URL && TRACKER_URL && SITE_ID) {
            if (!initialized) {
                initialized = true;

                provider.script({
                    url: TRACKING_SCRIPT_URL,
                    ready: function () {
                        // Piwik already loaded?
                        return !!global.win.Piwik;
                    },

                    callback: function () {
                        // Get actual Piwik tracker instance to use...
                        piwikTracker = global.win.Piwik.getTracker(TRACKER_URL, SITE_ID);
                        // Dequeue any pending requests...
                        var i;

                        for (i = 0; i < queue.length; i++) {
                            track(queue[i]);
                        }

                        queue = null;
                    },

                    errback: function (event) {
                        Log.global().error("Cannot load Piwik", event);
                    }
                });
            }
            // Return the track function that will enqueue until Piwik is loaded, then issue all queued requests
            // henceforth...
            return track;
        }
        // Return null if no tracker url or site id are available...
        Log.global().verbose("Piwik not enabled");

        return null;
    }

    /**
     * The Piwik tracking interface. <p>
     *
     * For now, only a single function is exposed:
     * <pre>
     *   {
     *       trackPageView: Function // tracks a Piwik page view for the current url, including search part.
     *   }
     * </pre>
     */
    return {

        /**
         * @type {String}
         * The script url for the Piwik tracking script. Can be undefined if so configured.
         */
        TRACKING_SCRIPT: TRACKING_SCRIPT_URL,

        /**
         * Tracks a Piwik page view using the current location, including any search part, if and only if
         * Piwik has been configured via the configuration.
         */
        trackPageView: function () {
            var piwikTracker = tracker();

            if (piwikTracker) {
                piwikTracker(["trackPageView"]);
            }
        }
    };
});

