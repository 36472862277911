define('util/BrowserCapabilitiesDetector',['require','util/BrowserDetector'],function (require) {
    "use strict";

    var browserDetector = require("util/BrowserDetector");

    var IE_EDITOR_MIN_SUPPORTED_VERSION = [11],
        IE_VIEWER_MIN_SUPPORTED_VERSION = [9],
        FIREFOX_MIN_SUPPORTED_VERSION = [12],
        SAFARI_DESKTOP_MIN_SUPPORTED_VERSION = [6],
        SAFARI_MOBILE_MIN_SUPPORTED_VERSION = [6];

    /**
     * Detects capabilities of the running browser. <p>
     *
     * For normal use, use <tt>Browser.capabilities</tt> instead of detecting the capabilities over and over.
     *
     * @class BrowserCapabilitiesDetector
     */
    return {

        /**
         * Detects the browser capabilities for the supplied user agent.
         *
         * @param {String}  [userAgent] The user agent, will default to <tt>navigator.userAgent</tt>.
         * @param {Boolean} [editor]    True if in the editor, false for the viewer (default).
         *
         * @return {*} The capabilities determined based on the supplied user agent; never null.
         */
        detect: function (userAgent, editor) { // TODO: revisit when we find a neat way of diff impl for same type for viewer and editor
            if (typeof userAgent === "boolean") {
                editor = userAgent;
                userAgent = null; // will default below
            }

            userAgent = userAgent || window.navigator.userAgent;

            var detectedBrowser = browserDetector.detect(userAgent);

            /**
             * Checks whether the browser/OS combination is supported, specific to the viewer or editor.
             *
             * @return {Boolean} True if the browser/OS combination is supported, false otherwise.
             */
            function isSupported () {
                if (isUnsupportedOS()) {
                    return false;
                }

                return detectedBrowser.chrome               ||
                       detectedBrowser.googlebot            ||
                       isSupportedFirefoxBrowser()          ||
                       isSupportedInternetExplorerBrowser() ||
                       isMicrosoftEdge()                    ||
                       isSupportedSafariDesktopBrowser()    ||
                       isSupportedSafariMobileBrowser();
            }

            // MOS-1273: Editor only supports browsers on Windows and Mac for now...
            function isUnsupportedOS () {
                return editor && userAgent.indexOf("Windows") === -1 && userAgent.indexOf("Mac OS X") === -1; // TODO: duplicate functionality, also in Browser.
            }

            function isSupportedFirefoxBrowser () {
                if (!detectedBrowser.firefox || detectedBrowser.mobile) {
                    return false;
                }

                return isRequiredVersionOrLater(FIREFOX_MIN_SUPPORTED_VERSION, detectedBrowser.version);
            }

            function isMicrosoftEdge () {
                return detectedBrowser.edge;
            }

            function isSupportedInternetExplorerBrowser () {
                if (!detectedBrowser.ie) {
                    return false;
                }
                // Min version will differ between viewer and editor!
                return isRequiredVersionOrLater(editor ? IE_EDITOR_MIN_SUPPORTED_VERSION : IE_VIEWER_MIN_SUPPORTED_VERSION, detectedBrowser.version);
            }

            function isSupportedSafariDesktopBrowser () {
                if (!detectedBrowser.safari || detectedBrowser.mobile) {
                    return false;
                }

                return isRequiredVersionOrLater(SAFARI_DESKTOP_MIN_SUPPORTED_VERSION, detectedBrowser.version);
            }

            function isSupportedSafariMobileBrowser () {
                if (detectedBrowser.facebookApp) {
                    return true;
                }

                if (detectedBrowser.twitterApp) {
                    return true;
                }

                // Not supported for the editor!
                if (editor || !detectedBrowser.safari || !detectedBrowser.mobile) {
                    return false;
                }

                return isRequiredVersionOrLater(SAFARI_MOBILE_MIN_SUPPORTED_VERSION, detectedBrowser.version);
            }

            /**
             * Checks whether clicks events are supported.
             * @returns {boolean} - Whether clicks events are supported.
             */
            function isClickSupported () {
                return "onclick" in document;
            }

            /**
             * Checks touch events are supported.
             * @returns {boolean} isTouchSupportedCheck - Whether touch events are supported.
             */
            function isTouchSupported () {
                var isTouchSupportedCheck = "ontouchend" in document;

                // IE user agent on Touch enabled devices is treated as Tablet Pc.
                if (detectedBrowser.ie) {
                    isTouchSupportedCheck = isTouchSupportedCheck || "onmsgesturechange" in window;
                }

                return isTouchSupportedCheck;
            }

            /**
             * Checks that the detected user browser supports mouse events, handling the special case
             * of IE touch-enabled devices.
             * @returns {Boolean} defaultMouseSupportCheck - Whether the browser supports mouse events.
             */
            function isMouseEventsSupported () {
                var defaultMouseSupportCheck = !detectedBrowser.mobile && !detectedBrowser.tablet,
                    isTouchSupportedCheck = isTouchSupported();

                // If browser is IE, we need to check if touch is supported.
                if (isTouchSupportedCheck && detectedBrowser.ie) {
                    return isTouchSupportedCheck;
                }

                return defaultMouseSupportCheck;
            }

            /**
             * Checks that the detected user browser supports iframe scrolling - it appears Apple has decided
             * that the default behavior of an iFrame in mobile Safari is 'no scroll' and expands to prevent it.
             *
             * @return {Boolean} Whether the browser supports iframe scrolling.
             */
            function isIframeScrollingSupportted () {
                return !detectedBrowser.isIOS;
            }

            /**
             * Checks whether the specified version is later than or equal than the required version.
             *
             * @param  {Number|Number[]} requiredVersion The required version. If an array, the lower the index, the
             *                                           more major the part is, e.g. [7,5,4] means major version 7,
             *                                           then 5, and then 4 (minor).
             * @param  {Number|Number[]} version         The version to check, same semantics as <tt>requiredVersion</tt>.
             * @return {Boolean} True if the specified version is later than or equal than the required version, false if not.
             */
            function isRequiredVersionOrLater (requiredVersion, version) {
                var i, actual, required;

                if (typeof requiredVersion === "number") {
                    requiredVersion = [requiredVersion];
                }

                if (typeof version === "number") {
                    version = [version];
                }

                // Loop through version numbers, starting with the first (the most important)
                for (i = 0; i < requiredVersion.length; ++i) {
                    actual = version[i];
                    required = requiredVersion[i];

                    // If there is no version number, assume there is no match
                    if (!actual) {
                        return false;
                    }

                    // If the actual version is less than the required, return false
                    if (actual < required) {
                        return false;
                    }

                    // If the actual version is greater than the required, return true without checking the next version number
                    if (actual > required) {
                        return true;
                    }
                }

                return true;
            }

            // Based on http://caniuse.com/queryselector
            function hasQuerySelectorSupport () {
                var majorVersion = detectedBrowser.version[0];

                /*
                 * IE 8 only has queryselector support for CSS 2.1, so we
                 * exclude it from our definition of "supported"
                 */
                return document.querySelectorAll                  &&
                    (detectedBrowser.chrome                       ||
                    (detectedBrowser.edge)                        ||
                    (detectedBrowser.firefox && majorVersion > 3) ||
                    (detectedBrowser.ie && majorVersion > 8)      ||
                    (detectedBrowser.safari && majorVersion > 3)  ||
                    (detectedBrowser.opera && majorVersion > 9));
            }

            function hasSupportedBackForwardCache () {
                var majorVersion = detectedBrowser.version[0];

                /*
                 * The back-forward cache in Mobile Safari prior to version 7 is not supported.
                 */
                return !detectedBrowser.safari || !detectedBrowser.mobile || majorVersion >= 7;
            }

            function is3dAnimationsSupported () {
                // Exclude IE - http://caniuse.com/#search=translate3d
                return !detectedBrowser.ie;
            }

            return {

                /**
                 * Returns the detected browser. Note: this is not a <tt>Browser</tt> instance!
                 *
                 * @type {*} -The detected browser (from the supplied user agent); never null. <p>
                 *
                 */
                browser: detectedBrowser,

                /**
                 * Checks if this browser is on a secure url/connection (https).
                 *
                 * @type {Boolean} - rue if this browser is on a secure url/connection (https), false otherwise.
                 */
                isSecure: window.location.protocol === "https:",

                /**
                 * Checks if the current browser is supported. Note that the viewer and editor
                 * do not have the same level of required browser support!
                 *
                 * @type {Boolean} - True if the browser is supported, false otherwise. <p>
                 *
                 */
                isSupported: isSupported(),

                /**
                 * Checks if the current browser is in the Creator Viewer or Editor.
                 *
                 * @type {Boolean} - True if the viewer, false if the editor.
                 */
                isViewer: !editor,

                /**
                 * Checks whether native document query selector support is available.
                 *
                 * @type {Boolean}- True if native document query selector support is available, false otherwise.
                 */
                isQuerySelectorSupported: hasQuerySelectorSupport(),

                /**
                 * Checks whether back-forward cache is supported.
                 *
                 * @type {Boolean} - True if back-forward cache is supported, false otherwíse.
                 */
                isBackForwardCacheSupported: hasSupportedBackForwardCache(),

                /**
                 * Whether the browser has proper support for 3D animations, i.e. CSS transform translate3d,
                 * false otherwise.
                 *
                 * @type {Boolean}- True if the browser has proper support for 3D animations, i.e. CSS transform.
                 * translate3d, false otherwise.
                 */
                is3dAnimationsSupported: is3dAnimationsSupported(),

                /**
                 * Whether we consider CSS animations/keyframes to be supported, i.e. not IE < 10.
                 *
                 * @type {Boolean} - <tt>true</tt> if CSS animations is supported, <tt>false</tt> if not.
                 */
                isCSSAnimationsSupported: !(detectedBrowser.ie && detectedBrowser.version[0] < 10),

                /**
                 * Whether the browser has proper support for CSS pointer events.
                 *
                 * @type {Boolean}
                 *
                 * @see http://caniuse.com/#feat=pointer
                 */
                // We assume that all browsers (that we support) except IE9 and IE10, has support for pointer events.
                isPointerEventsSupported: !detectedBrowser.ie || detectedBrowser.version[0] >= 11,

                /**
                 * Whether web fonts are supported for the current browser.
                 *
                 * @type {Boolean}
                 */
                isWebFontsSupported: !detectedBrowser.googlebot,

                /**
                 * Whether responsive iframes are supported.
                 *
                 * @type {Boolean}
                 */
                isResponsiveIframesSupported: !detectedBrowser.iOS,

                /**
                 * Checks whether clicks are supported. Note that some devices supports both clicks and touches.
                 *
                 * @type {Boolean}
                 * @see  #isTouchSupported
                 */
                isClicksSupported: isClickSupported(),

                /**
                 * Checks whether touches are supported. Note that some devices supports both clicks and touches.
                 *
                 * @type {Boolean}
                 * @see  #isClicksSupported
                 */
                isTouchSupported: isTouchSupported(),

                /**
                 * Checks whether mouse events are supported.
                 *
                 * @type {Boolean}
                 */
                isMouseEventsSupported: isMouseEventsSupported(),

                /**
                 * Whether the detected browser supports iframe scrolling - it appears Apple has decided
                 * that the default behavior of an iFrame in mobile Safari is 'no scroll' and expands to prevent it.
                 *
                 * @type {Boolean}
                 */
                isIframeScrollingSupportted: isIframeScrollingSupportted(),

                /**
                 * Checks whether the specified version is later than or equal than the version from the
                 * detected browser.
                 *
                 * @param  {Number|Number[]} version The version to test. If an array, the lower the index, the more
                 *                                   major the part is, e.g. [7,5,4] means major version 7, then 5,
                 *                                   and then 4 (minor).
                 * @return {Boolean} True if the specified version is later than or equal than the version of
                 *                   the detected browser, false if not.
                 */
                hasVersion: function (version) {
                    return isRequiredVersionOrLater(version, detectedBrowser.version);
                }
            };
        }
    };
});

