define('util/BrowserDetector',[],function () {
    "use strict";

    return {
        detect: function (userAgent) {
            userAgent = userAgent || window.navigator.userAgent;

            var ANDROID_PATTERN = /Android/,
                CHROME_PATTERN = /(?:Chrome|CriOS)\/([0-9.]+)/,
                /*
                 * Apple devices remove Safari from user-agent when using the
                 * apple-mobile-web-app-capable meta tag. If this happens we
                 * fallback to detecting on iPad/iPhone presence.
                 */
                SAFARI_PATTERN = /Safari|(?:(?:iPad|iPhone).*AppleWebKit.*Mobile)/,
                SAFARI_VERSION_PATTERN = /Version\/([0-9.]+)/,
                FACEBOOK_APP_PATTERN = /FBAN/,  // From Facebook app
                TWITTER_APP_PATTERN = /Twitter/,
                FIREFOX_PATTERN = /Firefox\/([0-9.]+)/,
                EDGE_PATTERN    = /Edge/i,
                EDGE_VERSION_PATTERN = /Edge\/([0-9.]+)/,
                INTERNET_EXPLORER_PATTERN = /Trident|MSIE/,
                INTERNET_EXPLORER_VERSION_PATTERN = /(?:MSIE |rv:)([0-9.]+)/,
                GOOGLEBOT_PATTERN = /Googlebot\/([0-9.]+)/,
                OPERA_PATTERN = /Opera.*Version\/([0-9.]+)|OPR\/([0-9.]+)/,
                MOBILE_PATTERN = /Mobile/,
                TABLET_PATTERN = /Tablet/,
                IPOD_PATTERN = /iPod/,
                IPHONE_PATTERN = /iPhone/,
                IPAD_PATTERN = /iPad/,
                WEB_KIT_PATTERN = /AppleWebKit/,
                DEFAULT_VERSION = [];

            /**
            * Returns <code>true</code> if the current device is running on iOS,
            * <code>false</code> if not.
            *
            * @returns {Boolean} <code>true</code> if the current device is running
            *          on iOS, <code>false</code> if not.
            */
            function isIOS() {
                return isIPod() || isIPhone() || isIPad();
            }

            /**
            * Checks whether the device is an iPod Touch.
            *
            * @return {Boolean} true if the device is an iPod Touch, false if not.
            */
            function isIPod() {
                return IPOD_PATTERN.exec(userAgent) !== null;
            }

            /**
            * Checks whether the device is an iPad.
            *
            * @return {Boolean} true if the device is an iPad, false if not.
            */
            function isIPad() {
                return IPAD_PATTERN.exec(userAgent) !== null;
            }

            /**
            * Checks whether the device is an iPhone.
            *
            * @return {Boolean} true if the device is an iPhone, false if not.
            */
            function isIPhone() {
                return IPHONE_PATTERN.exec(userAgent) !== null;
            }

            /**
             * Get the version of the currently detected browser. This method
             * returns an array of integers containing the browser version,
             * starting with the major version (e.g. version "12.5.2.67"
             * becomes [12,5,2,67]).
             *
             * Note that any letters contained in versions will be subject to
             * the parseInt method (e.g. "15.0a1"). Firefox has a history of
             * doing this for dev releases.
             *
             * @returns {*} The version as an array, or empty array if unable to determine
             */
            function getVersion(pattern, matchIndex) {
                if (!pattern) {
                    return DEFAULT_VERSION;
                } else if (!matchIndex) {
                    matchIndex = 1;
                }

                var match = pattern.exec(userAgent);
                if (match) {
                    var versions = match[matchIndex].split("."),
                        idx = versions.length;

                    // Convert versions from string to int.
                    while (idx--) {
                        versions[idx] = parseInt(versions[idx], 10);
                    }
                    return versions;
                }
                return DEFAULT_VERSION;
            }

            var isEdge = EDGE_PATTERN.exec(userAgent) !== null,

                result = {
                    desktop:     false,
                    tablet:      TABLET_PATTERN.exec(userAgent) !== null,
                    mobile:      MOBILE_PATTERN.exec(userAgent) !== null,

                    android:     ANDROID_PATTERN.exec(userAgent) !== null,
                    iOS:         isIOS(),
                    macOS:       userAgent.indexOf("Mac OS X") !== -1,
                    winOS:       userAgent.indexOf("Windows") !== -1,

                    webkit:      WEB_KIT_PATTERN.exec(userAgent) !== null && !isEdge, // Edge pretends to be webkit
                    ie:          false,
                    firefox:     false,
                    safari:      false,
                    twitterApp:  false,
                    facebookApp: false,
                    chrome:      false,
                    googlebot:   false,
                    opera:       false,
                    edge:        false,
                    version:     DEFAULT_VERSION
                };

            // Check mobile/desktop...
            result.desktop = !(result.iOS || result.android || result.mobile || result.tablet);

            // Check browsers

            /*
             * NOTE: The order of checks is important as opera 15+ will
             * disguise itself as chrome and safari. See the test spec
             * for example of opera user agent.
             */
            if (OPERA_PATTERN.exec(userAgent) !== null) {
                var match = OPERA_PATTERN.exec(userAgent);
                if (match[1]) {
                    result.version = getVersion(OPERA_PATTERN, 1);
                } else {
                    result.version = getVersion(OPERA_PATTERN, 2);
                }
                result.opera = true;

            } else if (isEdge) {
                result.edge = true;
                result.version = getVersion(EDGE_VERSION_PATTERN);

            } else if (INTERNET_EXPLORER_PATTERN.exec(userAgent) !== null) {
                result.ie = true;
                result.version = getVersion(INTERNET_EXPLORER_VERSION_PATTERN);

            } else if (FIREFOX_PATTERN.exec(userAgent) !== null) {
                result.firefox = true;
                result.version = getVersion(FIREFOX_PATTERN);

            } else if (CHROME_PATTERN.exec(userAgent) !== null) {
                result.chrome = true;
                result.version = getVersion(CHROME_PATTERN);

            } else if (GOOGLEBOT_PATTERN.exec(userAgent) !== null) {
                result.googlebot = true;
                result.version = getVersion(GOOGLEBOT_PATTERN);

            // Check for twitter and Facebook browser happens *before* check for Safari, as the
            // browser strictly speaking is a Safari (but without a version number). The version
            // will be kept the default version.
            } else if (TWITTER_APP_PATTERN.exec(userAgent) !== null) {
                result.twitterApp = true;
            } else if (FACEBOOK_APP_PATTERN.exec(userAgent) !== null) {
                result.facebookApp = true;

            // Some android devices (e.g. Motorola Xoom with android 4.0) match the safari pattern, so we
            // test explicitly that it isn't android.
            } else if (!result.android && SAFARI_PATTERN.exec(userAgent) !== null) {
                result.safari = true;
                result.version = getVersion(SAFARI_VERSION_PATTERN);
            }

            return result;
        }
    };
});

