define('channels/Util',['require','channels/Format'],function (require) {
    "use strict";

    var format = require("channels/Format");

    var PATCHED = "__zmagsPatched";

    // Return a new patched function...
    var patch = function (name, fn) {
        // Reuse already patched function, if so, for consistency...
        if (fn[PATCHED]) {
            return fn;
        }

        var f = function () {
            return fn.apply(this, [this].concat(Array.prototype.slice.call(arguments))); // patch convention: use "this" as first argument
        };

        f[PATCHED] = name;

        return f;
    };

    /**
     * Standalone util functions that requires some form of polyfill or another, separate from <tt>Context</tt>
     * to avoid circular dependencies, but only for those functions we know to be a problem for some client/browser
     * combination!
     *
     * @author Gunni Rode / <a href="http://www.zmags.com">Zmags</a>
     */
    var util = {

        /**
         * Returns true if the supplied function is native, false if it has been overridden. <p>
         *
         * This does not cover all cases, e.g. constructors and non-function arguments, but it suffices for
         * our needs.
         *
         * @param {Function} f The function to check whether it is is native or not, e.g.
         *                     <tt>Array.prototype.indexOf</tt>; can be falsey, in which case false is returned.
         * @return {Boolean} True if <tt>f</tt> is considered native, false otherwise.
         * @see #patchOverriddenNativeFunctions
         */
        isNative: function (f) {
            // Cannot test for "function" type here, as old IE will give "object" for window and DOM functions...
            return f && !("prototype" in f);
        },

        /**
         * Parses the specified value into an int, defaulting to <tt>defaultValue</tt> if not a number, secondarily
         * zero.
         *
         * @param {*}  value         The value to parse into an int; can be null/undefined, will default to
         *                           <tt>defaultValue</tt>.
         * @param {*} [defaultValue] The default value to parse into an int if <tt>value</tt> cannot be parsed to
         *                           a number; will default to zero.
         * @return {Number} The corresponding int value (based on 10 radix), defaulting to <tt>defaultValue</tt>,
         *                  defaulting to zero.
         */
        int: function (value, defaultValue) {
            return parseInt(value, 10) || parseInt(defaultValue, 10) || 0;
        },

        /**
         * Returns a boolean value for the specified value. <p>
         *
         * Any boolean value will simply be returned. Null or undefined yields false. A number different from zero (0)
         * will return true. The string "true" will return true. Any other values will return false.
         *
         * @param {*}  value         The value to parse into an boolean; can be null/undefined, which will cause
         *                           false to be returned.
         * @return {Boolean} The corresponding boolean value.
         */
        boolean: function (value) {
            /*jshint -W116*/
            if (value == null) {
                return false;
            }
            /*jshint +W116*/
            var type = typeof value;
            if (type === "boolean") {
                return value;
            } else if (type === "number") {
                return value !== 0;
            }
            value = value.toString();
            return value === "true";
        },

        /**
         * Returns a specified time in ms based on <tt>time</tt>, secondarily <tt>defaultTime</tt> if <tt>time</tt>
         * is undefined or not parseable to a number. <p>
         *
         * The returned time will always be coerced to a non-negative number.
         *
         * @param {*}  time         The time to parse into an ms number; can be null/undefined, in which case it
         *                          will default to <tt>defaultTime</tt>, but will not default if zero. In it cannot
         *                          be parsed to a number, it will default to zero.
         * @param {*} [defaultTime] The default time to parse into an ms number if <tt>time</tt> cannot be parsed to
         *                          a number; will default to zero.
         * @return {Number} The corresponding non-negative time value in ms (based on 10 radix), defaulting to
         *                  <tt>defaultTime</tt>, defaulting to zero.
         */
        time: function (time, defaultTime) {
            var t;
            // "time" can be null in admin mode, or undefined in viewer...
            /*jshint -W116*/
            if (time != null) {
                t = parseInt(time, 10); // cannot use util.int since it will eventually default to zero!
            }
            /*jshint +W116*/

            // If still not a valid time, we must default to zero only here...
            if (isNaN(t)) {
                t = parseInt(defaultTime, 10) || 0;
            }
            return Math.abs(t);
        },

        /**
         * Returns the trimmed version of the specified string.
         *
         * @param  {String} s The string to trim; cannot be null.
         * @return {String} The trimmed version; never null, but can be the empty string.
         */
        trim: function (s) {
            return typeof s.trim === "function" ? s.trim() : s.replace(/^\s+|\s+$/g, "");
        },

        /**
         * Returns the trimmed camelCased version of <tt>name</tt>, e.g. " foo" -> "foo", "foo-bar " -> "fooBar".
         *
         * @param  {String} name The name to camelCase; cannot be null/undefined..
         * @return {String} The camelCased trimmed version of <tt>name</tt>; never falsey.
         */
        camelCase: function (name) {
            return util.trim(name).replace(/(\-([a-z]){1})/g, function () { // e.g. foo-bar -> fooBar
                return arguments[2].toUpperCase();
            });
        },

        /**
         * Parses options in string form like "auto:js", "background-color=#fff,color", or "zmagsLog=3&foo=&bar=foo"
         * into a JS options, here respectively {auto: "", js: ""}, {backgroundColor: "#ff", color: ""}, and
         * {zmagsLog: "3", foo: "", bar: "foo"}. <p>
         *
         * (name,value) pairs are always divided by an equals char (=), but the delimiter between different pairs
         * can be specified, defaulting to semi-colon. A pair with an empty value will still be included in
         * the returned options, e.g. "foo=", but a pair with an empty name will be ignored, e.g. "=foo". <p>
         *
         * Any dash (-) separated option will be camelCased in the returned options, e.g. "background-color" becomes
         * "backgroundColor".
         *
         * @param {String|*}      opts          The options to parse; if not a string, it will simply be returned,
         *                                      without any kind of processing though defaulting to the empty object
         *                                      if falsey. Can be a falsey string in which case {} is returned.
         * @param {String|RegExp} [delimiter]   The delimiter to spilt <tt>opts</tt> into (name,value) pairs to be split
         *                                      again on = always; defaults to a semi-colon, i.e. ";".
         * @param {String}        [defaultName] An optional default name a single non-delimited token as a option value as
         *                                      opposed to the default behaviour of a name. By default, if <tt>options</tt>
         *                                      is simply "foo", it will be treated as {foo: ""}, but if <tt>defaultName</tt>
         *                                      is supplied as "bar", this will instead yield {bar: "foo"}.
         *
         *
         * @return {*} The parsed options; never falsey.
         * @see    #camelCase
         */
        options: function (opts, delimiter, defaultName) {
            if (typeof opts !== "string") {
                return opts || {};
            }

            var options = {},
                pairs,
                pair,
                name,
                n,
                i;

            if (opts) {
                pairs = opts.toString().split(delimiter || ";");
                n = pairs.length;
                for (i = 0; i < n; i++) {
                    pair = pairs[i].split("=", 2);
                    // Special case: could be single value supplied as the name, e.g. "spinner" meaning
                    // {type: "spinner"}, but it could also be a single actual name, e.g {auto: ""}, so
                    // we have to let the calling context decide this based on whether they supplied
                    // a default name or not...
                    if (n === 1 && pair.length === 1 && defaultName)  {
                        pair.unshift(defaultName);
                    }
                    name = util.camelCase(pair[0]);
                    if (name) {
                        options[name] = pair[1];
                    }
                }
            }
            return options;
        },

        /**
         * Returns an array of the names of the native functions that has been overridden for the specified types,
         * if any. For any such function, we will try and patch it if we have a patch available. However, crazy
         * client code can unfortunately still wreck it again, e.g. if the client code is executed (again) later.
         *
         * @return {[]} An array of the names of the native functions overridden for the specified (defaulted) types,
         *              if any; never falsey, but can (hopefully) be empty.
         * @see #isNative
         */
        patchOverriddenNativeFunctions: function () { // TODO: use Kim's suggestion using new iframe to supply the implementations
            var overridden = [],
                entry,
                proto,
                qualifiedName,
                name,
                fn,
                i;

            for (name in NATIVE_FUNCTIONS) {
                if (NATIVE_FUNCTIONS.hasOwnProperty(name)) {
                    entry = NATIVE_FUNCTIONS[name];
                    proto = entry.type.prototype;

                    for (i = 0; i < entry.functions.length; i++) {
                        fn = entry.functions[i];

                        if (typeof fn === "string") {
                            fn = {name: fn}; // no patch
                        }

                        // Re-patch our patches to ensure we for example will trace this via the channel admin
                        // error logger...
                        if (!util.isNative(proto[fn.name])) {
                            qualifiedName = name + "." + fn.name;
                            // Do we have a patch? We must set in on the prototype as third party libraries used by
                            // us (and others) is likely already broken...
                            if (fn.patch) {
                                proto[fn.name] = patch(qualifiedName, fn.patch);
                                qualifiedName += ":patched";
                            }

                            overridden.push(qualifiedName);
                        }
                    }
                }
            }

            return overridden;
        },

        /**
         * Returns the current timestamp in milliseconds since the epoch. Date.now is not supported
         * by IE 8.
         *
         * @return {Number} The current timestamp in ms.
         */
        now: Date.now || function () {
            return new Date().getTime();
        },

        /**
         * Returns the index of the specified element in the supplied string or array using the === operator, or returns
         * -1 otherwise.
         *
         * @param {String|[]} source   The string or array to search; can be false, in which case falsey is returned.
         * @param {*}         value    The value to find in <tt>source</tt> based on ===; can be any value, including
         *                             null/undefined for arrays.
         * @param {Number}    [offset] The offset to start from, if any. Defaults to zero.
         * @return {Number} The index of <tt>value</tt> in <tt>source</tt> based on the === operator, or -1 if not
         *                  found.
         */
        idx: function (source, value, offset) {
            if (source) {
                if (util.isNative(source.indexOf) && typeof source.indexOf === "function") {
                    return source.indexOf(value, offset || 0);

                // String where we do not have a native "indexOf" function, so we take advantage of regular expressions...
                } else if (typeof source === "string" && value) {
                    // We have to escape special characters: http://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
                    var regex = new RegExp(value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"), "g"),
                        match;

                    if (offset) {
                        regex.lastIndex = offset;
                    }
                    match = regex.exec(source);
                    if (match) {
                        return match.index;
                    }
                    // Fall-through

                // Array in legacy browsers or where we do not have a native "indexOf" function...
                } else {
                    var i;
                    for (i = offset || 0; i < source.length; i++) {
                        if (source[i] === value) {
                            return i;
                        }
                    }
                    // Fall-through
                }
            }

            return -1;
        },

        /**
         * @type {Number}
         * A multiplier to multiply to any (indirect) <tt>defer(..)</tt> call, which by default is 1. It
         * does <b>not</b> affect cache timeouts and the likes. <p>
         *
         * On alternate urls, like Google Cache or Microsoft Translate, this factor will be increased to
         * handle such slower services. <p>
         *
         * On mobile connections, the factor will be further doubled to handle potentially slow mobile
         * network connections.
         *
         * Timeouts of zero will thus always remain zero.
         */
        deferMultiplier: 1, // defined publicly because of circular references to "global".

        /**
         * Calls the scope function in a deferred (asynchronous) manner.
         *
         * @param  {Function} fn        The no-arg scoped function to call deferred; never null.
         * @param  {Number}   [timeout] The deferred timeout in ms, defaults to 0. If on an alternate url, e.g.
         *                              Google Cache or Microsoft Translate, the timeout may be multiplied by the
         *                              specific multiplier set on <tt>util.deferMultiplier</tt> to handle such
         *                              slower services (so zero remains zero always).
         *
         * @return {{timer:Number, timeout:Number}} A handle for the created timer, tagged with the actual timeout
         *         used; never falsey. To clear the timer, simply call <tt>util.defer(timer)</tt>.
         *
         * @see    #deferMultiplier
         */
        defer: function (fn, timeout) {
            timeout = (timeout || 0) * util.deferMultiplier;
            return {
                timer:    setTimeout(fn, timeout),
                timeout:  timeout,
                toString: function () { return "" + timeout; }
            };
        },

        /**
         * Clears the specifier timer handle for a deferred function obtained via <tt>defer</tt>.
         *
         * @param  {{timer:Number, timeout:Number}} timer The handle for the deferred timeout to clear, previously
         *         obtained from <tt>util.defer(..)</tt>; if falsey, or no <tt>timer.timer</tt> property, this function
         *         is a no-op. Will delete the <tt>timer.timer</tt> property on successful clearing.
         */
        clearDefer: function (timer) {
            if (timer && timer.timer) {
                clearTimeout(timer.timer);
                delete timer.timer;
            }
        },

        /**
         * Returns a function wrapping the specified function, <tt>fn</tt>, that when called with ever only invoke
         * <tt>fn</tt> one time. In addition, a timer is started to notify about failure to call <tt>fn</tt> within
         * the configured time span in ms.
         *
         * @param  {Log}      log       The log to use; never null.
         * @param  {String}   name      The logical name of the <tt>fn</tt>, used for logging only.
         * @param  {Function} fn        The function to call at most one time; cannot be null. The function is assumed to
         *                              be scoped and will be called without any arguments.
         * @param  {Number}   [timeout] The timeout in ms to raise an error if not called. Defaults to 10s. If negative,
         *                              <tt>fn</tt> will be executed on timeout with no args (if so, be sure to use a
         *                              high timeout as it may conflict with slow network connections otherwise).
         *                              If on an alternate url, e.g. Google Cache or Microsoft Translate, the timeout
         *                              will be multiplied by <tt>util.deferMultiplier</tt> to handle slower services.
         * @return {Function} A new function that will call <tt>fn</tt> at most one time the supplied argument(s), e.g.
         *                    an <tt>Event</tt>. The returned function has a <tt>clear()</tt> function that will clear
         *                    the timeout check, for example in case of an appropriate errback being invoked instead by
         *                    some context.
         */
        one: function (log, name, fn, timeout) {
            timeout = timeout || 10000;
            var invoked = false, execute = false, timer, f;
            if (timeout < 0) {
                execute = true;
                timeout = -timeout;
            }
            f = function () {
                if (!invoked) {
                    invoked = true;
                    f.clear();
                    // 0 or 1 arguments is the standard use-case...
                    switch (arguments.length) {
                        case 0:
                            return fn();
                        case 1:
                            return fn(arguments[0]);
                        default:
                            return fn.apply(fn, Array.prototype.slice.call(arguments));
                    }
                }
            };
            f.clear = function () {
                if (timer) {
                    util.clearDefer(timer);
                    timer = null;
                }
            };

            // Start timer...
            timer = util.defer(function () {
                if (!invoked) {
                    log.error("One-time function not called within ", timer, " ms: ", format.val(name), execute ? " -> calling it now!" : "");
                    if (execute) {
                        f();
                    }
                }
            }, timeout);

            return f;
        }

    };

    /**
     * The native functions we test to see if overridden (badly) by a client site, typically in browsers running
     * old JS versions, e.g. where Arrays does not have an "indexOf" function. Worse, client sites may include all
     * kinds of scripts that define/override such functions, we have to test to see if this is the case as it
     * is bound to give us trouble. There are many other functions, of course, but we only test here for a somewhat
     * meaningful subset we know can cause trouble for our supported browser matrix.
     */
    var NATIVE_FUNCTIONS = {
        "array": {
            type: Array,
            functions: [{name: "indexOf", patch: util.idx}, "lastIndexOf", "slice"]
        },
        "string": {
            type: String,
            functions: [{name: "indexOf", patch: util.idx}, "lastIndexOf", "trim"]
        },
        "function": {
            type: Function,
            functions: ["bind"]
        }
    };

    return util;
});

