define('channels/Store',['require','channels/Util','channels/Format'],function (require) {
    "use strict";

    var util = require("channels/Util"),
        json = require("channels/Format").json();

    var KEY_PREFIX = "zmags_channels:"; // our channel space in the storage

    /**
     * @class
     * A <i>store</i> describes the channel interaction with some form of browser store, such as session storage,
     * local storage, or cookie storage, ensuring a separate name space and option for timeout for values in
     * local storage (e.g. cached raw experience json). <p>
     *
     * Such browser storage may not be available at runtime for several reasons, e.g. incognito mode,
     * quota exceeded, or because of user preferences, but this storage will never fail because of this.
     * Instead, all operations will simply be "no-op"'s, e.g. will never store any values and will never
     * retrieve any either. <p>
     *
     * The backing storage (wrapping session/local storage or document.cookie) must have the following format:
     * <pre>
     *    {
     *        size:   Function(): Number,                                    // Return the number of keys in total,
     *        keys:   Function(index:Number): String                         // Return the key at "index" within [0, size()[,
     *        get:    Function(key:String, timeout:Number): String,          // Returns the value for "key" if the "timeout" in ms has not been exceeded
     *        put:    Function(key:String, value:String, timestamp:Boolean), // Stores the "value" for "key" with an optional timestamp.
     *        remove: Function(key:String)                                   // Removes the value stored for "key".
     *    }
     * </pre>
     * Note the format does not (exactly) match that of the native <tt>Storage</tt> type! <p>
     *
     * If data is stored as json, <tt>format.json()</tt> must be available (i.e. window.JSON). Otherwise this storage
     * will always report a miss when looking up persisted values. Also note that cookie storage is not well suited
     * for storing large chunks of data.
     *
     * @param {Storage} [storage] The backing storage; can be falsey in which case this storage will be disabled (all
     *                            misses, not actual storing made).
     */
    var Store = function (storage) {

        // The excessive typeof tests are required as to not break IE7...
        var enabled = !!storage,
            scope = this;

        /**
         * Returns all keys for this storage, optionally only those with the specified key prefix.
         *
         * @param  {String} [prefix] The key prefix, if any; can be falsey.
         * @return {String[]} The keys for this storage; never null, but can be empty.
         */
        this.keys = function (prefix) {
            var keys = [], key, i;
            if (enabled) {
                if (prefix) {
                    prefix = KEY_PREFIX + prefix;
                } else {
                    prefix = KEY_PREFIX;
                }
                for (i = 0; i < storage.size(); i++) {
                    key = storage.key(i);
                    if (util.idx(key, prefix) === 0) {
                        keys.push(key);
                    }
                }
            }
            return keys;
        };

        /**
         * Clears this storage by removing all keys associated with it, optionally only those with the specified
         * key prefix.
         *
         * @param {String} [prefix] The prefix of keys to to remove, if any; can be falsey.
         * @return {Number} The number of keys removed.
         */
        this.clear = function (prefix) {
            var i, keys = scope.keys(prefix);
            for (i = 0; i < keys.length; i++) {
                scope.put(keys[i]); // deletes!
            }
            return keys.length;
        };

        /**
         * Returns the specified value from this storage. <p>
         *
         * Either a string key to look up, or an object like:
         * <pre>
         *   {
         *      key:     String,  // the key to find the value for
         *      json:    Boolean, // true if the stored value is json, false otherwise. If so, the storage value will
         *                        // be parsed into a json object before returned. If json is not supported, the
         *                        // default value from will be returned always.
         *      timeout: Number,  // maximum timeout in ms for the found value. This requires the value stored with
         *                        // an associated timestamp, otherwise ignored. If expired, the persisted value will
         *                        // be deleted from this storage.
         *      defaultValue: *   // the default value to return if none could be found, including if expired.
         *   }
         * </pre>
         *
         * @param {String|*} options The key or options to find the value by; cannot be null.
         * @return {*} The stored (perhaps defaulted) value; can be undefined.
         */
        this.get = function (options) {
            var value;
            if (enabled) {
                try {
                    if (typeof options === "string") {
                        options = {key: options};
                    }
                    value = storage.get(KEY_PREFIX + options.key, options.timeout);

                    if (options.json) {
                        // Fine to test on just "value" here as a truthy value as expected to be json because of
                        // "options.json" is truthy...
                        if (json && value) {
                            try {
                                value = json.parse(value);

                            } catch (ee) {
                                return options.defaultValue;
                            }
                            // Fall-through, can still default

                        } else {
                            return options.defaultValue;
                        }
                    }

                // Catch all, as we do not want this storage to interfere with normal behaviour otherwise...
                } catch (e) {
                    // Fall-through, which will default...
                }
            }
            /*jshint -W116*/
            return value == null ? options.defaultValue : value; // == intentional!
            /*jshint +W116*/
        };

        /**
         * Sets/updates/removes the specified value for this storage. <p>
         *
         * Either a string key to update/delete, or an object like:
         * <pre>
         *   {
         *      key:     String,  // the key to update/delete the value for. If <tt>value</tt> is null/undefined,
         *                        // <tt>key</tt> will be removed from this storage.
         *      value:   *,       // the value to store, or undefined to remove <tt>key</tt>.
         *      json:    Boolean, // true if <tt>value</tt> is json (to be stringified), false otherwise.
         *      timeout: Number   // maximum timeout in ms for the value.
         *   }
         * </pre>
         *
         * @param {String|*} options The key or options to use; cannot be null. If a string, assumed a key to delete.
         * @param {String} The value stored where applicable; can be falsey.
         */
        this.put = function (options) {
            if (enabled) {
                try {
                    if (typeof options === "string") {
                        options = {key: options}; // will delete since no value!
                    }
                    var key = KEY_PREFIX + options.key,
                        value = options.value;

                    if (typeof value === "undefined") {
                        storage.remove(key);

                    } else {
                        if (options.json) {
                            // Fine to test on just "value" here as a truthy value as expected to be json because of
                            // "options.json" is truthy...
                            if (json && value)  {
                                try {
                                    value = json.stringify(value);
                                } catch (ee) {
                                    return; // cannot store if it cannot be stringified, but do not disable because of it!
                                }

                            } else {
                                return; // cannot store json if not available or no value!
                            }
                        }
                        storage.put(key, value, options.timeout);
                        return value;
                    }

                // QuotaExceededError in incognito mode in Safari, for example, or if truly exceeded; nothing
                // to do, so we simply fall through after disable this storage henceforth...
                } catch (e) {
                    enabled = false;
                }
            }
        };

        /**
         * Optionally sets, but always returns true if this storage is considered enabled, false otherwise. <p>
         *
         * This storage will be explicitly disabled on for example Quota Exceeded in Safari Incognito mode.
         *
         * @param  {Boolean} [enabled] True to enable this store, false otherwise. If already forcefully
         *                             disabled, e.g. on Quote Exceeded, this store cannot be enabled again.
         * @return {Boolean} True if now enabled, false otherwise.
         */
        this.enabled = function () {
            if (arguments.length) {
                enabled = enabled && arguments[0]; // can have been forcefully disabled, e.g. Quota Exceeded
            }
            return enabled;
        };

    };

    Store.KEY_PREFIX = KEY_PREFIX;

    return Store;

});

